import { useLocation } from "react-router-dom";

const useStreamers = (maxStreamers = 6) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const streamersSet = new Set();
  params.forEach((value, key) => {
    if (key === "stream" && streamersSet.size < maxStreamers) {
      streamersSet.add(decodeURIComponent(value));
    }
  });

  // Convertit le Set en tableau
  return Array.from(streamersSet);
};
export default useStreamers;
