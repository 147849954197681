import appTokenService from "../Twitch/TwitchAppTokenService";
import userAccessTokenService from "../Twitch/TwitchUserAccessTokenService";

export class TwitchAPIService {
    static TWITCH_API_URL = "https://api.twitch.tv/helix";

    async fetchWithBackend(url) {
        const access_token = await appTokenService.getAppAccessToken();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/twitch/fetch`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({access_token, url}),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`TwitchAPIService - Failed to fetch: ${errorData.error}`);
        }
        return await response.json();
    }

    async fetchWithToken(url, token) {
        const response = await fetch(url, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Client-ID": process.env.REACT_APP_TWITCH_CLIENT_ID,
            },
        });

        if (!response.ok) {
            throw new Error(`TwitchAPIService - Failed to fetch: ${response.statusText}`);
        }
        return await response.json();
    }

    // Fetch with App Access Token
    async fetchWithAppAccessToken(url) {
        const appToken = await appTokenService.getAppAccessToken();
        return this.fetchWithToken(url, appToken);
    }

    // Fetch with User Access Token
    async fetchWithUserAccessToken(url) {
        const accessToken = await userAccessTokenService.getValidAccessToken();
        return this.fetchWithToken(url, accessToken);
    }

    // https://dev.twitch.tv/docs/api/reference/#get-users
    // app access token or user access token
    UserService = {
        async getUserId(login) {
            try {
                const response = await apiService.fetchWithAppAccessToken(
                    `${TwitchAPIService.TWITCH_API_URL}/users?login=${login}`
                );
                return response.data[0]?.id || null;
            } catch (error) {
                throw new Error(`TwitchAPIService - Error getting user ID: ${error.message}`);
            }
        },

        async getUserByLogin(login) {
            try {
                const response = await apiService.fetchWithAppAccessToken(
                    `${TwitchAPIService.TWITCH_API_URL}/users?login=${login}`
                );
                return response.data[0];
            } catch (error) {
                throw new Error(`TwitchAPIService - Error getting user for login: ${error.message}`);
            }
        },

        async getUserById(userId) {
            try {
                const response = await apiService.fetchWithAppAccessToken(
                    `${TwitchAPIService.TWITCH_API_URL}/users?id=${userId}`
                );
                return response.data[0];
            } catch (error) {
                throw new Error(`TwitchAPIService - Error getting user by ID: ${error.message}`);
            }
        },

        async getUsers(userIds) {
            try {
                const response = await apiService.fetchWithAppAccessToken(
                    `https://api.twitch.tv/helix/users?id=${userIds.join("&id=")}`
                );
                return response.data;
            } catch (error) {
                throw new Error(`TwitchAPIService - Error getting users ID: ${error.message}`);
            }
        },
    };

    // https://dev.twitch.tv/docs/api/reference/#get-videos
    // app access token or user access token
    VideoService = {
        async getVideo(videoId) {
            try {
                const response = await apiService.fetchWithAppAccessToken(
                    `${TwitchAPIService.TWITCH_API_URL}/videos?id=${videoId}`
                );
                return response.data[0];
            } catch (error) {
                throw new Error(`TwitchAPIService - Error getting video: ${error.message}`);
            }
        },

        async getVideos(videoIds) {
            try {
                const response = await apiService.fetchWithAppAccessToken(
                    `${TwitchAPIService.TWITCH_API_URL}/videos?id=${videoIds.join("&id=")}`
                );

                return videoIds.map((id) =>
                    response.data.find((video) => video.id === id)
                );
            } catch (error) {
                throw new Error(`TwitchAPIService - Error getting videos: ${error.message}`);
            }
        },

        async getVideosByUserId(userId, paginationCursor = null) {
            try {
                const url = new URL(`${TwitchAPIService.TWITCH_API_URL}/videos`);
                url.searchParams.append("user_id", userId);
                url.searchParams.append("first", "20");
                if (paginationCursor) url.searchParams.append("after", paginationCursor);
                return await apiService.fetchWithAppAccessToken(url);
            } catch (error) {
                throw new Error(`TwitchAPIService - Error getting videos by user ID: ${error.message}`);
            }
        },
        async getVideosByGame(gameId, languages = [], paginationCursor = null) {
            try {
                const url = new URL(`${TwitchAPIService.TWITCH_API_URL}/videos`);
                url.searchParams.append("game_id", gameId);
                url.searchParams.append("first", "50");
                languages.forEach((languageCode) => url.searchParams.append("language", languageCode));
                if (paginationCursor) url.searchParams.append("after", paginationCursor);

                const response = await apiService.fetchWithBackend(url.toString());
                const videos = response.data;

                if (videos.length > 0) {
                    const userIds = videos.map((video) => video.user_id);
                    const users = await apiService.UserService.getUsers(userIds);
                    const videosWithUserInfo = videos.map((video) => {
                        const user = users.find((u) => u.id === video.user_id);
                        return {...video, user_info: user};
                    });

                    return {data: videosWithUserInfo, pagination: response.pagination || null};
                }
                return {data: [], pagination: null};
            } catch (error) {
                throw new Error(`TwitchAPIService - Error getting videos by game ID: ${error.message}`);
            }
        },
    };

    // https://dev.twitch.tv/docs/api/reference/#get-streams
    // app access token or user access token
    StreamService = {
        async checkIfUserIsLive(userId) {
            try {
                const response = await apiService.fetchWithAppAccessToken(
                    `${TwitchAPIService.TWITCH_API_URL}/streams?user_id=${userId}`
                );
                return response.data.length > 0;
            } catch (error) {
                throw new Error(`TwitchAPIService - Error checking if user is live: ${error.message}`);
            }
        },

        async getStream(userId) {
            try {
                const response = await apiService.fetchWithAppAccessToken(
                    `${TwitchAPIService.TWITCH_API_URL}/streams?user_id=${userId}`
                );
                return response.data[0];
            } catch (error) {
                throw new Error(`TwitchAPIService - Error getting stream details for user ID: ${error.message}`);
            }
        },

        async getFollowedStreams(userId) {
            try {
                const response = await apiService.fetchWithUserAccessToken(
                    `${TwitchAPIService.TWITCH_API_URL}/streams/followed?user_id=${userId}`
                );
                return response.data;
            } catch (error) {
                throw new Error(`TwitchAPIService - Error getting followed streams for user ID: ${error.message}`);
            }
        },

        async getStreamsByGame(gameId, languages = [], paginationCursor = null) {
            try {
                const url = new URL(`${TwitchAPIService.TWITCH_API_URL}/streams`);
                url.searchParams.append("game_id", gameId);
                languages.forEach((languageCode) => url.searchParams.append("language", languageCode));
                if (paginationCursor) url.searchParams.append("after", paginationCursor);

                const response = await apiService.fetchWithAppAccessToken(url);
                const streams = response.data;

                if (streams.length > 0) {
                    const userIds = streams.map((stream) => stream.user_id);
                    const users = await apiService.UserService.getUsers(userIds);
                    const streamsWithUserInfo = streams.map((stream) => {
                        const user = users.find((u) => u.id === stream.user_id);
                        return {...stream, user_info: user};
                    });

                    return {data: streamsWithUserInfo, pagination: response.pagination || null};
                }
                return {data: [], pagination: null};
            } catch (error) {
                throw new Error(`TwitchAPIService - Error getting streams for game ID: ${error.message}`);
            }
        },
    };

    // https://dev.twitch.tv/docs/api/reference/#get-top-games
    // app access token or user access token
    GameService = {
        async getTopGames(paginationCursor = null) {
            try {
                const url = new URL(`${TwitchAPIService.TWITCH_API_URL}/games/top`);
                url.searchParams.append("first", "40");
                if (paginationCursor) url.searchParams.append("after", paginationCursor);
                return await apiService.fetchWithAppAccessToken(url);
            } catch (error) {
                throw new Error(`TwitchAPIService - Error getting top games: ${error.message}`);
            }
        },
        async getTopGamesWithInfos(paginationCursor = null) {
            try {
                const topGamesResponse = await apiService.GameService.getTopGames(
                    paginationCursor
                );
                const games = topGamesResponse.data;

                // Préparer un tableau pour stocker les jeux avec le nombre de viewers et les tags
                const gamesWithDetails = await Promise.all(
                    games.map(async (game) => {
                        // Récupérer les streams pour chaque jeu
                        const streamsResponse =
                            await apiService.StreamService.getStreamsByGame(game.id);
                        const viewerCount = streamsResponse.data.reduce(
                            (total, stream) => total + stream.viewer_count,
                            0
                        );

                        return {
                            id: game.id,
                            name: game.name,
                            box_art_url: game.box_art_url,
                            viewer_count: viewerCount,
                        };
                    })
                );

                return {
                    data: gamesWithDetails,
                    paginationCursor: topGamesResponse.pagination.cursor,
                };
            } catch (error) {
                throw new Error(
                    `TwitchAPIService - Error getting top games with viewer count and tags: ${error.message}`
                );
            }
        },

        async getGameInfo(gameId) {
            // Logger.info(`TwitchAPIService - Getting game info for: ${gameId}`);
            try {
                const response = await apiService.fetchWithAppAccessToken(
                    `${TwitchAPIService.TWITCH_API_URL}/games?id=${gameId}`
                );
                return response.data[0];
            } catch (error) {
                throw new Error(
                    `TwitchAPIService - Error getting game info: ${error.message}`
                );
            }
        },

        async getGames(gameIds) {
            // Logger.info(`TwitchAPIService - getGames- ${gameIds.join("&id=")}`);
            try {
                const response = await apiService.fetchWithAppAccessToken(
                    `${TwitchAPIService.TWITCH_API_URL}/games?id=${gameIds.join("&id=")}`
                );
                return response.data;
            } catch (error) {
                throw new Error(`TwitchAPIService - getGames - Error: ${error.message}`);
            }
        },

        async getGameByName(gameName) {
            // Logger.info(`TwitchAPIService - Getting game id: ${gameName}`);
            try {
                const response = await apiService.fetchWithAppAccessToken(
                    `${TwitchAPIService.TWITCH_API_URL}/games?name=${encodeURIComponent(
                        gameName
                    )}`
                );
                return response.data[0];
            } catch (error) {
                throw new Error(
                    `TwitchAPIService - Error getting game id: ${error.message}`
                );
            }
        },
    };

    // https://dev.twitch.tv/docs/api/reference/#get-followed-channels
    ChannelService = {
        // user access token
        async getFollowedChannels(userId, paginationCursor = null) {
            try {
                const followsUrl = `${
                    TwitchAPIService.TWITCH_API_URL
                }/channels/followed?user_id=${userId}&first=100${
                    paginationCursor ? `&after=${paginationCursor}` : ""
                }`;
                const followsData = await apiService.fetchWithUserAccessToken(
                    followsUrl
                );
                const followedChannels = followsData.data.map(
                    (follow) => follow.broadcaster_id
                );
                const paginationInfo = followsData.pagination;

                if (followedChannels.length > 0) {

                    const channelsUrl = `${
                        TwitchAPIService.TWITCH_API_URL
                    }/users?id=${followedChannels.join("&id=")}`;
                    const channelsData = await apiService.fetchWithAppAccessToken(
                        channelsUrl
                    );

                    const onlineChannelsUrl = `${
                        TwitchAPIService.TWITCH_API_URL
                    }/streams?user_id=${followedChannels.join("&user_id=")}`;
                    const onlineData = await apiService.fetchWithAppAccessToken(
                        onlineChannelsUrl
                    );

                    const onlineChannels = new Set(
                        onlineData.data.map((stream) => stream.user_id)
                    );

                    const data = channelsData.data.map((channel) => ({
                        user_id: channel.id,
                        image: channel.profile_image_url,
                        display_name: channel.display_name,
                        name: channel.login,
                        is_online: onlineChannels.has(channel.id),
                    }));

                    data.sort((a, b) => b.is_online - a.is_online);

                    return {
                        followedChannels,
                        data,
                        paginationCursor: paginationInfo.cursor,
                    };
                } else {
                    return {
                        followedChannels: [],
                        data: [],
                        paginationCursor: null,
                    };
                }
            } catch (error) {
                throw new Error(
                    `TwitchAPIService - Error getting followed channels for user ID:: ${error.message}`
                );
            }
        }
    };
}

const apiService = new TwitchAPIService();
export default apiService;
