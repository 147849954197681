import React, {useState} from 'react';
import styled from 'styled-components';
import {ChevronDown, ChevronUp} from "lucide-react";
import {useTranslation} from "react-i18next";

const CollapsibleSection = ({title, icon, children}) => {
    const {t} = useTranslation();
    const [isCollapsed, setIsCollapsed] = useState(false);

    return (
        <SectionWrapper>
            <HeaderContainer>
                <TitleContainer>
                    <SectionTitle>
                        {icon}
                        {title}
                    </SectionTitle>
                </TitleContainer>
                <CollapseButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    {isCollapsed ? (
                        <>
                            <span>{t("home.expand")}</span>
                            <ChevronDown size={16}/>
                        </>
                    ) : (
                        <>
                            <span>{t("home.collapse")}</span>
                            <ChevronUp size={16}/>
                        </>
                    )}
                </CollapseButton>
            </HeaderContainer>
            <ContentContainer isCollapsed={isCollapsed}>
                {children}
            </ContentContainer>
        </SectionWrapper>
    );
};

const SectionWrapper = styled.div`
    margin: 2rem 0;
    padding: 1.5rem;
    background: ${props => props.theme.cardBackground}80;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
`;

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.8rem;
`;

const SectionTitle = styled.h2`
    color: ${props => props.theme.color};
    font-size: 1.8rem;
    font-weight: 600;
    position: relative;
    padding-bottom: 0.8rem;
    display: flex;
    align-items: center;
    gap: 0.8rem;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 60px;
        height: 3px;
        background: ${props => props.theme.primary};
        border-radius: 3px;
    }
`;

const CollapseButton = styled.button`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: transparent;
    color: ${props => props.theme.color};
    border: 1px solid ${props => props.theme.primary}40;
    border-radius: 8px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: all 0.2s ease;

    &:hover {
        background: ${props => props.theme.primary}20;
        transform: translateY(-2px);
    }

    &:active {
        transform: translateY(0);
    }

    @media (max-width: 768px) {
        span {
            display: none;
        }
    }
`;

const ContentContainer = styled.div`
    transition: all 0.3s ease-in-out;
    max-height: ${props => props.isCollapsed ? '0' : '2000px'};
    overflow: hidden;
    opacity: ${props => props.isCollapsed ? '0' : '1'};
    transform: translateY(${props => props.isCollapsed ? '-10px' : '0'});
    visibility: ${props => props.isCollapsed ? 'hidden' : 'visible'};
`;

export default CollapsibleSection;