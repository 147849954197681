import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
        en: {
            translation: {
                language: "en-US",
                languageLabel: "Language",
                loadMore: "SEE MORE",
                loading: "Loading...",
                add: "Add",
                cancel: 'Cancel',
                close: "Close",
                selector: {
                    noStream: "No ongoing streams",
                    newPlaylist: "Create a New Playlist",
                    add: "Add to playlists",
                    choose: "Select Playlists",
                    nb: "Selected playlist(s)",
                    placeholder: "New playlist name",
                },
                login: {
                    welcome: "Welcome to Twitch Playlist",
                    subtitle: "To fully enjoy the app, log in with Twitch.",
                    loginButton: "Log in with Twitch",
                    loggingIn: "Logging in...",
                    feature1: "Custom Playlist",
                    feature1Desc: "Create a Twitch video playlist and play it on repeat.",
                    feature2: "Advanced Search",
                    feature2Desc: "Search for a streamer’s videos.",
                    feature3: "Multi-Stream",
                    feature3Desc: "Watch multiple streams at once. Share this list.",
                    users: "Users",
                },
                header: {
                    link1: "Following",
                    link2: "Browse",
                    link3: "Multistream",
                    link4: "About",
                    placeholder: "Search a channel",
                    darkTheme: "Dark theme",
                    lightTheme: "Light theme",
                    settings: "Settings",
                    login: "Login",
                    logging: "Logging...",
                    logout: "Logout"
                },
                home: {
                    tooltip1: "Add a playlist",
                    tooltip2: "Delete playlist",
                    tooltip3: "Remove from favorites",
                    grid: "Grid",
                    list: "List",
                    section1: "Online Streamers",
                    noStreamer: "No followed streamer online",
                    add: "Add",
                    noPlaylist: "No playlists",
                    delete: "Delete",
                    section2: "Favorites",
                    users: "Users",
                    noUser: "No favorite users",
                    removeFavorite: "Remove from favorites",
                    section3: "Games",
                    noGame: "No favorite games",
                    newPlaylist: "Create new playlist",
                    create: "Create",
                    cancel: "Cancel",
                    confirm: "Confirm deletion",
                    confirm2: "Are you sure you want to delete the playlist?",
                    confirmOk: "Confirm",
                    nb: "Number of videos",
                    collapse: "Collapse",
                    expand: "Expand"
                },
                following: {
                    title: "Followed Channels",
                    tooltip1: "View videos",
                    tooltip2: "View Twitch channel",
                    tooltip3: "View Twitch channel",
                    noChannel: "No followed channels",
                    channel: "Twitch channel"
                },
                browse: {
                    title: "Browse",
                    placeholder: "Search for a game",
                    noGame: "No game found"
                },
                game: {
                    category: "Twitch Category",
                    addFavorite: "Add to favorites",
                    removeFavorite: "Remove from favorites",
                    error: "Game not found",
                    views: "views",
                    noVideo: "No video available",
                    channelTab: "Live Channel",
                    videos: "Videos"
                },
                channel: {
                    noDescription: "No description available",
                    channel: "Twitch channel",
                    addFavorite: "Add to favorites",
                    removeFavorite: "Remove from favorites",
                    noVideo: "No video found",
                    views: "views",
                    videos: "Videos"
                },
                multi: {
                    tooltip1: "Add a Stream",
                    tooltip2: "Remove the Stream",
                    tooltip3: "Share the stream list",
                    tooltip4: "Remove all streams",
                    tooltip5: "Remove the Stream",
                    tooltip6: "Switch to normal mode",
                    tooltip7: "Switch to compact mode",
                    tooltip8: "Switch to Theater mode",
                    tooltip9: "Switch to Grid mode",
                    noStream: "Use the 'Add a Stream' button to start following live streams simultaneously.",
                    noStream2: "No followed streams are currently live.",
                    share: "Share this stream list with one click!",
                    streamers: "Online Streamers",
                    short: "You can also shorten your links with",
                    copy: "Copy URL",
                    copied: "Copied!",
                    placeholder: "Streamer name"
                },
                settings: {
                    label: "Settings",
                    videoQualityLabel: "Select the default video player quality",
                    chatEnabledLabel: "Enable chat during live streams",
                    languageLabel: "Switch language"
                },
                about: {
                    title: "About",
                    description:
                        "This is a mini complement for Twitch enthusiasts. The goal is not to replicate Twitch.",
                    allows: "It allows, among other things:",
                    features: [
                        "Avoid installing the Twitch app on mobile devices",
                        "Creating playlists",
                        "Adding streamers or videos to favorites",
                        "Watching multiple streams on one page",
                        "Sharing the link",
                    ],
                    improvements: {
                        title: "If the site is successful, possible future features:",
                        items: [
                            {highlight: "Tag-based search", text: "With favorites functionality"},
                            {
                                highlight: "Theme customization",
                                text: "Changing colors and background, potentially with AI integration"
                            },
                            {highlight: "Notifications", text: "Alerts when a streamer goes live"},
                            {highlight: "Significant performance improvements", text: "For a seamless user experience"},
                            {
                                highlight: "Multi-device synchronization",
                                text: "Advanced mechanisms to save preferences across devices"
                            },
                            {
                                highlight: "Recommendation system",
                                text: "Algorithmic content suggestions and highlighting relevant streams"
                            },
                        ],
                    },
                    support: "Support the project on Ko-fi",
                },
                details: {
                    tooltip1: "View channel",
                    about: "About",
                    noDescription: "No description 😕"
                },
                playlist: {
                    noVideo: "No videos in the playlist.",
                    prev: "Previous",
                    next: "Next",
                    delete: "Delete",
                    deleteAll: "Delete all",
                    t1: "Loop the playlist",
                    t2: "Loop the video",
                    t3: "Disable video loop"
                }
            }
        },
        fr: {
            translation: {
                language: "fr-FR",
                languageLabel: "Langue",
                loadMore: "VOIR PLUS",
                loading: "Chargement...",
                add: "Ajouter",
                cancel: 'Annuler',
                close: "Fermer",
                selector: {
                    noStream: "Aucun stream en cours",
                    newPlaylist: "Créer une Nouvelle Playlist",
                    add: "Ajouter aux playlists",
                    choose: "Choisissez des Playlists",
                    nb: "Playlist(s) sélectionnée(s)",
                    placeholder: "Nom de la nouvelle playlist"
                },
                login: {
                    welcome: "Bienvenue sur Twitch Playlist",
                    subtitle: "Pour profiter pleinement de l'application, connectez-vous avec Twitch.",
                    loginButton: "Se connecter à Twitch",
                    loggingIn: "Connexion...",
                    feature1: "Playlist Personnalisée",
                    feature1Desc: "Créez une playlist de vidéos Twitch et jouez la en boucle.",
                    feature2: "Recherche Avancée",
                    feature2Desc: "Cherchez les vidéos d'un streameur ou d'une streameuse.",
                    feature3: "Multi-Stream",
                    feature3Desc: "Regardez plusieurs streams en même temps. Partagez cette liste.",
                    users: "Utilisateurs",
                },
                header: {
                    link1: "Suivis",
                    link2: "Parcourir",
                    link3: "Multistream",
                    link4: "À propros",
                    placeholder: "Rechercher la chaine",
                    darkTheme: "Thème sombre",
                    lightTheme: "Thème clair",
                    settings: "Paramètres",
                    login: "Se connecter",
                    logging: "Connexion...",
                    logout: "Se déconnecter"
                },
                home: {
                    tooltip1: "Ajouter une playlist",
                    tooltip2: "Supprimer la playlist",
                    tooltip3: "Retirer des favoris",
                    grid: "Grille",
                    list: "Liste",
                    section1: "Streamers en ligne",
                    noStreamer: "Aucun streamer suivi en ligne",
                    add: "Ajouter",
                    noPlaylist: "Aucune playlist",
                    delete: "Supprimer",
                    section2: "Favoris",
                    users: "Utilisateurs",
                    noUser: "Aucun utilisateur en favori",
                    removeFavorite: "Retirer des favoris",
                    section3: "Jeux",
                    noGame: "Aucun jeu en favori",
                    newPlaylist: "Créer une nouvelle playlist",
                    create: "Créer",
                    cancel: "Annuler",
                    confirm: "Confirmer la suppression",
                    confirm2: "Êtes-vous sûr de vouloir supprimer la playlist",
                    confirmOk: "Confirmer",
                    nb: "Nombre de vidéos",
                    collapse: "Réduire",
                    expand: "Développer"
                },
                following: {
                    title: "Chaînes Suivies",
                    tooltip1: "Voir les vidéos",
                    tooltip2: "Voir la chaine Twitch",
                    tooltip3: "Voir la chaine Twitch",
                    noChannel: "Aucune chaine suivie",
                    channel: "Chaîne Twitch"
                },
                browse: {
                    title: "Parcourir",
                    placeholder: "Rechercher un jeu",
                    noGame: "Aucun jeu trouvé",
                },
                game: {
                    category: "Catégorie Twitch",
                    addFavorite: "Ajouter aux favoris",
                    removeFavorite: "Retirer des favoris",
                    error: "Jeu non trouvé",
                    views: "vues",
                    noVideo: "Aucune vidéo disponible",
                    channelTab: "Chaîne Live",
                    videos: "Vidéos"
                },
                channel: {
                    noDescription: "Aucune description disponible",
                    channel: "Chaîne Twitch",
                    addFavorite: "Ajouter aux favoris",
                    removeFavorite: "Retirer des favoris",
                    noVideo: "Aucune vidéo trouvée",
                    views: "vues"
                },
                multi: {
                    tooltip1: "Ajouter un Stream",
                    tooltip2: "Supprimer le Stream",
                    tooltip3: "Partagez la liste des streams",
                    tooltip4: "Supprimer tous les streams",
                    tooltip5: "Supprimer le Stream",
                    tooltip6: "Passer en mode normal",
                    tooltip7: "Passer en mode réduit",
                    tooltip8: "Passer en mode Théâtre",
                    Tooltip9: "Passer en mode Grille",
                    noStream: " Utilisez le bouton 'Ajouter un Stream' pour commencer à suivre des lives en simultané.",
                    noStream2: "Aucun stream suivi n'est actuellement en Live",
                    share: "Partagez cette liste de streams en un clic !",
                    streamers: "Streamers en ligne",
                    short: "Vous pouvez aussi raccourcir vos liens avec",
                    copy: "Copier l'URL",
                    copied: "Copié!",
                    placeholder: "Nom du streameur"
                },
                settings: {
                    label: "Paramètres",
                    videoQualityLabel: "Sélectionner la qualité du lecteur vidéo par défaut:",
                    chatEnabledLabel: "Activer le chat lors des streams live",
                    languageLabel: "Changer de langue"
                },
                about: {
                    title: "À Propos",
                    description:
                        "C'est un mini complément pour habitués de Twitch. Le but n'est pas de reproduire Twitch.",
                    allows: "Cela permet entre autres :",
                    features: [
                        "D'éviter d'avoir à installer l'application Twitch sur Mobiles",
                        "De faire des playlists",
                        "Mettre en favoris des streameurs ou des vidéos",
                        "Voir plusieurs streams sur une même page",
                        "Partager le lien",
                    ],
                    improvements: {
                        title:
                            "Si le site a du succès, voici les fonctionnalités futures possibles :",
                        items: [
                            {highlight: "Recherche par tags", text: "Avec ajout en favoris"},
                            {
                                highlight: "Personnalisation du thème",
                                text: "Changer les couleurs, le fond, avec une intégration potentielle de l'IA"
                            },
                            {highlight: "Notifications", text: "Alerte lorsqu'un streamer passe en ligne"},
                            {
                                highlight: "Améliorations significatives des performances",
                                text: "Pour une expérience utilisateur ultra-fluide"
                            },
                            {
                                highlight: "Synchronisation multi-appareils",
                                text: "Sauvegarde des préférences entre différents appareils"
                            },
                            {
                                highlight: "Système de recommandations",
                                text: "Suggestions de contenu et mise en avant de streams pertinents"
                            },
                        ],
                    },
                    support: "Soutenez le projet sur Ko-fi",
                },
                details: {
                    tootltip1: "Voir la chaine",
                    about: "Concernant",
                    noDescription: "Pas de description 😕"
                },
                playlist: {
                    noVideo: "Aucune vidéo dans la playlist.",
                    prev: "Précédent",
                    next: "Suivant",
                    delete: "Supprimer",
                    deleteAll: "Supprimer tout",
                    t1: "Lire en boucle la playlist",
                    t2: "Lire en boucle la vidéo",
                    t3: "Désactiver la lecture en boucle de la vidéo"
                }
            }
        },
    }
;

i18n
    .use(LanguageDetector) // Détection automatique de la langue
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en", // Langue de repli
        supportedLngs: ['en', 'fr'], // Langues supportées
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['navigator'], // Détection automatique de la langue du navigateur
            caches: ['localStorage'], // Optionnel: Sauvegarde dans localStorage
        },
    });