import {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {useScreen} from "../contexts/ScreenContext";
import userAccessTokenService from "../services/Twitch/TwitchUserAccessTokenService";
import userSuggestionService from "../services/UserSuggestionService";
import {setIsDarkMode} from "../slices/themeSlice";
import styled from "styled-components";
import {
    ButtonSpinner,
    Spinner,
    SuggestionItem,
    SuggestionsList,
} from "./StyledControls";
import {BiMoon, BiSun} from "react-icons/bi";
import {AiOutlineSetting} from "react-icons/ai";
import {MdLogout} from "react-icons/md";
import {FaSearch, FaTimes, FaTwitch} from "react-icons/fa";
import {useTranslation} from "react-i18next";

const Header = ({user}) => {
    const {t} = useTranslation();

    const buttonRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {isMobile} = useScreen();

    const isDarkMode = useSelector((state) => state.theme.isDarkMode);

    const [leftMenuOpen, setLeftMenuOpen] = useState(false);
    const [leftMenuPosition, setLeftMenuPosition] = useState({top: 0, left: 0});
    const [profileMenuOpen, setProfileMenuOpen] = useState(false);

    const [isLogging, setIsLogging] = useState(false);

    const [searchInput, setSearchInput] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);

    const toggleLeftMenu = () => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            setLeftMenuPosition({top: rect.bottom, left: rect.left});
        }
        setLeftMenuOpen((prev) => !prev);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        const normalizedSuggestion =
            userSuggestionService.normalizeSuggestion(searchInput);
        navigate(`/channel/${encodeURIComponent(normalizedSuggestion)}`);
        setSuggestions([]);
    };

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchInput(value);
        // filter sugesstions
        const filteredSuggestions = userSuggestionService.filterSuggestions(value);
        setSuggestions(filteredSuggestions);
    };

    const handleKeyDown = (event) => {
        if (event.key === "ArrowDown") {
            setSelectedSuggestionIndex((prevIndex) =>
                Math.min(prevIndex + 1, suggestions.length - 1)
            );
        } else if (event.key === "ArrowUp") {
            setSelectedSuggestionIndex((prevIndex) => Math.max(prevIndex - 1, -1));
        } else if (event.key === "Enter" && selectedSuggestionIndex >= 0) {
            setSearchInput(suggestions[selectedSuggestionIndex]);
            setSelectedSuggestionIndex(-1);
            setSuggestions([]);
        }
    };

    const handleSelectSuggestion = (suggestion) => {
        setSearchInput(suggestion);
        setSuggestions([]);
    };

    const handleLogin = async () => {
        setIsLogging(true);
        try {
            const returnUrl = window.location.pathname + window.location.search;
            await userAccessTokenService.login(returnUrl);
        } catch (error) {
            throw new Error(`Header - handleLogin - error ${error.message}`);
        } finally {
            setIsLogging(false);
        }
    };

    return (
        <HeaderContainer>
            <LeftSection>
                <LogoContainer to="/">
                    <Logo
                        src={isMobile ? "/logo-small.png" : "/logo.png"}
                        alt="Twitch Playlist"
                    />
                </LogoContainer>

                <NavLinks>
                    {user && <NavLink to="/following">{t("header.link1")}</NavLink>}
                    <NavLink to="/directory">{t("header.link2")}</NavLink>
                </NavLinks>
                <MenuButton ref={buttonRef} onClick={toggleLeftMenu}>
                    &#x22EE;
                </MenuButton>
                <MobileMenu
                    isOpen={leftMenuOpen}
                    top={leftMenuPosition.top}
                    left={leftMenuPosition.left}
                    onMouseLeave={() => setLeftMenuOpen(false)}
                >
                    {isMobile ? (
                        <>
                            {user && <MobileNavLink to="/following">{t("header.link1")}</MobileNavLink>}
                            <MobileNavLink to="/directory">{t("header.link2")}</MobileNavLink>
                            <MobileNavLink to="/multistream">{t("header.link3")}</MobileNavLink>
                            <MobileNavLink to="/about">{t("header.link4")}</MobileNavLink>
                        </>
                    ) : (
                        <>
                            <MobileNavLink to="/multistream">{t("header.link3")}</MobileNavLink>
                            <MobileNavLink to="/about">{t("header.link4")}</MobileNavLink>
                        </>
                    )}
                </MobileMenu>
            </LeftSection>

            <CenterSection>
                <SearchContainer>
                    <SearchForm onSubmit={handleSearch}>
                        <ChannelSearchInput
                            type="text"
                            placeholder={t("header.placeholder")}
                            value={searchInput}
                            onChange={handleSearchChange}
                            onKeyDown={handleKeyDown}
                        />
                        <SearchIconWrapper>
                            {searchInput ? (
                                <ClearIcon onClick={() => setSearchInput("")}/>
                            ) : (
                                <SearchIcon/>
                            )}
                        </SearchIconWrapper>
                    </SearchForm>
                    {searchInput && suggestions.length > 0 && (
                        <SuggestionsList>
                            {suggestions.map((suggestion, index) => (
                                <SuggestionItem
                                    key={index}
                                    onClick={() => handleSelectSuggestion(suggestion)}
                                    isSelected={index === selectedSuggestionIndex}
                                >
                                    {suggestion}
                                </SuggestionItem>
                            ))}
                        </SuggestionsList>
                    )}
                </SearchContainer>
            </CenterSection>

            <RightSection>
                {user ? (
                    <>
                        <ProfileButton onClick={() => setProfileMenuOpen(!profileMenuOpen)}>
                            <ProfileImage src={user.profile_image_url} alt="profile"/>
                        </ProfileButton>
                        <DropdownMenu
                            isOpen={profileMenuOpen}
                            onMouseLeave={() => setProfileMenuOpen(false)}
                        >
                            <DropdownItem>
                                <ThemeText>
                                    {isDarkMode ? (
                                        <ThemeIcon>
                                            <BiMoon/>
                                        </ThemeIcon>
                                    ) : (
                                        <ThemeIcon>
                                            <BiSun/>
                                        </ThemeIcon>
                                    )}
                                    {isDarkMode ? t("header.darkTheme") : t("header.lightTheme")}
                                    <SwitchContainer>
                                        <SwitchInput
                                            type="checkbox"
                                            checked={isDarkMode}
                                            onChange={() => dispatch(setIsDarkMode(!isDarkMode))}
                                        />
                                        <SwitchLabel isChecked={isDarkMode}/>
                                    </SwitchContainer>
                                </ThemeText>
                            </DropdownItem>
                            <DropdownItem onClick={() => navigate("/settings")}>
                                <AiOutlineSetting style={{marginRight: "8px"}}/>
                                {t("header.settings")}
                            </DropdownItem>
                            <Separator/>
                            <DropdownItem onClick={() => userAccessTokenService.logout()}>
                                <MdLogout style={{marginRight: "8px"}}/>
                                {t("header.logout")}
                            </DropdownItem>
                        </DropdownMenu>
                    </>
                ) : (
                    <SmallLoginButton onClick={handleLogin}>
                        {isLogging ? <ButtonSpinner size={24}/> : <FaTwitch size={24}/>}
                        {isLogging ? <span>{t("header.logging")}</span> : <span>{t("header.login")}</span>}
                    </SmallLoginButton>
                )}
            </RightSection>
        </HeaderContainer>
    );
};

//region Styled

const HeaderContainer = styled.header`
    z-index: 2000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${(props) => props.theme.headerBg};
    color: ${(props) => props.theme.color};
    padding: 0 8px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 6px ${(props) => props.theme.shadowLight};
    border-bottom: 1px solid ${(props) => props.theme.border};

    @media (max-width: 375px) {
        padding: 0 6px;
        height: 45px;
    }
`;

const LeftSection = styled.div`
    display: flex;
    align-items: center;
    min-width: 100px;

    @media (max-width: 375px) {
        min-width: 80px;
    }
`;

const CenterSection = styled.div`
    flex: 1;
    max-width: 250px;
    min-width: 100px;
    margin: 0 8px;

    @media (max-width: 375px) {
        max-width: 180px;
        min-width: 80px;
        margin: 0 4px;
    }
`;

const RightSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 100px;

    @media (max-width: 375px) {
        min-width: 80px;
    }
`;

const Logo = styled.img`
    height: 30px;

    @media (max-width: 375px) {
        height: 25px;
    }
`;

const ProfileImage = styled.img`
    width: 28px;
    height: 28px;
    border-radius: 50%;
    object-fit: cover;

    @media (max-width: 375px) {
        width: 24px;
        height: 24px;
    }
`;

const ProfileButton = styled.button`
    padding: 2px;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;

    @media (max-width: 375px) {
        margin-left: 4px;
    }
`;

const SmallLoginButton = styled.button`
    font-size: 12px;
    padding: 6px 10px;
    display: flex;
    align-items: center;
    gap: 0.6rem;
    font-weight: 600;
    color: white;
    background: #9146ff;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: all 0.3s ease;

    svg {
        font-size: 1.2rem;
    }

    &:hover:not(:disabled) {
        transform: translateY(-1px);
        background: #7f2fff;
    }

    @media (max-width: 768px) {
        font-size: 11px;
        padding: 5px 8px;
        margin-left: 4px;
        gap: 0.25rem;

        svg {
            font-size: 1rem;
        }

        span {
            display: none;
        }
    }

    @media (max-width: 375px) {
        padding: 4px 6px;
        margin-left: 2px;
    }
`;

const ChannelSearchInput = styled.input`
    width: 100%;
    padding: 5px 10px; // Reduced from 6px 14px
    border: 2px solid ${(props) => props.theme.inputBorder};
    border-radius: 6px;
    background: ${(props) => props.theme.inputBg};
    color: ${(props) => props.theme.inputText};
    font-size: 12px; // Reduced from 13px

    @media (max-width: 375px) {
        padding: 4px 8px;
        font-size: 11px;
    }
`;

const NavLink = styled(Link)`
    color: ${(props) => props.theme.color};
    text-decoration: none;
    font-weight: 600;
    font-size: 14px; // Decreased from 15px
    padding: 6px 10px; // Decreased from 8px 12px
    border-radius: 4px;
    transition: color 0.2s ease, background-color 0.2s ease;

    &:hover {
        color: ${(props) => props.theme.primary};
        background-color: ${(props) => props.theme.hoverBg};
    }

    @media (max-width: 768px) {
        font-size: 13px; // Decreased from 14px
        padding: 5px 8px; // Decreased from 6px 10px
    }
`;

const SearchForm = styled.form`
    position: relative;
    width: 100%;
`;

const SearchIconWrapper = styled.div`
    position: absolute;
    right: 0; // Augmenté de 8px à 12px
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
`;

const SearchContainer = styled.div`
    position: relative;
    width: 100%;
`;

const LogoContainer = styled(Link)`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 12px; // Augmenté de 10px à 12px
`;

const NavLinks = styled.nav`
    display: flex;
    gap: 16px; // Augmenté de 10px à 16px

    @media (max-width: 768px) {
        display: none;
    }
`;

const DropdownMenu = styled.div.withConfig({
    shouldForwardProp: (prop) => !["isOpen"].includes(prop),
})`
    display: ${(props) => (props.isOpen ? "block" : "none")};
    position: absolute;
    top: 60px;
    right: 20px;
    background: ${(props) => props.theme.headerBg};
    border: 1px solid ${(props) => props.theme.border};
    border-radius: 8px;
    padding: 8px;
    min-width: 200px;
    z-index: 1000;
    box-shadow: 0 4px 12px ${(props) => props.theme.shadowMedium};

    @media (max-width: 375px) {
        top: 50px;
        right: 10px;
        min-width: 180px;
        padding: 6px;
    }
`;

const DropdownItem = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s ease;

    &:hover {
        background: ${(props) => props.theme.hoverBg};
    }

    &:active {
        background: ${(props) => props.theme.activeBg};
    }
`;

const SwitchLabel = styled.span.withConfig({
    shouldForwardProp: (prop) => !["isChecked"].includes(prop),
})`
    width: 44px;
    height: 24px;
    background: ${(props) =>
    props.isChecked ? props.theme.primary : props.theme.disabled};
    border-radius: 12px;
    position: relative;
    transition: background 0.3s ease;
    margin-left: 10px;

    &:before {
        content: "";
        position: absolute;
        top: 2px;
        left: ${(props) => (props.isChecked ? "22px" : "2px")};
        width: 20px;
        height: 20px;
        background: white;
        border-radius: 50%;
        transition: left 0.3s ease;
        box-shadow: 0 1px 3px ${(props) => props.theme.shadowLight};
    }
`;

const MenuButton = styled.button`
    display: block;
    background: transparent;
    border: none;
    color: ${(props) => props.theme.color};
    font-size: 24px;
    cursor: pointer;
    margin-left: 20px;
`;

const MobileMenu = styled.div.withConfig({
    shouldForwardProp: (prop) => !["isOpen", "top", "left"].includes(prop),
})`
    display: ${(props) => (props.isOpen ? "flex" : "none")};
    flex-direction: column;
    position: absolute;
    top: ${(props) => props.top}px;
    left: ${(props) => props.left}px;
    background: ${(props) => props.theme.background};
    border: 1px solid ${(props) => props.theme.primary};
    border-radius: 5px;
    z-index: 1000;
`;

const MobileNavLink = styled(Link)`
    padding: 10px;
    color: ${(props) => props.theme.color};
    text-decoration: none;

    &:hover {
        background: ${(props) => props.theme.secondary};
    }
`;

const Separator = styled.div`
    height: 1px;
    background: ${(props) => props.theme.border};
    margin: 5px 0;
`;

const SwitchContainer = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const SwitchInput = styled.input`
    display: none;
`;

const ThemeText = styled.span`
    display: flex;
    align-items: center;
`;

const ThemeIcon = styled.span`
    margin-right: 8px;
`;

// -- SEARCH --

const SearchIcon = styled(FaSearch)`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: ${(props) => props.theme.color};
    pointer-events: none;
`;

const ClearIcon = styled(FaTimes)`
    position: absolute;
    right: 0;
    top: 50%; /* Centrer verticalement */
    transform: translateY(-50%); /* Ajuster pour un centrage parfait */
    color: ${(props) => props.theme.color};
    cursor: pointer;
`;

//endregion

export default Header;
