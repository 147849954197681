import React, {createContext, useContext, useState, useEffect} from "react";

// Contexte
const ScreenContext = createContext();

// provider
export const ScreenProvider = ({children}) => {
    const [isMobile, setIsMobile] = useState(false);
    const [isLargeScreen, setIsLargeScreen] = useState(false);

    useEffect(() => {
        // Définir les media queries
        const mediaQueryLarge = window.matchMedia("(min-width: 1200px)");
        const mediaQueryMobile = window.matchMedia("(max-width: 768px)");

        // Initialiser les états en fonction des media queries
        setIsLargeScreen(mediaQueryLarge.matches);
        setIsMobile(mediaQueryMobile.matches);

        // Fonctions pour gérer les changements
        const handleLargeChange = () => {
            setIsLargeScreen(mediaQueryLarge.matches);
        };

        const handleMobileChange = () => {
            setIsMobile(mediaQueryMobile.matches);
        };

        // Ajouter les écouteurs d'événements
        mediaQueryLarge.addEventListener("change", handleLargeChange);
        mediaQueryMobile.addEventListener("change", handleMobileChange);

        // Nettoyer les écouteurs lors du démontage
        return () => {
            mediaQueryLarge.removeEventListener("change", handleLargeChange);
            mediaQueryMobile.removeEventListener("change", handleMobileChange);
        };
    }, []);

    return (
        <ScreenContext.Provider value={{isMobile, isLargeScreen}}>
            {children}
        </ScreenContext.Provider>
    );
};

// Hook pour utiliser le contexte
export const useScreen = () => {
    return useContext(ScreenContext);
};
