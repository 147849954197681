import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {
    setInfos,
    setIsMiniPlayer,
    setPlaylistIndex
} from "../slices/playerSlice";
import TwitchAPIService from "../services/Twitch/TwitchAPIService";
import PlaylistService from "../services/Supabase/SupabasePlaylistService";
import VideoDetails from "../components/VideoDetails";
import StreamDetails from "../components/StreamDetails";
import Playlist from "../components/Playlist";
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorPage from "./ErrorPage";
import logger from "../services/Logger";

const WatchPage = ({user}) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const [playerMode, setPlayerMode] = useState("VIDEO");

    const [playlistDetails, setPlaylistDetails] = useState(null);
    const [currentPlaylistIndex, setCurrentPlaylistIndex] = useState(0);

    // videos of playlist
    const [loadingVideos, setLoadingVideos] = useState(false);
    const [videos, setVideos] = useState([]);

    const [error, setError] = useState(null);

    const fetchVideos = async (playlist_videos) => {
        setLoadingVideos(true);
        try {
            const ids = playlist_videos.map((v) => v.video_id);
            const newVideos = await TwitchAPIService.VideoService.getVideos(ids);
            if (!newVideos || newVideos.length === 0)
                throw new Error("Pas de vidéos trouvées pour cette playlist.");
            setVideos(newVideos);
        } catch (error) {
            logger.error(
                `WatchPage - fetchVideos - Error fetching videos: ${error.message}`
            );
            setError("Pas de vidéos trouvées.");
        } finally {
            setLoadingVideos(false);
        }
    };

    const fetchData = async (video, channel, list, index) => {
        if (!video && !channel && !list) {
            setError("Video, Channel, ou Playlist attendus.");
            return;
        }

        try {
            if (channel) {
                setPlayerMode("CHANNEL");
                TwitchAPIService.UserService.getUserByLogin(channel)
                    .then((channelExists) => {
                        if (!channelExists) throw new Error("Channel non trouvé.");
                    })
                    .catch((error) => {
                        logger.error(`WatchPage - useEffect : ${error.message}`);
                        setError("Channel non trouvé.");
                    });
            }

            let playlist = null;
            if (list) {
                // load playlist
                playlist = await PlaylistService.getPlaylistById(list);
                if (!playlist) {
                    logger.error("WatchPage - useEffect -Playlist not found.");
                    setError("Playlist non trouvée.");
                    return;
                }
                setPlaylistDetails(playlist);

                // playlist index
                if (index >= playlist.playlist_videos.length) {
                    logger.warn(
                        "WatchPage - useEffect -Invalid playlist index, resetting to 0."
                    );
                    index = 0;
                }
                setCurrentPlaylistIndex(index);
                await PlaylistService.setPlaylistIndex(list, index);

                video = playlist.playlist_videos[index].video_id;
                setPlayerMode("PLAYLIST");

                // fetch video details for playlist
                await fetchVideos(playlist.playlist_videos);
            }

            if (video || channel) {
                dispatch(
                    setInfos({
                        video: video,
                        channel: channel,
                        playlistIndex: index,
                        playlist: playlist,
                        isMiniPlayer: false
                    })
                );
            }
        } catch (error) {
            logger.error(`WatchPage - Loding - Error: ${error.message}`);
            setError("Une erreur est survenue.");
        }
    };

    useEffect(() => {
        return () => {
            dispatch(setIsMiniPlayer(true));
        };
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        let video = queryParams.get("video");
        const channel = queryParams.get("channel");
        const list = queryParams.get("list");
        let index = parseInt(queryParams.get("index"), 10) || 0;

        fetchData(video, channel, list, index);

    }, [location.search]);

    const handleRemoveVideoFromCurrentPlaylist = async (index) => {
        const videoId = playlistDetails.playlist_videos[index].video_id;
        const result = await PlaylistService.removeVideoFromPlaylist(
            playlistDetails.id,
            videoId
        );

        if (result) {
            const newPlaylist = await PlaylistService.getPlaylistById(
                playlistDetails.id
            );
            setPlaylistDetails(newPlaylist);
            setVideos(videos.filter((_, i) => i !== index));
        }
    };

    const handleClearVideosFromCurrentPlaylist = async () => {
        const result = await PlaylistService.clearVideosFromPlaylist(
            playlistDetails.id
        );
        if (result) {
            const newPlaylist = await PlaylistService.getPlaylistById(
                playlistDetails.id
            );
            setPlaylistDetails(newPlaylist);
            setVideos([]);
        }
    };

    const handleDragEnd = async (result) => {
        if (!result.destination) return;

        try {
            const sourceIndex = result.source.index;
            const destinationIndex = result.destination.index;
            const currentVideo = videos[currentPlaylistIndex];

            const reorderedVideoIds = Array.from(playlistDetails.playlist_videos);
            const [movedVideoId] = reorderedVideoIds.splice(sourceIndex, 1);
            reorderedVideoIds.splice(destinationIndex, 0, movedVideoId);

            // on clear les videos de la playlist
            await PlaylistService.clearVideosFromPlaylist(playlistDetails.id);
            // ... et on ajoute dans l'ordre
            for (let i = 0; i < reorderedVideoIds.length; i++) {
                await PlaylistService.addVideoToPlaylist(
                    playlistDetails.id,
                    reorderedVideoIds[i].video_id
                );
            }

            // on recharge la playlist
            const newPlaylist = await PlaylistService.getPlaylistById(
                playlistDetails.id
            );
            setPlaylistDetails(newPlaylist);

            // on cherche l'index de la current video
            const index = newPlaylist.playlist_videos.findIndex(
                (v) => v.video_id === currentVideo.id
            );
            // ... et on change l'index si besoin
            if (index !== currentPlaylistIndex) {
                await PlaylistService.setPlaylistIndex(newPlaylist.id, index);
                dispatch(setPlaylistIndex(index));
                setCurrentPlaylistIndex(index);
            }

            // on reorder les videos
            const reorderedVideos = Array.from(videos);
            const [movedVideo] = reorderedVideos.splice(sourceIndex, 1);
            reorderedVideos.splice(destinationIndex, 0, movedVideo);
            setVideos(reorderedVideos);
        } catch (error) {
            logger.error(`WatchPage - handleDragEnd - Error: ${error.message}`);
        }
    };

    if (loadingVideos) return <LoadingSpinner/>;

    if (error) return <ErrorPage message={error}/>;

    if (playerMode === "VIDEO") return <VideoDetails user={user}/>;

    if (playerMode === "CHANNEL") return <StreamDetails/>;

    return (
        <>
            {videos.length > 0 && <VideoDetails user={user}/>}
            <Playlist
                videos={videos}
                onRemoveVideo={handleRemoveVideoFromCurrentPlaylist}
                onClearVideos={handleClearVideosFromCurrentPlaylist}
                onDragEnd={handleDragEnd}
            />
        </>
    );
};

export default WatchPage;
