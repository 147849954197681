import {Link} from "react-router-dom";
import {BigLogo, ErrorContainer} from "../components/StyledControls";

const NotFound = () => {
    return (
        <ErrorContainer>
            <BigLogo src="/logo.png" alt="Twitch Playlist"/>
            <h1>404</h1>
            <p>La page n'existe pas!</p>
            <Link to="/">Retour vers Accueil</Link>
        </ErrorContainer>
    );
};


export default NotFound;
