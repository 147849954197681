export class CacheService {
  static defaultExpiration = 60000; // 1 minute par défaut

  getCacheFromStorage(cacheKey) {
    return JSON.parse(sessionStorage.getItem(cacheKey)) || {};
  }

  saveCacheToStorage(cacheKey, cache) {
    sessionStorage.setItem(cacheKey, JSON.stringify(cache));
  }

  removeFromStorage(cacheKey) {
    sessionStorage.removeItem(cacheKey);
  }

  isCacheExpired(cache) {
    if (!cache || !cache.expiryTime) {
      return true; // Si pas de cache ou pas de timestamp d'expiration, considérer comme expiré
    }
    return Date.now() > cache.expiryTime; // Vrai si l'heure actuelle dépasse l'expiration
  }

   set(key, value, expiration = CacheService.defaultExpiration) {
    const expiryTime = Date.now() + expiration;
    const cacheData = { value, expiryTime };

    this.saveCacheToStorage(key, cacheData);
  }

  get(key) {
    const cached = this.getCacheFromStorage(key);

    if (!cached.value || this.isCacheExpired(cached)) {
      this.removeFromStorage(key); // Si expiré, on le supprime du sessionStorage
      return null;
    }
    return cached.value;
  }

  clear() {
    sessionStorage.clear();
  }

   delete(key) {
    sessionStorage.removeItem(key);
  }
}

const cacheService = new CacheService();
export default cacheService;
