import { useState } from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const languageCodes = [
  { language: "Français", code: "fr" },
  { language: "English", code: "en" },
  { language: "Bahasa Indonesia", code: "id" },
  { language: "Català", code: "ca" },
  { language: "Dansk", code: "da" },
  { language: "Deutsch", code: "de" },
  { language: "Español", code: "es" },
  { language: "Italiano", code: "it" },
  { language: "Magyar", code: "hu" },
  { language: "Nederlands", code: "nl" },
  { language: "Norsk", code: "no" },
  { language: "Polski", code: "pl" },
  { language: "Português", code: "pt" },
  { language: "Română", code: "ro" },
  { language: "Slovenčina", code: "sk" },
  { language: "Suomi", code: "fi" },
  { language: "Svenska", code: "sv" },
  { language: "Tagalog", code: "tl" },
  { language: "Tiếng Việt", code: "vi" },
  { language: "Türkçe", code: "tr" },
  { language: "Čeština", code: "cs" },
  { language: "Ελληνικά", code: "el" },
  { language: "Български", code: "bg" },
  { language: "Русский", code: "ru" },
  { language: "Українська", code: "uk" },
  { language: "العربية", code: "ar" },
  { language: "بهاس ملايو", code: "ms" },
  { language: "मानक हिन्दी", code: "hi" },
  { language: "ภาษาไทย", code: "th" },
  { language: "中文", code: "zh" },
  { language: "日本語", code: "ja" },
  { language: "한국어", code: "ko" },
  { language: "American Sign Language", code: "as" },
  { language: "Autres", code: "other" },
];

const LanguageDropdown = ({ selectedLanguages, setSelectedLanguages }) => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  return (
    <DropdownContainer onClick={toggleDropdown}>
      <SelectedLanguages>
        {t("languageLabel")}
        {selectedLanguages.length > 0 && (
          <LanguageCount>{selectedLanguages.length}</LanguageCount>
        )}
      </SelectedLanguages>
      {isOpen && (
        <Dropdown>
          {languageCodes.map(({ language, code }) => (
            <LanguageItem key={code} onClick={() => setSelectedLanguages(code)}>
              <Checkbox
                type="checkbox"
                checked={selectedLanguages.includes(code)}
                readOnly
              />
              {language}
            </LanguageItem>
          ))}
        </Dropdown>
      )}
    </DropdownContainer>
  );
};


const DropdownContainer = styled.div`
  position: relative;
  width: 200px;
  cursor: pointer;
`;

const SelectedLanguages = styled.div`
  padding: 8px 12px;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 4px;
  background: ${({ theme }) => theme.backgroundColor};
  cursor: pointer;
  transition: background 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: ${({ theme }) => theme.background};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.border};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  margin-top: 5px;
  max-height: 150px; // Réduction de la hauteur maximale
  overflow-y: auto;
  width: 100%; // Ajustement pour prendre toute la largeur
`;

const LanguageItem = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isSelected",
})`
  padding: 8px 10px; // Réduction du padding
  display: flex;
  align-items: center;
  font-size: 14px; // Réduction de la taille de la police
  background: ${({ isSelected, theme }) =>
    isSelected ? theme.selectedBackground : theme.background};

  &:hover {
    background-color: ${({ theme }) => theme.hoverBackground};
  }
`;

const LanguageCount = styled.span`
  background-color: violet; /* Couleur violet */
  color: white;
  border-radius: 12px; /* Arrondi léger */
  padding: 2px 6px;
  font-size: 0.8em; /* Taille du texte légèrement plus petite */
  margin-left: 8px; /* Ajoute un espace à gauche du compteur */
`;

const Checkbox = styled.input`
  margin-right: 6px; // Réduction de la marge à droite
  transform: scale(1.1); // Légère augmentation de la taille de la case à cocher
`;

export default LanguageDropdown;
