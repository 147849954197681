// Base theme with common values and defaults
const baseTheme = {
    // Main colors
    primary: '#9147ff', // Utilisé dans les boutons principaux
    secondary: '#772ce8', // Couleur secondaire
    tertiary: '#5c16c5', // Couleur tertiaire

    // Status colors
    success: '#00c73c', // Couleur de succès
    successHover: '#388e3c', // Hover pour le succès
    error: '#f04747', // Couleur d'erreur
    errorHover: '#c82333', // Hover pour l'erreur
    warning: '#faa61a', // Couleur d'avertissement

    // Interactive states
    buttonHover: '#772ce8', // Hover des boutons
    buttonActive: '#5c16c5', // Bouton actif
    highlightColor: '#A95DFF', // Couleur de surbrillance

    // Transitions
    transition: 'all 0.5s ease', // Transition générale
};

// Light theme configuration
export const lightTheme = {
    ...baseTheme, // Fusion avec le thème de base

    // Main colors
    background: '#ffffff', // Couleur de fond (blanc)
    color: '#0e0e10', // Couleur du texte principal

    // Layout elements
    headerBg: '#ffffff', // Fond de l'en-tête
    cardBackground: '#f5f5f5', // Fond des cartes

    // Interactive elements
    hoverBg: '#f7f7f8', // Hover sur les éléments
    activeBg: '#efeff1', // Fond actif
    selectedBackground: '#e0e0e0', // Fond sélectionné
    selectedItemBackground: '#D1D1D1', // Fond de l'élément sélectionné
    hoverBackground: '#999', // Fond au survol

    // Input elements
    inputBg: '#f2f2f2', // Fond des champs de saisie
    inputBorder: '#e5e5e5', // Bordure des champs de saisie
    inputText: '#0e0e10', // Texte dans les champs de saisie
    inputPlaceholder: '#53535f', // Texte des placeholders
    inputBackground: '#2f2f2f', // Fond de saisie

    // Text variations
    titleColor: '#333333', // Couleur des titres
    textMuted: '#53535f', // Texte atténué
    textDimmed: '#1f1f23', // Texte plus pâle
    linkText: '#ffffff', // Couleur des liens

    // Tags and badges
    tagBackground: '#ADADB838', // Fond des étiquettes
    tagText: '#53535F', // Texte des étiquettes

    // Borders and shadows
    border: '#e5e5e5', // Bordures générales
    shadowLight: 'rgba(0, 0, 0, 0.1)', // Ombre légère
    shadowMedium: 'rgba(0, 0, 0, 0.15)', // Ombre moyenne

    // Disabled states
    disabled: '#e5e5e5', // Couleur des éléments désactivés
    disabledText: '#53535f', // Texte des éléments désactivés

    // Additional theme-specific values for light mode
    barBackground: 'rgba(247, 247, 248, 0.95)',
    barShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    thumbnailHoverShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
    buttonBackground: '#efeff1',
    buttonBackgroundHover: '#e6e6ea',
    buttonBackgroundDisabled: '#f7f7f8',
    buttonColorDisabled: '#93939e',
    primaryButton: '#9147ff', // Couleur du bouton principal
    primaryButtonHover: '#772ce8', // Hover du bouton principal
    textColor: '#18181b',
    borderColor: '#dedee3',
    gradientOverlay: {
        default: 'rgba(239, 239, 241, 0.2)',
        hover: 'rgba(239, 239, 241, 0.1)',
    },

    // scrollbar
    scrollbarTrack: "#f1f1f1",
    scrollbarThumb: "#ccc",
    scrollbarThumbHover: "#aaa",
};

// Dark theme configuration
export const darkTheme = {
    ...baseTheme, // Fusion avec le thème de base

    // Main colors
    background: '#0e0e10', // Couleur de fond (sombre)
    color: '#efeff1', // Couleur du texte principal

    // Layout elements
    headerBg: '#18181b', // Fond de l'en-tête
    cardBackground: '#29292e', // Fond des cartes

    // Interactive elements
    hoverBg: '#1f1f23', // Hover sur les éléments
    activeBg: '#26262c', // Fond actif
    selectedBackground: '#4c4c4c', // Fond sélectionné
    selectedItemBackground: '#3a3b3f', // Fond de l'élément sélectionné
    hoverBackground: '#3c3c3c', // Fond au survol

    // Input elements
    inputBg: '#18181b', // Fond des champs de saisie
    inputBorder: '#303032', // Bordure des champs de saisie
    inputText: '#efeff1', // Texte dans les champs de saisie
    inputPlaceholder: '#adadb8', // Texte des placeholders
    inputBackground: '#2f2f2f', // Fond de saisie

    // Text variations
    titleColor: '#f2f2f2', // Couleur des titres
    textMuted: '#adadb8', // Texte atténué
    textDimmed: '#dedee3', // Texte plus pâle
    linkText: '#ffffff', // Couleur des liens

    // Tags and badges
    tagBackground: '#27272a', // Fond des étiquettes
    tagText: '#d4d4d8', // Texte des étiquettes

    // Borders and shadows
    border: '#303032', // Bordures générales
    shadowLight: 'rgba(0, 0, 0, 0.2)', // Ombre légère
    shadowMedium: 'rgba(0, 0, 0, 0.4)', // Ombre moyenne

    // Disabled states
    disabled: '#303032', // Couleur des éléments désactivés
    disabledText: '#898989', // Texte des éléments désactivés

    // Additional theme-specific values for dark mode
    barBackground: 'rgba(14, 14, 16, 0.95)',
    barShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    thumbnailHoverShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    buttonBackground: '#2d2d34',
    buttonBackgroundHover: '#3a3a43',
    buttonBackgroundDisabled: '#18181b',
    buttonColorDisabled: '#53535f',
    primaryButton: '#9147ff', // Couleur du bouton principal
    primaryButtonHover: '#772ce8', // Hover du bouton principal
    textColor: '#ffffff',
    borderColor: '#2d2d34',
    gradientOverlay: {
        default: 'rgba(0, 0, 0, 0.2)',
        hover: 'rgba(0, 0, 0, 0.1)',
    },

    // scrollbar
    scrollbarTrack: "#444",
    scrollbarThumb: "#888",
    scrollbarThumbHover: "#aaa",
};



