import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import twitchAPIService from "../services/Twitch/TwitchAPIService";
import userSuggestionService from "../services/UserSuggestionService";
import favoriteUsersService from "../services/Supabase/SupabaseFavoriteUsersService";
import {formatDuration, formatDate} from "../utils/dateUtils";
import ErrorPage from "./ErrorPage";
import LoadingSpinner from "../components/LoadingSpinner";
import LiveStatusProfile from "../components/LiveStatusProfile";
import {
    CenteredContainer,
    FavoriteButton,
    TabButton,
    TwitchLink,
    LoadMoreButton, LoadingIndicator, ButtonSpinner
} from "../components/StyledControls";
import MultiplePlaylistSelector from "../components/MultiplePlaylistSelector";
import styled from "styled-components";
import Logger from "../services/Logger";
import CacheService from "../services/CacheService";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";
import {MoreVertical, CheckCircle} from "lucide-react";
import playlistService from "../services/Supabase/SupabasePlaylistService";
import logger from "../services/Logger";
import {useTranslation} from "react-i18next";

const ChannelPage = ({user}) => {
    const {t} = useTranslation();

    const {login} = useParams();
    const [error, setError] = useState();
    const [loadingChannelUser, setLoadingChannelUser] = useState(true);
    const [channelUser, setChannelUser] = useState();
    const [isOnline, setIsOnline] = useState(false);

    const [loadingVideos, setLoadingVideos] = useState(false);
    const [loadingMoreVideos, setLoadingMoreVideos] = useState(false);
    const [videos, setVideos] = useState([]);
    const [paginationCursor, setPaginationCursor] = useState(null);

    const [loadingPlaylists, setLoadingPlaylists] = useState(true);
    const [playlists, setPlaylists] = useState();

    const [isFavoriteUserAdded, setFavoriteUserAdded] = useState();

    const [activeTab, setActiveTab] = useState("videos");

    const fetchChannelUser = async (username) => {
        if (username === undefined) throw new Error("username required");

        setLoadingChannelUser(true);
        try {
            const userId = await twitchAPIService.UserService.getUserId(username);
            const twitchUser = await twitchAPIService.UserService.getUserById(
                userId
            );
            setChannelUser(twitchUser);

            const isOnline = await twitchAPIService.StreamService.checkIfUserIsLive(
                userId
            );
            setIsOnline(isOnline);

            if (user) {
                const shortcut = await favoriteUsersService.getById(user.id, twitchUser.id);
                if (shortcut) setFavoriteUserAdded(true);
            }

            userSuggestionService.addSuggestion(username);
        } catch (error) {
            Logger.error(`ChannelPage - fetchUser - error ${error.message}`);
            setError(error);
        } finally {
            setLoadingChannelUser(false);
        }
    };

    const fetchVideos = async (userId, isLoadMore = false) => {
        if (userId === undefined) throw new Error("userId required");

        if (isLoadMore) setLoadingMoreVideos(true);
        else {
            setLoadingVideos(true);
            setVideos([]);
        }

        try {
            const videoData = await twitchAPIService.VideoService.getVideosByUserId(
                userId,
                paginationCursor
            );
            setVideos((prev) => [...prev, ...videoData.data]);
            setPaginationCursor(videoData.pagination.cursor || null);
        } catch (err) {
            Logger.error(`ChannelPage - fetchVideos - error ${error.message}`);
        } finally {
            setLoadingVideos(false);
            setLoadingMoreVideos(false);
        }
    };

    const fetchPlaylists = async (userId) => {
        setLoadingPlaylists(true);
        try {
            const userPlaylists = await playlistService.getPlaylists(userId);
            setPlaylists(userPlaylists);
            return userPlaylists;
        } catch (error) {
            logger.error(`ChannelPage - fetchPlaylists - error ${error.message}`);
        } finally {
            setLoadingPlaylists(false);
        }
    };

    useEffect(() => {
        if (login) {
            fetchChannelUser(login);
        }
    }, [login]);

    useEffect(() => {
        if (channelUser) {
            fetchVideos(channelUser.id);
        }
    }, [channelUser]);

    useEffect(() => {
        if (user) {
            fetchPlaylists(user.id);
        }
    }, []);

    const toggleChannelUserToFavorites = async () => {
        if (isFavoriteUserAdded) {
            const removed = await favoriteUsersService.remove(user.id, channelUser.id);
            if (removed)
                setFavoriteUserAdded(false);
        } else {
            const added = await favoriteUsersService.add(user.id, channelUser.id);
            if (added)
                setFavoriteUserAdded(true);
        }

        CacheService.delete("favoriteUsers");
    };

    const handleAddPlaylist = async (name) => {
        const newPlaylist = await playlistService.addPlaylist(name, user.id);
        const newPlaylists = await playlistService.getPlaylists(user.id);
        setPlaylists(newPlaylists);
        return newPlaylist;
    };

    const handleAddVideoToPlaylist = async (playlistId, videoId) =>
        await playlistService.addVideoToPlaylist(playlistId, videoId);

    const handleRemoveVideoFromPlaylist = async (playlistId, videoId) =>
        await playlistService.removeVideoFromPlaylist(playlistId, videoId);

    if (error) {
        if (login) userSuggestionService.removeSuggestion(login);
        return <ErrorPage message={`Chaine non trouvée.`}/>;
    }

    if (loadingChannelUser) return <LoadingSpinner/>;

    return (
        <>
            <HeaderContainer>
                <ImageContainer>
                    <LiveStatusProfile
                        imageUrl={channelUser.profile_image_url}
                        alt={channelUser.display_name}
                        isOffline={!isOnline}
                        channelUrl={`/watch?channel=${channelUser.login}`}
                    />
                </ImageContainer>
                <UserInfo>
                    <UserName>{channelUser.display_name}</UserName>
                    <UserDescription>
                        {channelUser.description || t("game.noDescription")}
                    </UserDescription>
                    <div style={{marginTop: "10px"}}>
                        <TwitchLink
                            href={`https://www.twitch.tv/${channelUser.login}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{marginBottom: "10px"}}
                            data-tooltip-id="twitchChannelTooltip"
                        >
                            {t("channel.channel")}
                        </TwitchLink>
                    </div>
                    {user &&
                        <div style={{marginTop: "20px"}}>
                            <FavoriteButton onClick={async () => toggleChannelUserToFavorites()}>
                                {isFavoriteUserAdded ? (
                                    <AiFillStar
                                        size={24}
                                        style={{marginRight: "8px", color: "gold"}}
                                    />
                                ) : (
                                    <AiOutlineStar
                                        size={24}
                                        style={{marginRight: "8px", color: "gray"}}
                                    />
                                )}
                                {isFavoriteUserAdded
                                    ? t("channel.removeFavorite")
                                    : t("channel.addFavorite")}
                            </FavoriteButton>
                        </div>
                    }
                </UserInfo>
            </HeaderContainer>
            <div>
                <TabButton
                    active={activeTab === "videos"}
                    onClick={() => setActiveTab("videos")}
                >
                    {t("channel.videos")}
                </TabButton>
            </div>
            {activeTab === "videos" && loadingVideos && (
                <CenteredContainer>
                    <LoadingIndicator/>
                </CenteredContainer>
            )}
            {activeTab === "videos" && !loadingVideos && (
                <>
                    <GridContainer>
                        <Grid>
                            {videos.length > 0 ? (
                                videos.map((video, index) => (
                                    <VideoCard key={index}>
                                        <Link
                                            style={{position: "relative"}}
                                            to={`/watch?video=${video.id}`}
                                        >
                                            <ThumbnailContainer>
                                                <Thumbnail className="thumbnail">
                                                    <img
                                                        src={video.thumbnail_url
                                                            .replace("%{width}", "320")
                                                            .replace("%{height}", "180")}
                                                        alt={video.title}
                                                    />
                                                </Thumbnail>
                                                <Duration>{formatDuration(video.duration)}</Duration>
                                                <ViewCount>{video.view_count} {t("channel.views")}</ViewCount>
                                                <UploadTime>
                                                    {formatDate(video.published_at, t("language"))}
                                                </UploadTime>
                                            </ThumbnailContainer>
                                        </Link>
                                        <VideoInfo>
                                            <ChannelAvatar>
                                                <img
                                                    src={channelUser.profile_image_url}
                                                    alt={channelUser.display_name}
                                                />
                                            </ChannelAvatar>
                                            <MetaContainer>
                                                <Title>{video.title}</Title>
                                                <ChannelName>
                                                    {video.user_name}
                                                    <CheckCircle/>
                                                </ChannelName>
                                                <TextContent>
                                                    {user && !loadingPlaylists && (
                                                        <MultiplePlaylistSelector
                                                            videoId={video.id}
                                                            playlists={playlists}
                                                            addPlaylist={handleAddPlaylist}
                                                            addVideoToPlaylist={handleAddVideoToPlaylist}
                                                            removeVideoFromPlaylist={handleRemoveVideoFromPlaylist}
                                                            data-tooltip-id="addToPlaylistTooltip"
                                                        />
                                                    )}
                                                </TextContent>
                                                <Actions>
                                                    <MoreVertical size={20}/>
                                                </Actions>
                                            </MetaContainer>
                                        </VideoInfo>
                                    </VideoCard>
                                ))
                            ) : (
                                <p>{t("channel.noVideo")}</p>
                            )}
                        </Grid>
                    </GridContainer>

                    {paginationCursor && (
                        <CenteredContainer>
                            <LoadMoreButton onClick={() => fetchVideos(channelUser.id, true)}>
                <span>
                  {loadingMoreVideos && <ButtonSpinner size={24}/>}
                    {loadingMoreVideos ? t("loading") : t("loadMore")}
                </span>
                            </LoadMoreButton>
                        </CenteredContainer>
                    )}
                </>
            )}
        </>
    );
};

const HeaderContainer = styled.div`
    display: flex;
    gap: 20px;
    padding: 20px;
    margin-bottom: 20px;
`;

const ImageContainer = styled.div`
    flex: 0 0 auto; /* basis auto*/
`;

const UserInfo = styled.div`
    flex: 1;
`;

const UserName = styled.h2`
    margin: 0;
    font-size: 1.8em;
    color: ${({theme}) => theme.color};
`;

const UserDescription = styled.p`
    margin: 5px 0 0; /* Ajoute un espace entre le nom et la description */
    color: ${({theme}) => theme.color};
`;

const GridContainer = styled.div`
    max-width: 1600px;
    margin: 0 auto;
    padding: 24px;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1400px) {
        grid-template-columns: repeat(4, 1fr);
    }
`;

const VideoCard = styled.div`
    cursor: pointer;

    &:hover {
        .thumbnail img {
            transform: scale(1.05);
        }
    }
`;

const ThumbnailContainer = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 12px;
`;

const Thumbnail = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.2s ease;
    }
`;

const Duration = styled.div`
    position: absolute;
    bottom: 8px;
    right: 8px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 3px 4px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
`;

const ViewCount = styled.div`
    position: absolute;
    bottom: 8px;
    left: 8px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 3px 8px;
    border-radius: 4px;
    font-size: 12px;
`;

const UploadTime = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 3px 8px;
    border-radius: 4px;
    font-size: 12px;
`;

const VideoInfo = styled.div`
    display: flex;
    gap: 12px;
    padding: 0 4px;
    flex: 1;
`;

const ChannelAvatar = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const TextContent = styled.div`
    flex: 1;
`;

const Title = styled.h3`
    font-size: 16px;
    font-weight: 500;
    color: ${({theme}) => theme.color};
    margin: 0 0 4px 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.4;
`;

const ChannelName = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    color: #606060;
    font-size: 14px;
    margin-bottom: 2px;

    svg {
        width: 14px;
        height: 14px;
    }
`;

const Actions = styled.button`
    color: #606060;
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    transition: opacity 0.2s;

    ${VideoCard}:hover & {
        opacity: 1;
    }
`;

const MetaContainer = styled.div`
    position: relative;
    padding-right: 32px;
`;

export default ChannelPage;
