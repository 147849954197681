import styled from "styled-components";
import {formatViewers} from "../utils/displayUtils";
import {Link} from "react-router-dom";
import {Users} from "lucide-react";
import {Tag, TagsContainer} from "./StyledControls";
import {useTranslation} from "react-i18next";

const LiveStreamsGrid = ({streams}) => {
    const {t} = useTranslation();
    return (
        <GridContainer>
            <GridWrapper>
                {streams.length > 0 ? (
                    streams.map((stream, i) => (
                        <Card key={i}>
                            <Link to={`/watch?channel=${stream.user_login}`}>
                                <ThumbnailContainer>
                                    <Thumbnail
                                        src={stream.thumbnail_url
                                            .replace("{width}", "320")
                                            .replace("{height}", "180")}
                                        alt={stream.title}
                                    />
                                    <LiveBadge>LIVE</LiveBadge>
                                    <ViewerCount>
                                        <Users size={14}/>
                                        <span>
                      {" "}
                                            {formatViewers(stream.viewer_count)} viewers
                    </span>
                                    </ViewerCount>
                                </ThumbnailContainer>
                            </Link>

                            <StreamInfo>
                                <StreamerInfo>
                                    <Avatar
                                        src={stream.user_info.profile_image_url}
                                        alt={stream.user_name}
                                    />
                                    <TextInfo>
                                        <Title to={`/watch?channel=${stream.user_login}`}>
                                            {stream.title}
                                        </Title>
                                        <div>
                                            <StreamerName to={`/channel/${stream.user_info.login}`}>
                                                {stream.user_info.display_name}
                                            </StreamerName>
                                        </div>
                                    </TextInfo>
                                </StreamerInfo>

                                <TagsContainer>
                                    {stream &&
                                        stream.tags &&
                                        Array.isArray(stream.tags) &&
                                        stream.tags
                                            .slice(0, 3)
                                            .map((tag, index) => <Tag key={index}>{tag}</Tag>)}
                                </TagsContainer>
                            </StreamInfo>
                        </Card>
                    ))
                ) : (
                    <p>{t("noStream")}</p>
                )}
            </GridWrapper>
        </GridContainer>
    );
};


const GridContainer = styled.div`
    min-height: 100vh;
    padding: 1.5rem;
`;

const GridWrapper = styled.div`
    max-width: 2000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1024px) {
        grid-template-columns: repeat(5, 1fr);
    }
`;

const Card = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
    background-color: ${({theme}) => theme.cardBackground};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: transform 0.2s, box-shadow 0.2s;

    &:hover {
        transform: scale(1.02);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
    }
`;

const ThumbnailContainer = styled.div`
    position: relative;
    aspect-ratio: 16/9;
    width: 100%;
    background-color: ${({theme}) => theme.cardBackground};
    overflow: hidden;
`;

const Thumbnail = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const LiveBadge = styled.div`
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    background-color: rgba(255, 0, 0, 0.8); /* Fond rouge avec opacité */
    color: white;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
`;

const ViewerCount = styled.div`
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;

const StreamInfo = styled.div`
    padding: 0.75rem;
    color: ${({theme}) => theme.color};
`;

const StreamerInfo = styled.div`
    display: flex;
    gap: 0.75rem;
`;

const Avatar = styled.img`
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    background-color: ${({theme}) => theme.cardBackground};
    flex-shrink: 0;
`;

const TextInfo = styled.div`
    flex: 1;
    min-width: 0;
`;

const Title = styled(Link)`
    color: ${(props) => props.theme.color};
    text-decoration: none;
    font-weight: 600;
    font-size: 0.875rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%; /* Limite la largeur du lien */
    display: inline-block; /* Nécessaire pour que text-overflow fonctionne */
`;

const StreamerName = styled(Link)`
    color: #a1a1aa;
    text-decoration: none;
    font-size: 0.875rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%; /* Limite la largeur du lien */
    display: inline-block; /* Nécessaire pour que text-overflow fonctionne */
`;

export default LiveStreamsGrid;
