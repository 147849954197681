import { createSlice } from "@reduxjs/toolkit";
import Logger from "../services/Logger";

const getIsChatEnabled = () => {
  const savedIsChatEnabled = localStorage.getItem("isChatEnabled");
  if (savedIsChatEnabled) {
    return savedIsChatEnabled === "true";
  }
  return true;
};

const initialState = {
  // infos
  oldVideo: null,
  oldChannel: null,
  video: null,
  channel: null,
  playlist: null,
  playlistIndex: 0,
  isMiniPlayer: false,
  //
  loopState: "NORMAL",
  videoQuality: localStorage.getItem("videoQuality") || "auto",
  isChatEnabled: getIsChatEnabled(),
};

const PlayerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    // shortcut pour definir toutes les infos au chargement de la page watch
    setInfos(state, action) {
      // previous
      state.oldChannel = state.channel;
      state.oldVideo = state.video;
      // video id
      state.video = action.payload.video;
      // channel
      state.channel = action.payload.channel;
      // playlist
      state.playlist = action.payload.playlist;
      state.playlistIndex = action.payload.playlistIndex;
      // mini player false par default quand on navigue vers watch page
      state.isMiniPlayer = action.payload.isMiniPlayer;

      //Logger.log(`PlayerSlice - setInfos - state ${JSON.stringify(state)}`);
    },
    setPlaylistIndex(state, action) {
      state.playlistIndex = action.payload;
      //Logger.log(`PlayerSlice - setPlaylistIndex - index ${action.payload}`);
    },
    setIsMiniPlayer(state, action) {
      state.isMiniPlayer = action.payload;
      //Logger.log(`PlayerSlice - setIsMiniPlayer - ${action.payload}`);
    },
    toggleLoopState(state) {
      if (state.loopState === "NORMAL") state.loopState = "LOOP_PLAYLIST";
      else if (state.loopState === "LOOP_PLAYLIST")
        state.loopState = "LOOP_VIDEO";
      else state.loopState = "NORMAL";
      sessionStorage.setItem("loop_state", state.loopState);
      //Logger.log(`PlayerSlice - toggleLoopState - ${state.loopState}`);
    },
    setVideoQuality(state, action) {
      state.videoQuality = action.payload;
      localStorage.setItem("videoQuality", action.payload); // Sauvegarde dans localStorage
      //Logger.log(`PlayerSlice - setVideoQuality - ${action.payload}`);
    },
    setChatVisibility(state, action) {
      state.isChatEnabled = action.payload; // Met à jour la visibilité du chat
      localStorage.setItem("isChatEnabled", action.payload); // Sauvegarde dans localStorage
      //Logger.log(`PlayerSlice - setChatVisiblity - ${action.payload}`);
    },
  },
});

export const {
  setInfos,
  setPlaylistIndex,
  setIsMiniPlayer,
  toggleLoopState,
  setVideoQuality,
  setChatVisibility,
} = PlayerSlice.actions;

export default PlayerSlice.reducer;
