import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0); // Remet le défilement en haut
    }, [location]); // Ce hook est exécuté chaque fois que la location change

    return null;
};

export default ScrollToTop;
