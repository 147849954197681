import {useEffect, useState} from "react";
import styled from "styled-components";
import twitchAPIService from "../services/Twitch/TwitchAPIService";
import gameSuggestionService from "../services/GameSuggestionService";
import logger from "../services/Logger";
import LoadingSpinner from "../components/LoadingSpinner";
import {
    ButtonSpinner,
    CenteredContainer,
    LoadMoreButton,
    SuggestionItem,
    SuggestionsList
} from "../components/StyledControls";
import {useNavigate} from "react-router-dom";
import {formatViewers} from "../utils/displayUtils";
import {FaSearch} from "react-icons/fa";
import {useTranslation} from "react-i18next";

const BrowsePage = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [loadingGames, setLoadingGames] = useState(true);
    const [loadingMoreGames, setLoadingMoreGames] = useState(false);
    const [games, setGames] = useState([]);
    const [paginationCursor, setPaginationCursor] = useState(null);

    const [suggestions, setSuggestions] = useState([]);
    const [suggestionIndex, setSuggestionIndex] = useState(-1);

    const [searchInput, setSearchInput] = useState("");

    const fetchGames = async (isLoadMore = false) => {
        if (isLoadMore) setLoadingMoreGames(true);
        else {
            setLoadingGames(true);
            setGames([]);
        }
        try {
            const gamesData = await twitchAPIService.GameService.getTopGamesWithInfos(
                paginationCursor
            );
            setGames((prev) => [...prev, ...gamesData.data]);
            setPaginationCursor(gamesData.paginationCursor || null);
        } catch (error) {
            logger.error(`BrowsePage - fetchGames - error ${error.message}`);
        } finally {
            setLoadingGames(false);
            setLoadingMoreGames(false);
        }
    };

    useEffect(() => {
        fetchGames();
    }, []);

    // Suggestions management

    const handleChangeSearchInput = async (e) => {
        const value = e.target.value;
        setSearchInput(value);

        if (value !== "") {
            const filteredSuggestions =
                await gameSuggestionService.filterSuggestions(value);
            setSuggestions(filteredSuggestions);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "ArrowDown") {
            setSuggestionIndex((prevIndex) =>
                Math.min(prevIndex + 1, suggestions.length - 1)
            );
        } else if (event.key === "ArrowUp") {
            setSuggestionIndex((prevIndex) => Math.max(prevIndex - 1, -1));
        } else if (event.key === "Enter" && suggestionIndex >= 0) {
            setSearchInput(suggestions[suggestionIndex]);
            setSuggestionIndex(-1);
            setSuggestions([]);
        }
    };

    const handleSelectSuggestion = (suggestion) => {
        setSearchInput(suggestion);
        setSuggestions([]);
    };

    const handleSubmitNavigateToGamePage = async (event) => {
        event.preventDefault();

        if (searchInput === "") return;

        try {
            const game = await twitchAPIService.GameService.getGameByName(
                searchInput.toLowerCase()
            );
            if (game) handleNavigateToGamePage(game);
        } catch (error) {
            logger.error(`BrowsePage - handleSearch - error ${error.message}`);
        }
    };

    const handleNavigateToGamePage = (game) =>
        navigate(`/game/${game.id}`);

    if (loadingGames) return <LoadingSpinner/>;

    return (
        <Container>
            <h1>{t("browse.title")}</h1>
            <SearchContainer style={{position: "relative"}}>
                <Icon/>
                <form onSubmit={async (e) => handleSubmitNavigateToGamePage(e)}>
                    <SearchInput
                        type="text"
                        placeholder={t("browse.placeholder")}
                        value={searchInput}
                        onChange={async (e) => handleChangeSearchInput(e)}
                        onKeyDown={handleKeyDown}
                    />
                </form>
                {searchInput && suggestions.length > 0 && (
                    <SuggestionsList>
                        {suggestions.map((suggestion, index) => (
                            <SuggestionItem
                                key={index}
                                onClick={() => handleSelectSuggestion(suggestion)}
                                isSelected={index === suggestionIndex}
                            >
                                {suggestion}
                            </SuggestionItem>
                        ))}
                    </SuggestionsList>
                )}
            </SearchContainer>
            <GridContainer>
                <Grid>
                    {games.length > 0 ? (
                        games.map((game, index) => (
                            <CategoryCard key={index} onClick={() => handleNavigateToGamePage(game)}>
                                <ImageContainer>
                                    <CategoryImage
                                        src={game.box_art_url
                                            .replace("{width}", "150")
                                            .replace("{height}", "200")}
                                        alt={game.name}
                                    />
                                </ImageContainer>
                                <Title>{game.name}</Title>
                                <ViewerCount>
                                    {formatViewers(game.viewer_count)} viewers
                                </ViewerCount>
                            </CategoryCard>
                        ))
                    ) : (
                        <p>{t("browse.noGame")}</p>
                    )}
                </Grid>
            </GridContainer>

            {paginationCursor && (
                <CenteredContainer>
                    <LoadMoreButton onClick={async () => await fetchGames(true)}>
                        <span>
                            {loadingMoreGames && <ButtonSpinner size={24}/>}
                            {loadingMoreGames ? t("loading") : t("loadMore")}
                        </span>
                    </LoadMoreButton>
                </CenteredContainer>
            )}
        </Container>
    );
};

const Container = styled.div`
    background-color: ${({theme}) => theme.background};
    color: ${({theme}) => theme.color};
    padding: 20px;
    min-height: 100vh;

    h1 {
        font-size: 3rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
        color: ${({theme}) => theme.color};
    }
`;

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    max-width: 400px;
    margin-bottom: 2rem;
`;

const SearchInput = styled.input`
    width: 100%;
    padding: 10px 16px 10px 40px;
    border: 2px solid transparent;
    border-radius: 8px;
    background: ${props => props.theme.inputBackground || 'rgba(255, 255, 255, 0.15)'};
    color: ${props => props.theme.color};
    font-size: 0.9rem;
    transition: all 0.2s ease;

    &::placeholder {
        color: ${props => props.theme.placeholderColor || 'rgba(255, 255, 255, 0.5)'};
    }

    &:focus {
        outline: none;
        border-color: ${props => props.theme.primary || '#9147ff'};
        box-shadow: 0 0 0 1px ${props => props.theme.primary || '#9147ff'};
        background: ${props => props.theme.inputFocusBackground || 'rgba(255, 255, 255, 0.2)'};
    }
`;

const Icon = styled(FaSearch)`
    position: absolute;
    left: 12px;
    color: ${props => props.theme.iconColor || 'rgba(255, 255, 255, 0.6)'};
    font-size: 16px;
    pointer-events: none;
    transition: color 0.2s ease;

    ${SearchInput}:focus + & {
        color: ${props => props.theme.primary || '#9147ff'};
    }
`;

const GridContainer = styled.div`
    max-width: 2000px;
    margin: 0 auto;
    padding: 0 20px;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 24px;

    @media (max-width: 1200px) {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        gap: 20px;
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        gap: 16px;
    }
`;

const Title = styled.h3`
    color: ${({theme}) => theme.color};
    font-size: 0.95rem;
    margin: 0;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: color 0.2s ease;
`;


const CategoryCard = styled.div`
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    padding: 8px;
    border-radius: 10px;
    background: transparent;

    &:hover {
        transform: translateY(-4px);
        background: ${({theme}) => theme.cardHoverBackground || 'rgba(255, 255, 255, 0.05)'};

        ${Title} {
            color: ${({theme}) => theme.primary || '#9147ff'};
        }
    }
`;

const ImageContainer = styled.div`
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease;

    &:before {
        content: '';
        display: block;
        padding-top: 133.33%; // 3:4 aspect ratio
    }

    &:hover {
        box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
    }
`;

const CategoryImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;

    ${CategoryCard}:hover & {
        transform: scale(1.05);
    }
`;


const ViewerCount = styled.p`
    color: ${({theme}) => theme.textSecondary || '#adadb8'};
    font-size: 0.85rem;
    margin: 4px 0 0 0;
    font-weight: 400;
    transition: color 0.2s ease;

    ${CategoryCard}:hover & {
        color: ${({theme}) => theme.textSecondaryHover || '#dedee3'};
    }
`;


export default BrowsePage;
