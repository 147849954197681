import { useState, useEffect } from 'react';
import styled from 'styled-components';
import {ArrowUpCircle, ChevronUp} from "lucide-react";

const BackToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Fonction pour scroller en haut de la page
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    // Gérer la visibilité du bouton en fonction de la position de scroll
    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        isVisible && (
            <ScrollToTopButton onClick={scrollToTop}>
                <ChevronUp size={24} />
            </ScrollToTopButton>
        )
    );
};

export default BackToTopButton;

const ScrollToTopButton = styled.button`
    z-index: 2000;
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    background-color: ${({ theme }) => theme.primary};
    color: white;
    border: none;
    font-weight: bold;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: opacity 0.3s ease, transform 0.3s ease;

    &:hover {
        opacity: 0.8;
        transform: translateY(-2px);
    }

    @media (max-width: 768px) {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 20px;
    }
`;
