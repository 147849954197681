import styled, {keyframes} from "styled-components";
import {Link} from "react-router-dom";

export const FlexColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const ButtonSpinner = styled.div`
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #ffffff;
    animation: ${spin} 0.6s linear infinite;
    display: inline-block;
`;


// CONTAINERS

export const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${({theme}) => theme.background};
    color: ${({theme}) => theme.color};
    min-height: 100vh;
    padding: 20px;

    h1 {
        font-size: 4rem;
        margin: 0;
    }

    p {
        font-size: 1.5rem;
    }

    a {
        margin-top: 20px;
        padding: 10px 20px;
        background-color: ${({theme}) => theme.primary};
        color: ${({theme}) => theme.linkText};
        text-decoration: none;
        border-radius: 5px;

        &:hover {
            background-color: ${({theme}) => theme.highlightColor};
        }
    }
`;

export const BigLogo = styled.img`
    width: 350px;
    margin-bottom: 50px;
`;

// -- Dialog --

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Pour s'assurer que l'overlay est au-dessus des autres éléments */
`;

export const DialogBox = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${(props) => props.theme.cardBackground};
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 80%; /* Utilise 80% de la largeur de l'écran pour les mobiles */
    max-width: 400px; /* Limite la largeur maximale à 400px */
    text-align: left;

    h2 {
        margin-bottom: 1rem;
        font-size: 1.5rem;
        color: ${(props) => props.theme.text};
    }

    input {
        width: calc(100% - 20px);
        padding: 10px;
        margin-bottom: 1rem;
        border: 1px solid ${(props) => props.theme.border};
        border-radius: 4px;
        font-size: 1rem;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: border-color 0.3s;

        &:focus {
            outline: none;
            border-color: rgba(145, 70, 255, 0.8);
            box-shadow: 0 0 0 2px rgba(145, 70, 255, 0.5);
        }
    }

    @media (max-width: 600px) {
        padding: 15px; /* Réduit légèrement le padding pour les petits écrans */
    }
`;

export const DialogButton = styled.button`
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #ddd; // Changez cette couleur selon votre thème
    }
`;

// -- Tags --

export const TagsContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-top: 0.5rem;
`;

export const Tag = styled.span`
    background-color: ${({theme}) => theme.tagBackground};
    color: ${({theme}) => theme.tagText};
    font-size: 0.75rem;
    font-weight: 600;
    padding: 0.25rem 0.5rem;
    border-radius: 999px;
`;

// BUTTONS

export const LoadMoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #9147ff;
  color: white;
  font-weight: 600;
  font-size: 0.9375rem;
  font-family: Inter, system-ui, -apple-system, sans-serif;
  padding: 0.625rem 1rem;
  border-radius: 0.375rem;
  border: 2px solid transparent;
  cursor: pointer;
  width: 100%;
  max-width: 20rem;
  margin: 1.5rem auto;
  position: relative;
  transition: all 0.15s ease;
  min-height: 2.75rem;
  letter-spacing: 0.0125em;
  text-transform: capitalize;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  /* Effet de brillance sur le bouton */
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 200% 100%;
  background-position: 100% 0;

  &:hover {
    background-color: #772ce8;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-position: 0 0;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px rgba(145, 71, 255, 0.5);
  }

  &:active {
    background-color: #5c16c5;
    transform: translateY(1px);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    background-color: #9147ff80;
    cursor: not-allowed;
    transform: none;
    opacity: 0.7;
    box-shadow: none;

    &:hover {
      background-color: #9147ff80;
      transform: none;
    }
  }

  /* Conteneur pour le texte et le spinner */
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: opacity 0.2s ease;
    
    /* Animation pour le spinner */
    &:has(svg) {
      opacity: 0.9;
    }
  }

  /* Animation de pulse quand en chargement */
  &:has(span:has(svg)) {
    animation: pulse 2s infinite;
    
    @keyframes pulse {
      0% {
        box-shadow: 0 0 0 0 rgba(145, 71, 255, 0.4);
      }
      70% {
        box-shadow: 0 0 0 6px rgba(145, 71, 255, 0);
      }
      100% {
        box-shadow: 0 0 0 0 rgba(145, 71, 255, 0);
      }
    }
  }

  /* Animation de transition au survol */
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.1) 100%
    );
    opacity: 0;
    transition: opacity 0.2s ease;
    border-radius: 0.375rem;
  }

  &:hover::after {
    opacity: 1;
  }
`;


// Suggestions List

export const SuggestionsList = styled.ul`
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: ${(props) => props.theme.background};
    border: 1px solid ${(props) => props.theme.primary};
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    list-style: none;
    padding: 0;

    // Ajoutez un peu d'ombre pour améliorer l'apparence
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
`;

export const SuggestionItem = styled.li.withConfig({
    shouldForwardProp: (prop) => prop !== "isSelected"
})`
    padding: 10px;
    cursor: pointer;
    color: ${(props) => props.theme.color};

    // Changer la couleur de fond au survol

    &:hover {
        background: ${(props) =>
                props.theme.secondary}; // Couleur secondaire de Twitch
        color: white; // Changer la couleur du texte au survol pour contraster
    }

    // Appliquer un style différent lorsque l'élément est sélectionné
    ${(props) =>
            props.isSelected &&
            `
    background: ${props.theme.primary}; // Couleur principale de Twitch
    color: white; // Changer la couleur du texte
  `}
`;

// ?
//
// export const TitleLink = styled(Link)`
//     color: ${(props) => props.theme.primary};
//     text-decoration: none;
//     font-weight: bold;
//     font-size: 1.2rem;
//     transition: color 0.2s ease;
//
//     white-space: nowrap; /* Empêche le retour à la ligne */
//     overflow: hidden; /* Masque le texte qui déborde */
//     text-overflow: ellipsis; /* Affiche des points de suspension */
//
//     max-width: 150px;
//     display: inline-block; /* Nécessaire pour que text-overflow fonctionne */
//
//     &:hover {
//         color: #fff; /* Couleur blanche au survol */
//         background-color: rgba(
//                 145,
//                 70,
//                 255,
//                 0.1
//         ); /* Légère couleur de fond au survol */
//         border-radius: 4px; /* Coins arrondis */
//         /*  padding: 0.2rem 0.4rem; Espacement pour le fond */
//     }
// `;


export const TitleLink = styled(Link)`
    position: relative;
    z-index: 1000;
    pointer-events: auto;

    color: ${props => props.theme.color};
    text-decoration: none;
    font-weight: 600;
    font-size: 1.1rem;
    transition: all 0.2s ease;
    max-width: 200px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        color: ${props => props.theme.primary};
    }
`;

export const Title = styled.h1`
    color: ${({theme}) => theme.titleColor};
    text-align: center;
    margin-bottom: 20px;
`;

export const TwitchLink = styled.a`
    color: ${({theme}) => theme.color};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export const FavoriteButton = styled.button`
    display: flex;
    align-items: center;
    background-color: ${({theme}) => theme.primary};
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    cursor: pointer;
    transition: box-shadow 0.3s ease, transform 0.3s ease;

    &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        transform: scale(1.05);
    }

    svg {
        margin-right: 8px;
    }

    &:disabled {
        background-color: ${({theme}) => theme.disabledBackground};
        color: ${({theme}) => theme.disabledTextColor};
        cursor: not-allowed;
        box-shadow: none;
        transform: none;
        opacity: 0.6;
    }
`;

export const TabButton = styled.button.withConfig({
    shouldForwardProp: (prop) => prop !== "active",
})`
    margin-right: 10px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 18px;
    font-weight: bold;
    color: ${({active, theme}) => (active ? theme.primary : "inherit")};
    padding: 10px 0; /* Ajoute du padding vertical pour le meilleur alignement */
    position: relative; /* Nécessaire pour le positionnement du liseré */

    &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -5px; /* Ajustez selon l'espacement souhaité */
        height: 3px; /* Hauteur du liseré */
        background-color: ${({active, theme}) =>
                active
                        ? theme.primary
                        : "transparent"}; /* Utilise la couleur primaire du thème pour le liseré */
        transition: background-color 0.3s; /* Transition douce */
    }

    &:hover {
        color: ${({theme}) => theme.highlightColor}; /* Couleur au survol */
    }
`;

// details

export const StreamerImage = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
    align-self: flex-start;
`;

export const ChannelLink = styled(Link)`
    color: ${(props) => props.theme.secondary};
    text-decoration: none; /* Supprimer le soulignement */
    font-weight: bold; /* Gras pour mettre en valeur le nom */
    font-size: 1.2rem; /* Taille de police */
    transition: color 0.2s ease; /* Effet de transition */

    white-space: nowrap; /* Empêche le retour à la ligne */
    overflow: hidden; /* Masque le texte qui déborde */
    text-overflow: ellipsis; /* Affiche des points de suspension */

    max-width: 150px; /* Limite la largeur du lien */
    display: inline-block; /* Nécessaire pour que text-overflow fonctionne */

    &:hover {
        color: ${(props) => props.theme.highlightColor};
    }
`;

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const pulseAnimation = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 32px;
  min-height: 200px;
`;

const SpinnerWrapper = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  animation: ${pulseAnimation} 1.2s ease-in-out infinite;
`;

export const Spinner = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  animation: ${rotateAnimation} 1s linear infinite;

  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0;
    border-radius: 50%;
    border: 3px solid ${({theme}) => theme.highlightColor || '#9147ff'};
    border-top-color: transparent;
    border-right-color: transparent;
  }
`;

const LoadingText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({theme}) => theme.secondaryText || '#adadb8'};
  animation: ${pulseAnimation} 1.2s ease-in-out infinite;
`;

export const LoadingIndicator = () => (
    <LoadingContainer>
        <SpinnerWrapper>
            <Spinner />
        </SpinnerWrapper>
        <LoadingText>Chargement du contenu...</LoadingText>
    </LoadingContainer>
);

// Utilisation du nouveau composant de chargement avec le conteneur existant
export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
`;


