import {useState, useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import logger from "../services/Logger";
import userSuggestionService from "../services/UserSuggestionService";
import twitchAPIService from "../services/Twitch/TwitchAPIService";
import cacheService from "../services/CacheService";
import useStreamers from "../hooks/useStreamers";
import styled from "styled-components";
import {
    DialogBox, DialogButton,
    Overlay,
    SuggestionItem,
    SuggestionsList
} from "../components/StyledControls";
import {
    LayoutGrid,
    LayoutPanelTop,
    Maximize2,
    Minimize2,
    Share2,
} from "lucide-react";
import {Tooltip} from "react-tooltip";
import {FaPlus, FaTimes} from "react-icons/fa";
import {useScreen} from "../contexts/ScreenContext";
import {useTranslation} from "react-i18next";

const loadTwitchScript = () => {
    return new Promise((resolve, reject) => {
        if (document.getElementById("twitch-embed-script")) {
            resolve(window.Twitch);
            return;
        }

        const script = document.createElement("script");
        script.src = "https://embed.twitch.tv/embed/v1.js";
        script.id = "twitch-embed-script";
        script.async = true;
        script.onload = () => {
            if (window.Twitch) resolve(window.Twitch);
            else reject("Erreur : window.Twitch n'est pas défini.");
        };
        script.onerror = (error) => {
            reject("Erreur lors du chargement du script Twitch Embed: ", error);
        };
        document.body.appendChild(script);
    });
};

const TwitchStream = ({
                          stream,
                          isMainStream,
                          isChatEnabled,
                          isMobile,
                          videoQuality,
                      }) => {
    const playerRef = useRef(null);

    const handleReady = () => {
        playerRef.current.getPlayer().setMuted(false);
        if (videoQuality !== "auto") {
            playerRef.current.getPlayer().setQuality(videoQuality);
        }
    };

    const createPlayer = () => {
        const options = {
            width: "100%",
            height: "100%",
            channel: stream,
            parent: [window.location.hostname],
            layout:
                isMainStream && isChatEnabled && !isMobile
                    ? "video_with_chat"
                    : "video",
        };

        playerRef.current = new window.Twitch.Embed(
            `twitch-embed-${stream}`,
            options
        );

        playerRef.current.addEventListener(window.Twitch.Player.READY, handleReady);
    };

    useEffect(() => {
        createPlayer();
        return () => {
            if (playerRef.current) playerRef.current.destroy();
        };
    }, [stream, isMainStream, isMobile]);

    return <div id={`twitch-embed-${stream}`}/>;
};

const MultiStreamPage = ({user}) => {
    const {t} = useTranslation();
    const inputRef = useRef(null);
    const { isMobile, isLargeScreen } = useScreen();

    const {videoQuality, isChatEnabled} = useSelector((state) => state.player);

    const streamers = useStreamers();

    const [mode, setMode] = useState("grid");
    const [streams, setStreams] = useState(streamers);
    const [selectedStream, setSelectedStream] = useState(null);

    const [loadingFollowedStreams, setLoadingFollowedStreams] = useState(false);
    const [followedStreams, setFollowedStreams] = useState([]);

    const [suggestions, setSuggestions] = useState([]);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);

    const [isTwitchScriptLoaded, setIsTwitchScriptLoaded] = useState(false);

    const [showAddStreamPopup, setShowAddStreamPopup] = useState(false);
    const [newStreamName, setNewStreamName] = useState("");

    const [showShareStreamsPopup, setShowShareStreamsPopup] = useState(false);
    const [shareStreamsUrl, setShareStreamsUrl] = useState("");
    const [isCopied, setIsCopied] = useState(false);

    const [isMinimizedMode, setIsMinimizedMode] = useState(false);

    const fetchFollowedStreams = async (userId) => {
        setLoadingFollowedStreams(true);
        try {
            const cacheKey = `followedStreams`;
            const cachedData = cacheService.get(cacheKey);
            if (cachedData) {
                setFollowedStreams(cachedData);
                return cachedData;
            } else {
                // streams
                const streams = await twitchAPIService.StreamService.getFollowedStreams(
                    userId
                );

                // users (streamers)
                const streamUserIds = streams.map((stream) => stream.user_id);
                const usersInfo = await twitchAPIService.UserService.getUsers(
                    streamUserIds
                );

                // création de stream infos avec user umage et user name
                const streamsWithUserInfo = streams.map((stream) => {
                    const userInfo = usersInfo.find((user) => user.id === stream.user_id);
                    return {
                        ...stream,
                        user_image: userInfo.profile_image_url,
                        user_login: userInfo.login,
                        user_name: userInfo.display_name,
                    };
                });

                cacheService.set(cacheKey, streamsWithUserInfo);

                setFollowedStreams(streamsWithUserInfo);
                return streamsWithUserInfo;
            }
        } catch (error) {
            logger.error(error.message);
        } finally {
            setLoadingFollowedStreams(false);
        }
    };

    const loadScript = async () => {
        try {
            await loadTwitchScript();
            setIsTwitchScriptLoaded(true);
        } catch (error) {
            logger.error(`MultiStreamPage - loadScript- Error : ${error.message}`);
        }
    };

    useEffect(() => {
        loadScript();
    }, []);

    useEffect(() => {
        if (streamers.length > 0) {
            setSelectedStream(streamers[0]);
        }
    }, [streamers]);

    useEffect(() => {
        if (showAddStreamPopup) {
            if (user) fetchFollowedStreams(user.id);
            inputRef.current.focus();
        }
    }, [showAddStreamPopup]);

    const toggleIsMinimizedMode = () => setIsMinimizedMode(!isMinimizedMode);

    const handleSelectStream = (stream) => setSelectedStream(stream);

    const handleNewStreamNameChange = (event) => {
        const value = event.target.value;
        setNewStreamName(value);

        const filteredSuggestions = userSuggestionService.filterSuggestions(value);
        setSuggestions(filteredSuggestions);
    };

    const handleKeyDown = (event) => {
        if (event.key === "ArrowDown") {
            setSelectedSuggestionIndex((prevIndex) =>
                Math.min(prevIndex + 1, suggestions.length - 1)
            );
        } else if (event.key === "ArrowUp") {
            setSelectedSuggestionIndex((prevIndex) => Math.max(prevIndex - 1, -1));
        } else if (event.key === "Enter" && selectedSuggestionIndex >= 0) {
            setNewStreamName(suggestions[selectedSuggestionIndex]);
            setSelectedSuggestionIndex(-1);
            setSuggestions([]);
        }
    };

    const handleSelectSuggestion = (suggestion) => {
        setNewStreamName(suggestion);
        setSuggestions([]);
    };

    const handleAddStream = (event) => {
        event.preventDefault();

        setStreams((prevStreams) => {
            // Vérifier si le stream existe déjà
            const normalizedStreamName = newStreamName.toLowerCase();
            if (prevStreams.includes(normalizedStreamName)) {
                return prevStreams;
            }

            const updatedStreams = [...prevStreams, normalizedStreamName];
            if (prevStreams.length === 0) setSelectedStream(normalizedStreamName);
            userSuggestionService.addSuggestion(normalizedStreamName);
            return updatedStreams;
        });

        setShowAddStreamPopup(false);
        setNewStreamName("");
        setSuggestions([]);
    };

    const handleSelectFollowedStream = (normalizedStreamName) => {
        setStreams((prevStreams) => {
            if (prevStreams.includes(normalizedStreamName)) {
                return prevStreams;
            }

            const updatedStreams = [...prevStreams, normalizedStreamName];
            if (prevStreams.length === 0) setSelectedStream(normalizedStreamName);
            return updatedStreams;
        });

        setShowAddStreamPopup(false);
        setNewStreamName("");
        setSuggestions([]);
    };

    const handleDeleteStream = (event, stream) => {
        // Empêcher l'événement de propagation pour éviter de déclencher la sélection du stream
        event.stopPropagation();

        setStreams((prevStreams) => {
            // Filtrer les streams
            const updatedStreams = prevStreams.filter((s) => s !== stream);

            if (selectedStream === stream) {
                if (prevStreams.length > 0) setSelectedStream(updatedStreams[0]);
            }

            return updatedStreams;
        });
    };

    const handleClearStreams = (event) => {
        event.preventDefault();

        setStreams([]);
        setSelectedStream(null);
    };

    if (!isTwitchScriptLoaded) return <div>Chargement...</div>;

    const handleShareStreams = () => {
        const url = `https://twitch-playlist.site/multistream?stream=${streams.join(
            "&stream="
        )}`;
        setShareStreamsUrl(url);
        setShowShareStreamsPopup(true);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(shareStreamsUrl).then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000); // Réinitialiser le message après 2 secondes
        });
    };

    return (
        <>
            <Tooltip id="addStreamTooltip" place="right" style={{zIndex: 1500}}>
                {t("multi.tooltip1")}
            </Tooltip>
            <Tooltip id="deleteStreamTooltip" place="right" style={{zIndex: 1500}}>
                {t("multi.tooltip2")}
            </Tooltip>
            <Tooltip id="shareStreamsTooltip" place="right" style={{zIndex: 1500}}>
                {t("multi.tooltip3")}
            </Tooltip>
            <Tooltip id="deleteStreamsTooltip" place="right" style={{zIndex: 1500}}>
                {t("multi.tooltip4")}
            </Tooltip>
            <Tooltip id="smallTooltip">
                {isMinimizedMode ?  t("multi.tooltip5") : t("multi.tooltip6")}
            </Tooltip>
            <Tooltip id="modeTooltip">
                {mode === "grid" ? t("multi.tooltip7") : t("multi.tooltip8")}
            </Tooltip>

            <MultiStreamBar>
                {streams.map((stream) => (
                    <Thumbnail
                        key={stream}
                        selected={stream === selectedStream}
                        onClick={() => handleSelectStream(stream)}
                    >
                        <img
                            src={`https://static-cdn.jtvnw.net/previews-ttv/live_user_${stream}-320x180.jpg`}
                            alt={`Thumbnail de ${stream}`}
                        />
                        <DeleteButton
                            onClick={(e) => handleDeleteStream(e, stream)}
                            data-tooltip-id="deleteStreamTooltip"
                        ></DeleteButton>
                    </Thumbnail>
                ))}

                {streams.length < 6 && (
                    <BarButton
                        onClick={() => setShowAddStreamPopup(true)}
                        data-tooltip-id="addStreamTooltip"
                    >
                        <FaPlus/>
                    </BarButton>
                )}

                <BarButton
                    onClick={handleShareStreams}
                    disabled={streams.length === 0}
                    data-tooltip-id="shareStreamsTooltip"
                >
                    <Share2/>
                </BarButton>

                <BarButton
                    onClick={handleClearStreams}
                    data-tooltip-id="deleteStreamsTooltip"
                    disabled={streams.length === 0}
                >
                    <FaTimes/>
                </BarButton>

                <div style={{marginLeft: "auto", display: "flex", gap: "10px"}}>
                    {isLargeScreen && (
                        <BarButton
                            onClick={toggleIsMinimizedMode}
                            data-tooltip-id="smallTooltip"
                        >
                            {isMinimizedMode ? (
                                <Maximize2 size={24}/>
                            ) : (
                                <Minimize2 size={24}/>
                            )}
                        </BarButton>
                    )}

                    {!isMobile && (
                        <BarButton
                            onClick={() => setMode(mode === "grid" ? "main" : "grid")}
                            data-tooltip-id="modeTooltip"
                        >
                            {mode === "grid" ? <LayoutPanelTop/> : <LayoutGrid/>}
                        </BarButton>
                    )}
                </div>
            </MultiStreamBar>

            {streams.length === 0 ? (
                <NoStreamsWrapper>
                    <NoStreamsMessage>
                        {t("multi.noStream")}
                    </NoStreamsMessage>
                </NoStreamsWrapper>
            ) : mode === "main" ? (
                <StreamLayout isMinimizedMode={isMinimizedMode}>
                    {selectedStream && (
                        <MainPlayerContainer hasChat={isChatEnabled && !isMobile}>
                            <TwitchStream
                                stream={selectedStream}
                                isMobile={isMobile}
                                isMainStream={true}
                                videoQuality={videoQuality}
                                isChatEnabled={isChatEnabled}
                            />
                        </MainPlayerContainer>
                    )}
                    <SecondaryPlayersGrid>
                        {streams
                            .filter((stream) => stream !== selectedStream)
                            .map((stream) => (
                                <PlayerContainer key={stream}>
                                    <TwitchStream
                                        stream={stream}
                                        isMainStream={false}
                                        videoQuality={videoQuality}
                                    />
                                    {!isMobile && (
                                        <MaximizeButton onClick={() => setSelectedStream(stream)}>
                                            <LayoutPanelTop/>
                                        </MaximizeButton>
                                    )}
                                </PlayerContainer>
                            ))}
                    </SecondaryPlayersGrid>
                </StreamLayout>
            ) : (
                <StreamContainer isMinimizedMode={isMinimizedMode}>
                    {streams.map((stream) => (
                        <PlayerContainer key={stream}>
                            <TwitchStream
                                stream={stream}
                                isMainStream={false}
                                videoQuality={videoQuality}
                            />
                            {!isMobile && (
                                <MaximizeButton
                                    onClick={() => {
                                        setSelectedStream(stream);
                                        setMode("main");
                                    }}
                                >
                                    <LayoutPanelTop/>
                                </MaximizeButton>
                            )}
                        </PlayerContainer>
                    ))}
                </StreamContainer>
            )}

            {showAddStreamPopup && (
                <Overlay>
                    <DialogBox>
                        <h2>Ajouter un stream</h2>
                        <form onSubmit={handleAddStream}>
                            <div style={{position: "relative"}}>
                                <input
                                    ref={inputRef}
                                    type="text"
                                    value={newStreamName}
                                    onChange={handleNewStreamNameChange}
                                    onKeyDown={handleKeyDown}
                                    placeholder={t("multi.placeholder")}
                                />
                                {newStreamName && suggestions.length > 0 && (
                                    <SuggestionsList style={{top: "50%"}}>
                                        {suggestions.map((suggestion, index) => (
                                            <SuggestionItem
                                                key={index}
                                                onClick={() => handleSelectSuggestion(suggestion)}
                                                isSelected={index === selectedSuggestionIndex}
                                            >
                                                {suggestion}
                                            </SuggestionItem>
                                        ))}
                                    </SuggestionsList>
                                )}
                            </div>
                            <DialogButton type="submit"> {t("add")}</DialogButton>
                            <DialogButton
                                type="button"
                                onClick={() => setShowAddStreamPopup(false)}
                            >
                                {t("cancel")}
                            </DialogButton>
                        </form>

                        {user && (
                            <>
                                <Title>{t("multi.streamers")}</Title>
                                {loadingFollowedStreams ? (
                                    <p>{t("loading")}</p>
                                ) : followedStreams.length === 0 ? (
                                    <p>{t("multi.noStream2")}</p>
                                ) : (
                                    <ProfileList>
                                        {followedStreams.map((stream, index) => (
                                            <ProfileItem
                                                key={index}
                                                onClick={() =>
                                                    handleSelectFollowedStream(stream.user_login)
                                                }
                                            >
                                                <ProfileImage
                                                    src={stream.user_image}
                                                    alt={`Profile de ${stream.name}`}
                                                />
                                                <LiveBadge>Live</LiveBadge>
                                            </ProfileItem>
                                        ))}
                                    </ProfileList>
                                )}
                            </>
                        )}
                    </DialogBox>
                </Overlay>
            )}

            {showShareStreamsPopup && (
                <Overlay>
                    <DialogBox>
                        <h2>{t("multi.share")}</h2>
                        <div>
                            <input
                                type="text"
                                value={shareStreamsUrl}
                                readOnly
                                style={{
                                    width: "100%",
                                    maxWidth: "400px", // Limite la largeur de l'input
                                    padding: "10px",
                                    fontSize: "1rem",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                    boxSizing: "border-box", // Inclut padding et border dans la largeur totale
                                }}
                            />
                        </div>
                        <DialogButton onClick={handleCopy}>
                            {isCopied ? t("multi.copied") : t("multi.copy")}
                        </DialogButton>
                        <DialogButton
                            type="button"
                            onClick={() => setShowShareStreamsPopup(false)}
                        >
                            {t("close")}
                        </DialogButton>
                        <p>
                            {t("multi.short")}{" "}
                            <StyledLink
                                href="https://bitly.com/pages/products/url-shortener"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Bitly URL Shortener
                            </StyledLink>
                        </p>
                    </DialogBox>
                </Overlay>
            )}
        </>
    );
};

const MultiStreamBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
  width: 100%;
  background: ${({ theme }) => theme.barBackground};
  padding: 10px 16px;
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.barShadow};
  border: 1px solid ${({ theme }) => theme.borderColor};
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  @media (max-width: 768px) {
    padding: 8px;
    gap: 6px;
  }
`;

const Thumbnail = styled.div`
  position: relative;
  min-width: 100px;
  height: 56px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid ${props => props.selected ? props.theme.primaryButton : 'transparent'};
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: ${({ theme }) => theme.thumbnailHoverShadow};
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props => props.selected
    ? `linear-gradient(${props.theme.gradientOverlay.hover}, ${props.theme.gradientOverlay.hover})`
    : `linear-gradient(${props.theme.gradientOverlay.default}, ${props.theme.gradientOverlay.default})`
};
    opacity: ${props => props.selected ? 0.8 : 1};
    transition: opacity 0.2s ease;
  }

  &:hover::after {
    opacity: 0.6;
  }
`;

const BarButton = styled.button`
  min-width: 36px;
  height: 56px;
  border-radius: 4px;
  border: none;
  background: ${props => {
    if (props.disabled) return props.theme.buttonBackgroundDisabled;
    return props.primary ? props.theme.primaryButton : props.theme.buttonBackground;
}};
  color: ${props => {
    if (props.disabled) return props.theme.buttonColorDisabled;
    return props.theme.textColor;
}};
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: all 0.2s ease;
  padding: 0 12px;

  &:hover:not(:disabled) {
    background: ${props => props.primary
    ? props.theme.primaryButtonHover
    : props.theme.buttonBackgroundHover
};
    transform: translateY(-1px);
    box-shadow: ${({ theme }) => theme.thumbnailHoverShadow};
  }

  &:active:not(:disabled) {
    transform: translateY(0);
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${props => `${props.theme.primaryButton}50`};
  }

  svg {
    width: 20px;
    height: 20px;
    transition: transform 0.2s ease;
    color: ${props => props.disabled
    ? props.theme.buttonColorDisabled
    : props.primary
        ? '#ffffff'
        : props.theme.textColor
};
  }

  &:hover:not(:disabled) svg {
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    min-width: 32px;
    height: 48px;
    padding: 0 8px;
    
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;
//

const MaximizeButton = styled.button`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(
            -50%,
            -50%
    ); /* Centre horizontalement et verticalement */
    background-color: transparent;
    color: white;
    border: none;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    opacity: 0;

    &:hover {
        background-color: rgba(100, 65, 165, 0.8); /* Fond violet Twitch */
        transform: translate(-50%, -50%) scale(1.1); /* Centre et agrandissement */
    }

    svg {
        width: 30px;
        height: 30px;
    }
`;


const DeleteButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 50%; /* Cercle parfait */
  width: 30px; /* Largeur du bouton */
  height: 30px; /* Hauteur du bouton */
  padding: 0; /* Retirer padding pour garder la forme circulaire */
  display: none; /* Masquer par défaut */
  z-index: 1000;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  text-align: center; /* Centrer le texte à l'intérieur */
  line-height: 30px; /* Aligner verticalement le "X" au centre */

  /* Ajouter un "X" centré */
  &::before {
    content: "×"; /* Afficher le caractère X */
    font-size: 1.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centrer parfaitement le "X" */
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
    transform: scale(1.1); /* Agrandir légèrement le bouton au survol */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4); /* Ajouter une ombre pour effet de profondeur */
  }

  // Afficher le bouton de suppression lorsque l'élément est survolé
  ${Thumbnail}:hover & {
    display: block;
  }

  // Ajouter un petit effet lorsque le bouton est cliqué
  &:active {
    transform: scale(
      0.95
    ); /* Réduire légèrement lors du clic pour effet visuel */
  }
`;

// Grid

const StreamContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => prop !== "isMinimizedMode",
})`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    width: 100%;
    height: 100%;
    justify-items: center; /* Centre horizontalement les éléments */
    align-items: center; /* Centre verticalement les éléments */
    max-width: ${(props) => (props.isMinimizedMode ? "1200px" : "1920px")};
    margin: 0 auto; /* Centrer le conteneur lui-même si nécessaire */

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const PlayerContainer = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; // 16:9 aspect ratio
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }

    &:hover {
        transform: scale(1.02);
        transition: transform 0.2s ease;
    }

    &:hover ${MaximizeButton} {
        opacity: 1; /* Affiche le bouton Maximize */
    }
`;

// Main
const StreamLayout = styled.div.withConfig({
    shouldForwardProp: (prop) => prop !== "isMinimizedMode",
})`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: ${(props) => (props.isMinimizedMode ? "1200px" : "1920px")};
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
`;

const MainPlayerContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => prop !== "hasChat",
})`
    position: relative;
    width: 100%;
    height: 0;
    /* Avec un chat de 340px et un écran de 1920px, le ratio serait : */
    /* (1920-340) * 9/16 / 1920 * 100 = 46.25% */
    padding-bottom: ${
            (props) =>
                    props.hasChat
                            ? "calc((100% - 340px) * 9 / 16)" // Avec chat
                            : "56.25%" // Sans chat (ratio 16:9 standard)
    };

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
`;

const SecondaryPlayersGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    width: 100%;
`;


// Profile

const ProfileList = styled.div`
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

const ProfileItem = styled.button`
    text-align: center;
    width: 60px;
    position: relative; /* Pour positionner le badge "Live" */
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    &:focus {
        outline: none; /* Retirer l'effet de focus */
    }

    &:hover {
        transform: scale(1.1);
    }
`;

const ProfileImage = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out; /* Transition fluide pour l'ombre et l'agrandissement */

    /* Ombre par défaut */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    &:hover {
        transform: scale(
                1.1
        ); /* Agrandissement supplémentaire de l'image au survol */
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Ombre plus marquée et floue pour un effet plus doux */
    }
`;

const LiveBadge = styled.div`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 0, 0, 0.8); /* Fond rouge avec opacité */
    color: white;
    font-size: 0.5rem;
    font-weight: bold;
    padding: 0.25rem 0.3rem;
    border-radius: 0.25rem;
`;

const Title = styled.h3`
    text-align: left;
    font-size: 1.1rem;
    margin-bottom: 1rem; /* Espacement sous le titre */
    color: ${(props) => props.theme.color};
`;

const NoStreamsWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
`;

const NoStreamsMessage = styled.div`
    text-align: center;
    font-size: 1.2rem;
    color: #888;
    margin-top: 2rem;
`;

const StyledLink = styled.a`
    color: ${(props) => props.primary};
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s, text-decoration 0.3s;

    &:hover {
        color: ${(props) => props.highlightColor};
        text-decoration: underline;
    }

    &:focus {
        outline: none;
    }
`;

export default MultiStreamPage;
