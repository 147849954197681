export class TwitchAppTokenService {
    constructor() {
        this.appAccessToken = null;
        this.expiry = null;
    }

    // https://dev.twitch.tv/docs/authentication/getting-tokens-oauth/#client-credentials-grant-flow
    /*
    {
      "access_token": "jostpf5q0uzmxmkba9iyug38kjtgh",
      "expires_in": 5011271,
      "token_type": "bearer"
    }
    */
    async getAppAccessToken() {
        if (!this.appAccessToken || this.isTokenExpired()) {
            const clientId = process.env.REACT_APP_TWITCH_CLIENT_ID;
            const clientSecret = process.env.REACT_APP_TWITCH_CLIENT_SECRET;

            if (!clientId || !clientSecret)
                throw new Error("Missing clientId or clientSecret");

            const url = `https://id.twitch.tv/oauth2/token?client_id=${clientId}&client_secret=${clientSecret}&grant_type=client_credentials`;
            const response = await fetch(url, {
                method: "POST",
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(
                    `AppTokenService  - getAppAccessToken - Error: ${errorData.message || response.statusText}`
                );
            }

            const data = await response.json();
            this.appAccessToken = data.access_token;
            this.expiry = Date.now() + data.expires_in * 1000; // Calcul de l'expiration en ms
        }

        return this.appAccessToken;
    }

    isTokenExpired() {
        return !this.expiry || Date.now() > this.expiry;
    }
};

const appTokenService = new TwitchAppTokenService();
export default appTokenService;
