import cacheService from "./CacheService";
import logger from "./Logger";
import twitchAPIService, {TwitchAPIService} from "./Twitch/TwitchAPIService";

const GameSuggestionService = {
    cacheKey: "topGames",
    isLoading: false, // état de chargement

    preloadTopGames: async () => {
        if (cacheService.get(GameSuggestionService.cacheKey)) {
            return; // Ne charge pas si les jeux sont déjà en cache
        }
        GameSuggestionService.isLoading = true;

        try {
            await GameSuggestionService.getTopGames();
        } finally {
            GameSuggestionService.isLoading = false;
        }
    },

    getTopGames: async () => {
        const cachedGames = cacheService.get(GameSuggestionService.cacheKey);
        if (cachedGames) {
            return cachedGames;
        }

        let allGames = [];
        let paginationCursor = null;

        try {
            do {
                const url = new URL(`${TwitchAPIService.TWITCH_API_URL}/games/top`);
                url.searchParams.append("first", "100");
                if (paginationCursor) {
                    url.searchParams.append("after", paginationCursor);
                }

                const response = await twitchAPIService.fetchWithAppAccessToken(url);
                allGames = allGames.concat(response.data.map((game) => game.name));
                paginationCursor = response.pagination?.cursor || null;

            } while (paginationCursor);

            cacheService.set(GameSuggestionService.cacheKey, allGames, 3600000); // 1h
            return allGames;
        } catch (error) {
            logger.error(
                `TwitchGameSuggestionService - getTopGames - error: ${error.message}`
            );
            throw error;
        }
    },

    normalizeSuggestion(suggestion) {
        return suggestion.toLowerCase().trim();
    },

    filterSuggestions: async (prefix) => {
        if (GameSuggestionService.isLoading) {
            return []; // Placeholder de chargement
        }

        const allGames = await GameSuggestionService.getTopGames();
        const normalizedPrefix =
            GameSuggestionService.normalizeSuggestion(prefix);

        return allGames
            .filter((suggestion) =>
                suggestion.toLowerCase().startsWith(normalizedPrefix)
            )
            .slice(0, 4); // Limite les suggestions à 4
    },
};

export default GameSuggestionService;
