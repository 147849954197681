export class TwitchUserAccessTokenService {
    static ACCESS_TOKEN_KEY = "accessToken";
    static REFRESH_TOKEN_KEY = "refreshToken";
    static EXPIRES_IN_KEY = "expiresIn";
    static RECEIVED_AT_KEY = "receivedAt";
    static RETURN_URL_KEY = "returnUrl";

    store(key, value) {
        if (key === undefined) throw new Error("key required");
        window.localStorage.setItem(key, value);
    }

    getFromStorage(key) {
        if (key === undefined) throw new Error("key required");
        return window.localStorage.getItem(key);
    }

    removeFromStorage(key) {
        if (key === undefined) throw new Error("key required");
        return window.localStorage.removeItem(key);
    }

    navigateTo(url) {
        if (url === undefined) throw new Error("url required");
        window.location.href = url;
    }

    async login(returnUrl = "/") {
        this.store(TwitchUserAccessTokenService.RETURN_URL_KEY, returnUrl);
        const authUrl = await this.getAuthUrl();
        this.navigateTo(authUrl);
    }

    async getAuthUrl() {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/auth/login`
        );
        const data = await response.json();
        return data.url;
    }

    async handleAuthCallback(search) {
        if (search === undefined) throw new Error("search required");

        const params = new URLSearchParams(search);
        const access_token = params.get("access_token");
        const refresh_token = params.get("refresh_token");
        const expires_in = params.get("expires_in");

        if (access_token && refresh_token && expires_in) {
            this.store(TwitchUserAccessTokenService.ACCESS_TOKEN_KEY, access_token);
            this.store(TwitchUserAccessTokenService.REFRESH_TOKEN_KEY, refresh_token);
            this.store(TwitchUserAccessTokenService.EXPIRES_IN_KEY, expires_in);
            const tokenReceivedAt = Date.now();
            this.store(TwitchUserAccessTokenService.RECEIVED_AT_KEY, tokenReceivedAt.toString());

            const returnUrl = this.getFromStorage(TwitchUserAccessTokenService.RETURN_URL_KEY);
            this.navigateTo(returnUrl);
        } else {
            throw new Error(
                "AuthService - handleAuthCallback - Authentication failed: Missing tokens."
            );
        }
    }

    getAccessToken() {
        return this.getFromStorage(TwitchUserAccessTokenService.ACCESS_TOKEN_KEY);
    }

    async getValidAccessToken() {
        if (this.isTokenExpired()) {
            await this.refreshToken();
        }
        return this.getAccessToken();
    }

    isTokenExpired() {
        const expiresIn = this.getFromStorage(TwitchUserAccessTokenService.EXPIRES_IN_KEY);
        const receivedAt = this.getFromStorage(TwitchUserAccessTokenService.RECEIVED_AT_KEY);

        if (expiresIn && receivedAt) {
            const expirationTime = Number(receivedAt) + Number(expiresIn) * 1000;
            return Date.now() > expirationTime;
        }
        return true;
    }

    async refreshToken() {
        const token = this.getFromStorage(TwitchUserAccessTokenService.REFRESH_TOKEN_KEY);
        if (!token)
            throw new Error("refresh_token undefined");

        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/auth/refresh-token`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    refresh_token: token
                })
            }
        );

        if (response.ok) {
            const {access_token, refresh_token, expires_in} = await response.json();
            if (access_token && refresh_token && expires_in) {
                this.store(TwitchUserAccessTokenService.ACCESS_TOKEN_KEY, access_token);
                this.store(TwitchUserAccessTokenService.REFRESH_TOKEN_KEY, refresh_token);
                this.store(TwitchUserAccessTokenService.EXPIRES_IN_KEY, expires_in);
                const tokenReceivedAt = Date.now();
                this.store(TwitchUserAccessTokenService.RECEIVED_AT_KEY, tokenReceivedAt.toString());
            }
        } else {
            throw new Error("AuthService - refreshToken - Failed to refresh token");
        }
    }

    async getUserInfo() {
        const clientId = process.env.REACT_APP_TWITCH_CLIENT_ID;
        if (!clientId)
            throw new Error("clientId undefined");

        const accessToken = this.getAccessToken();
        if (!accessToken)
            throw new Error("accessToken undefined");

        const response = await fetch("https://api.twitch.tv/helix/users", {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Client-ID": clientId
            }
        });

        if (!response.ok) {
            const errorData = await response.json();
            const message = `AuthService - getUserInfo - Error: ${errorData.message || response.statusText}`;
            throw new Error(message);
        }

        const data = await response.json();
        return data.data[0];
    }

    logout() {
        this.removeFromStorage(TwitchUserAccessTokenService.ACCESS_TOKEN_KEY);
        this.removeFromStorage(TwitchUserAccessTokenService.REFRESH_TOKEN_KEY);
        this.removeFromStorage(TwitchUserAccessTokenService.EXPIRES_IN_KEY);
        this.removeFromStorage(TwitchUserAccessTokenService.RECEIVED_AT_KEY);

        this.navigateTo("/");
    }
}

const userAccessTokenService = new TwitchUserAccessTokenService();
export default userAccessTokenService;
