import {useEffect, useRef, useState} from "react";

import Logger from "../services/Logger";
import twitchAPIService from "../services/Twitch/TwitchAPIService";
import playlistService from "../services/Supabase/SupabasePlaylistService";
import favoriteUsersService from "../services/Supabase/SupabaseFavoriteUsersService";
import favoriteGamesService from "../services/Supabase/SupabaseFavoriteGamesService";
import cacheService from "../services/CacheService";
import styled from "styled-components";
import {
    CenteredContainer,
    DialogBox,
    DialogButton,
    LoadingIndicator,
    Overlay, TitleLink
} from "../components/StyledControls";
import {FaPlus, FaTrashAlt, FaUserFriends} from "react-icons/fa";
import {Tooltip} from "react-tooltip";
import {AiFillStar} from "react-icons/ai";
import {GiGamepad} from "react-icons/gi";
import {FaList, FaThLarge} from "react-icons/fa";
import LiveStatusProfile from "../components/LiveStatusProfile";
import {useTranslation} from "react-i18next";
import {ChevronUp} from "lucide-react";
import CollapsibleSection from "../components/CollpasibleSection";

const HomePage = ({user}) => {
    const {t} = useTranslation();

    const newPlaylistNameInputRef = useRef(null);

    const [loadingStreams, setLoadingStreams] = useState(true);
    const [streams, setStreams] = useState([]);

    const [loadingPlaylists, setLoadingPlaylists] = useState(true);
    const [playlists, setPlaylists] = useState([]);

    const [loadingFavoriteUsers, setLoadingFavoriteUsers] = useState(true);
    const [favoriteUsers, setFavoriteUsers] = useState([]);

    const [loadingFavoriteGames, setLoadingFavoriteGames] = useState(true);
    const [favoriteGames, setFavoriteGames] = useState([]);

    const [showCreatePlaylistDialog, setShowCreatePlaylistDialog] = useState(false);
    const [newPlaylistName, setNewPlaylistName] = useState("");

    const [showConfirmDeletePlaylistDialog, setShowConfirmDeletePlaylistDialog] = useState(false);
    const [playlistToDelete, setPlaylistToDelete] = useState(null);

    const [viewMode, setViewMode] = useState("grid");

    const fetchFollowedStreams = async (userId) => {
        setLoadingStreams(true);
        try {
            const cacheKey = `followedStreams`;
            const cachedData = cacheService.get(cacheKey);
            if (cachedData) {
                setStreams(cachedData);
                return cachedData;
            } else {
                // streams
                const streams = await twitchAPIService.StreamService.getFollowedStreams(
                    userId
                );
                if (streams.length > 0) {
                    // users (streamers)
                    const streamUserIds = streams.map((stream) => stream.user_id);
                    const usersInfo = await twitchAPIService.UserService.getUsers(
                        streamUserIds
                    );

                    // création de stream infos avec user umage et user name
                    const streamsWithUserInfo = streams.map((stream) => {
                        const userInfo = usersInfo.find((user) => user.id === stream.user_id);
                        return {
                            ...stream,
                            user_image: userInfo.profile_image_url,
                            user_login: userInfo.login,
                            user_name: userInfo.display_name
                        };
                    });

                    cacheService.set(cacheKey, streamsWithUserInfo);

                    setStreams(streamsWithUserInfo);
                    return streamsWithUserInfo;
                } else {
                    setStreams([]);
                    return [];
                }

            }
        } catch (error) {
            Logger.error(error.message);
        } finally {
            setLoadingStreams(false);
        }
    };

    const fetchPlaylists = async (userId) => {
        setLoadingPlaylists(true);
        try {
            const userPlaylists = await playlistService.getPlaylists(userId);
            setPlaylists(userPlaylists);
            return userPlaylists;
        } catch (error) {
            Logger.error(`HomePage - fetchPlaylists - Error: ${error.message}`);
        } finally {
            setLoadingPlaylists(false);
        }
    };

    const fetchFavoriteUsers = async (userId) => {
        setLoadingFavoriteUsers(true);
        try {
            const cacheKey = "favoriteUsers";
            const cachedData = cacheService.get(cacheKey);
            if (cachedData) {
                setFavoriteUsers(cachedData);
                return cachedData;
            } else {
                const favoriteUserIds = await favoriteUsersService.getAll(userId);

                const allFavoriteUserDetails = [];

                for (let index = 0; index < favoriteUserIds.length; index++) {
                    const favoriteUserId = favoriteUserIds[index];

                    try {
                        // invalid id ?
                        const favoriteUserDetails = await twitchAPIService.UserService.getUserById(
                            favoriteUserId
                        );
                        const isOnline =
                            await twitchAPIService.StreamService.checkIfUserIsLive(
                                favoriteUserId
                            );

                        allFavoriteUserDetails.push({...favoriteUserDetails, isOnline});
                    } catch (error) {
                        Logger.error(
                            `HomePage - fetchFavoriteUsers - Error: ${error.message}`
                        );
                    }
                }

                cacheService.set(cacheKey, allFavoriteUserDetails);
                setFavoriteUsers(allFavoriteUserDetails);
                return allFavoriteUserDetails;
            }
        } catch (error) {
            Logger.error(`HomePage - fetchFavoriteUsers - error ${error.message}`);
        } finally {
            setLoadingFavoriteUsers(false);
        }
    };

    const fetchFavoriteGames = async (userId) => {
        setLoadingFavoriteGames(true);
        try {
            const cacheKey = "favoriteGames";
            const cachedData = cacheService.get(cacheKey);
            if (cachedData) {
                setFavoriteGames(cachedData);
                return cachedData;
            } else {
                const favoriteGameIds = await favoriteGamesService.getAll(userId);
                const gameDetails = await twitchAPIService.GameService.getGames(favoriteGameIds);

                cacheService.set(cacheKey, gameDetails);

                setFavoriteGames(gameDetails);
                return gameDetails;
            }
        } catch (error) {
            Logger.error(`HomePage - fetchFavoriteGames - error ${error.message}`);
        } finally {
            setLoadingFavoriteGames(false);
        }
    };

    const fetchAllData = async (userId) => {
        try {
            await Promise.all([
                fetchFollowedStreams(userId),
                fetchPlaylists(userId),
                fetchFavoriteUsers(userId),
                fetchFavoriteGames(userId)
            ]);
        } catch (error) {
            Logger.error(`HomePage - fetchAllData - error ${error.message}`);
            throw error;
        }
    };

    useEffect(() => {
        fetchAllData(user.id);
    }, []);

    useEffect(() => {
        if (showCreatePlaylistDialog) {
            newPlaylistNameInputRef.current.focus();
        }
    }, [showCreatePlaylistDialog]);

    //region Create Playlist

    const handleShowCreatePlaylistDialog = () => setShowCreatePlaylistDialog(true);

    const handleCreatePlaylist = async () => {
        if (newPlaylistName.trim()) {
            await playlistService.addPlaylist(newPlaylistName, user.id);

            const newPlaylists = await playlistService.getPlaylists(user.id);
            setPlaylists(newPlaylists);

            handleCloseCreatePlaylistDialog();
        }
    };

    const handleNewPlaylistNameInputKeyPress = async (event) => {
        if (event.key === "Enter") await handleCreatePlaylist();
    };

    const handleCloseCreatePlaylistDialog = () => {
        setShowCreatePlaylistDialog(false);
        setNewPlaylistName(""); // Reset the input
    };

    //endregion

    //region Delete playlist

    const handleShowConfirmDeletePlaylistDialog = (playlist) => {
        setPlaylistToDelete(playlist);
        setShowConfirmDeletePlaylistDialog(true);
    };

    const handleConfirmDeletePlaylist = async () => {
        if (playlistToDelete) {
            await playlistService.removePlaylist(playlistToDelete.id);

            const updatedPlaylists = await playlistService.getPlaylists(user.id);
            setPlaylists(updatedPlaylists);
            setPlaylistToDelete(null);
            setShowConfirmDeletePlaylistDialog(false);
        }
    };

    const handleCloseConfirmDeletePlaylistDialog = () => {
        setShowConfirmDeletePlaylistDialog(false);
        setPlaylistToDelete(null);
    };

    //endregion

    const handleRemoveUserFromFavorites = async (favoriteUserDetails) => {
        await favoriteUsersService.remove(user.id, favoriteUserDetails.id);
        cacheService.delete("favoriteUsers");
        await fetchFavoriteUsers(user.id);
    };

    const handleRemoveGameFromFavorites = async (favoriteGameDetails) => {
        await favoriteGamesService.remove(user.id, favoriteGameDetails.id);
        cacheService.delete("favoriteGames");
        await fetchFavoriteGames(user.id);
    };


    const handleToggleViewMode = () => {
        setViewMode(viewMode === "grid" ? "list" : "grid");
    };

    return (
        <div>
            <Tooltip id="addPlaylistTooltip" style={{zIndex: 1500}}>
                {t("home.tooltip1")}
            </Tooltip>
            <Tooltip id="deletePlaylistTooltip" style={{zIndex: 1500}}>
                {t("home.tooltip2")}
            </Tooltip>
            <Tooltip id="deleteShortcutTooltip" style={{zIndex: 1500}}>
                {t("home.tooltip3")}
            </Tooltip>

            <ActionsContainer>
                <ViewModeToggle
                    active={viewMode === "grid"}
                    onClick={handleToggleViewMode}
                    data-tooltip-id="gridViewTooltip"
                >
                    <FaThLarge/>
                    <span>{t("home.grid")}</span>
                </ViewModeToggle>
                <ViewModeToggle
                    active={viewMode === "list"}
                    onClick={handleToggleViewMode}
                    data-tooltip-id="listViewTooltip"
                >
                    <FaList/>
                    <span>{t("home.list")}</span>
                </ViewModeToggle>
            </ActionsContainer>

            <CollapsibleSection title={t("home.section1")}>
                {loadingStreams ? (
                    <CenteredContainer>
                        <LoadingIndicator/>
                    </CenteredContainer>
                ) : streams.length === 0 ? (
                    <p>{t("home.noStreamer")}</p>
                ) : (
                    <GridContainer className={viewMode}>
                        {streams.map((stream) => (
                            <StreamerCard key={stream.id} compact={viewMode === "list"}>
                                <LiveStatusProfile
                                    isOffline={false}
                                    imageUrl={stream.user_image}
                                    channelUrl={`/watch?channel=${stream.user_login}`}
                                />
                                <div className="info">
                                    <TitleLink to={`/watch?channel=${stream.user_login}`}>
                                        {stream.user_name}
                                    </TitleLink>
                                    <a className="game-name" href={`/game/${stream.game_id}`}>
                                        {stream.game_name}
                                    </a>

                                    <p className="view-count">
                                        <span className="viewer-icon"/>
                                        {stream.viewer_count} viewers
                                    </p>
                                </div>
                            </StreamerCard>
                        ))}
                    </GridContainer>
                )}
            </CollapsibleSection>

            <CollapsibleSection title="Playlists">
                {loadingPlaylists ? (
                    <CenteredContainer>
                        <LoadingIndicator/>
                    </CenteredContainer>
                ) : (
                    <>
                        <AddButton
                            type="submit"
                            onClick={handleShowCreatePlaylistDialog}
                            data-tooltip-id="addPlaylistTooltip"
                        >
                            <FaPlus/>
                            {t("home.add")}
                        </AddButton>

                        <GridContainer className={viewMode}>
                            {playlists.length === 0 ? (
                                <p>{t("home.noPlaylist")}</p>
                            ) : (
                                playlists.map((playlist) => (
                                    <PlaylistCard key={playlist.id} compact={viewMode === "list"}>
                                        <TitleLink
                                            to={`/watch?list=${playlist.id}&index=${
                                                playlist.index !== undefined ? playlist.index : 0
                                            }`}
                                        >
                                            {playlist.name}
                                        </TitleLink>
                                        <p>{t("home.nb")}: {playlist.playlist_videos.length}</p>
                                        <DeleteButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleShowConfirmDeletePlaylistDialog(playlist);
                                            }}
                                            data-tooltip-id="deletePlaylistTooltip"
                                        >
                                            <FaTrashAlt/>
                                            {t("home.delete")}
                                        </DeleteButton>
                                    </PlaylistCard>
                                ))
                            )}
                        </GridContainer>
                    </>
                )}
            </CollapsibleSection>

            <CollapsibleSection title={t("home.section2")} icon={<AiFillStar size={24} style={{marginRight: "8px", color: "gold"}}/>}>
                <SubSectionTitle>
                    <FaUserFriends size={20}/>
                    {t("home.users")}
                </SubSectionTitle>
                {loadingFavoriteUsers ? (
                    <CenteredContainer>
                        <LoadingIndicator/>
                    </CenteredContainer>
                ) : favoriteUsers.length === 0 ? (
                    <p>{t("home.noUser")}</p>
                ) : (
                    <GridContainer className={viewMode}>
                        {favoriteUsers.map((favoriteUser) => (
                            <FavoriteCard key={favoriteUser.id} compact={viewMode === "list"}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: viewMode === "list" ? "0" : "10px"
                                    }}
                                >
                                    <LiveStatusProfile
                                        isOffline={!favoriteUser.isOnline}
                                        imageUrl={favoriteUser.profile_image_url}
                                        channelUrl={`/watch?channel=${favoriteUser.login}`}
                                    />

                                    <div className="info">
                                        <TitleLink
                                            to={`/channel/${favoriteUser.login}`}
                                        >
                                            {favoriteUser.display_name}
                                        </TitleLink>
                                    </div>
                                </div>

                                <div>
                                    <DeleteButton
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                            await handleRemoveUserFromFavorites(favoriteUser);
                                        }}
                                        data-tooltip-id="deleteShortcutTooltip"
                                    >
                                        <FaTrashAlt/>
                                        {viewMode === "grid" && <span>{t("home.removeFavorite")}</span>}
                                    </DeleteButton>
                                </div>
                            </FavoriteCard>
                        ))}
                    </GridContainer>
                )}

                <SubSectionTitle>
                    <GiGamepad size={20}/>
                    {t("home.section3")}
                </SubSectionTitle>
                {loadingFavoriteGames ? (
                    <CenteredContainer>
                        <LoadingIndicator/>
                    </CenteredContainer>
                ) : favoriteGames.length === 0 ? (
                    <p>{t("home.noGame")}</p>
                ) : (
                    <GridContainer className={viewMode}>
                        {favoriteGames.map((game) => (
                            <FavoriteCard key={game.id} compact={viewMode === "list"}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: viewMode === "list" ? "0" : "10px"
                                    }}
                                >
                                    <ImageContainer>
                                        <GameImage
                                            src={game.box_art_url
                                                .replace("{width}", "150")
                                                .replace("{height}", "200")}
                                            alt={game.name}
                                        />
                                    </ImageContainer>
                                    <div className="info">
                                        <TitleLink
                                            to={`/game/${game.id}`}
                                        >
                                            {game.name}
                                        </TitleLink>
                                    </div>
                                </div>

                                <div>
                                    <DeleteButton
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                            await handleRemoveGameFromFavorites(game);
                                        }}
                                        data-tooltip-id="deleteShortcutTooltip"
                                    >
                                        <FaTrashAlt/>
                                        {viewMode === "grid" && <span>{t("home.removeFavorite")}</span>}
                                    </DeleteButton>
                                </div>
                            </FavoriteCard>
                        ))}
                    </GridContainer>
                )}
            </CollapsibleSection>

            {showCreatePlaylistDialog && (
                <Overlay>
                    <DialogBox>
                        <h2>{t("home.newPlaylist")}</h2>
                        <div>
                            <input
                                ref={newPlaylistNameInputRef}
                                type="text"
                                value={newPlaylistName}
                                onChange={(e) => setNewPlaylistName(e.target.value)}
                                onKeyUp={async (e) => await handleNewPlaylistNameInputKeyPress(e)}
                                placeholder="Nom de la playlist"
                            />
                        </div>
                        <DialogButton onClick={async () => await handleCreatePlaylist()}>
                            {t("home.create")}
                        </DialogButton>
                        <DialogButton onClick={handleCloseCreatePlaylistDialog}>{t("home.cancel")}</DialogButton>
                    </DialogBox>
                </Overlay>
            )}

            {showConfirmDeletePlaylistDialog && (
                <Overlay>
                    <DialogBox>
                        <h2>{t("home.confirm")}</h2>
                        <p>
                            {t("home.confirm2")} '
                            {playlistToDelete.name}' ?
                        </p>
                        <DialogButton
                            onClick={async () => await handleConfirmDeletePlaylist()}
                        >
                            {t("home.confirmOk")}
                        </DialogButton>
                        <DialogButton onClick={handleCloseConfirmDeletePlaylistDialog}>
                            {t("home.cancel")}
                        </DialogButton>
                    </DialogBox>
                </Overlay>
            )}
        </div>
    );
};

const StreamerCard = styled.div.withConfig({
    shouldForwardProp: (prop) => prop !== "compact"
})`
    background-color: ${props => props.theme.cardBackground};
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: ${props => (props.compact ? "0.3rem" : "1.2rem")};
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    position: relative;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    margin-bottom: ${props => (props.compact ? "0.4rem" : "1rem")};

    img {
        width: ${props => (props.compact ? "60px" : "80px")};
        height: ${props => (props.compact ? "60px" : "80px")};
        border-radius: 50%;
        object-fit: cover;
    }

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
        border-color: ${props => props.theme.primary}40;
    }

    .info {
        margin-left: ${props => (props.compact ? "0.4rem" : "1.2rem")};
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        gap: ${props => (props.compact ? "0.1rem" : "0.2rem")};
    }

    .game-name {
        font-size: ${props => (props.compact ? "0.7rem" : "0.95rem")}; /* Taille plus petite en mode compact */
        font-weight: 500;
        color: ${props => props.theme.highlightColor};
        text-decoration: none;
        transition: color 0.2s ease;

        &:hover {
            color: ${props => props.theme.primary};
            text-decoration: underline;
        }
    }

    .view-count {
        font-size: ${props => (props.compact ? "0.7rem" : "0.9rem")}; /* Taille plus petite en mode compact */
        color: ${props => props.theme.subTextColor};
        display: flex;
        align-items: center;
        gap: 0.3rem;
    }

    .viewer-icon {
        width: ${props => (props.compact ? "4px" : "6px")}; /* Réduction de la taille de l’icône en mode compact */
        height: ${props => (props.compact ? "4px" : "6px")};
        border-radius: 50%;
        background-color: #ef4444;
        box-shadow: 0 0 2px #ef4444;
    }
`;

const PlaylistCard = styled.div.withConfig({
    shouldForwardProp: (prop) => prop !== "compact"
})`
    background: ${props => props.theme.cardBackground};
    background: linear-gradient(145deg, ${props => props.theme.cardBackground} 0%, ${props => props.theme.cardBackground}ee 100%);
    color: ${props => props.theme.color};
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: ${props => (props.compact ? "0.4rem" : "1.2rem")};
    margin: 0;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    position: relative;
    overflow: hidden;
    height: ${props => (props.compact ? "auto" : "initial")};

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        border-color: ${props => props.theme.primary}40;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(45deg, transparent 0%, rgba(145, 70, 255, 0.05) 100%);
        }
    }
`;

const FavoriteCard = styled(PlaylistCard).withConfig({
    shouldForwardProp: (prop) => prop !== "compact"
})`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    img {
        width: ${props => (props.compact ? "60px" : "80px")};
        height: ${props => (props.compact ? "60px" : "80px")};
        object-fit: cover;
    }

    .info {
        margin-left: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
    }
`;

const ActionsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
`;

const ViewModeToggle = styled.button.withConfig({
    shouldForwardProp: (prop) => prop !== "active"
})`
    background-color: ${(props) =>
            props.active
                    ? props.theme.primary
                    : props.theme.cardBackground};
    color: ${(props) =>
            props.active
                    ? "white"
                    : props.theme.color};
    border: none;
    border-radius: 8px;
    padding: 0.6rem 1rem;
    cursor: pointer;
    font-size: 0.95rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: all 0.2s ease;

    &:hover {
        background-color: ${(props) => props.theme.highlightColor};
        color: white;
        transform: translateY(-2px);
        box-shadow: 0 4px 12px ${(props) => props.theme.highlightColor}40;
    }

    &:not(:last-child) {
        margin-right: 0.5rem;
    }

    svg {
        font-size: 1rem;
    }
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 1.2rem;
    margin-top: 1.5rem;
    width: 100%;
    box-sizing: border-box;

    &.list {
        grid-template-columns: 1fr;
        gap: 0.8rem;

        ${StreamerCard}, ${PlaylistCard}, ${FavoriteCard} {
            flex-direction: row;
            align-items: center;
            padding: 1rem;

            .info {
                margin-left: 1.5rem;
                margin-right: auto;
            }
        }
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
`;

// const CollapsibleSection = styled.div`
//     margin: 2rem 0;
//     padding: 1.5rem;
//     background: ${props => props.theme.cardBackground}80;
//     border-radius: 12px;
//     box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
// `;

const SectionTitle = styled.h2`
    color: ${props => props.theme.color};
    font-size: 1.8rem;
    font-weight: 600;
    position: relative;
    padding-bottom: 0.8rem;
    margin-bottom: 1.2rem;
    display: flex;
    align-items: center;
    gap: 0.8rem;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 60px;
        height: 3px;
        background: ${props => props.theme.primary};
        border-radius: 3px;
    }
`;

const SubSectionTitle = styled.h3`
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: ${props => props.theme.highlightColor};
    margin: 1.5rem 0 1rem;
    gap: 0.6rem;

    svg {
        color: ${props => props.theme.primary};
    }
`;

const AddButton = styled.button`
    display: flex;
    align-items: center;
    padding: 0.8rem 1.2rem;
    background-color: ${props => props.theme.primary};
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 0.95rem;
    font-weight: 600;
    transition: all 0.2s ease;
    gap: 0.5rem;

    &:hover {
        background-color: ${props => props.theme.highlightColor};
        transform: translateY(-2px);
        box-shadow: 0 4px 12px ${props => props.theme.primary}40;
    }

    &:active {
        transform: translateY(0);
    }

    svg {
        font-size: 1rem;
    }
`;

const DeleteButton = styled.button`
    display: flex;
    position: relative;
    z-index: 1000;
    pointer-events: auto;

    background-color: transparent;
    color: ${props => props.theme.error};
    border: 1px solid ${props => props.theme.error};
    border-radius: 8px;
    padding: 0.6rem 1rem;
    cursor: pointer;
    transition: all 0.2s ease;

    align-items: center;
    font-weight: 500;
    font-size: 0.9rem;
    gap: 0.4rem;
    margin-top: auto;

    &:hover {
        background-color: ${props => props.theme.error};
        color: white;
        transform: translateY(-2px);
        box-shadow: 0 4px 12px ${props => props.theme.error};
    }

    &:active {
        transform: translateY(0);
    }

    svg {
        font-size: 0.9rem;
    }
`;

const ImageContainer = styled.div`
    flex-shrink: 0;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 8px;
        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
    }
`;

const GameImage = styled.img`
    width: 90px;
    height: 120px;
    border-radius: 8px;
    object-fit: cover;
    transition: transform 0.2s ease;

    ${FavoriteCard}:hover & {
        transform: scale(1.05);
    }
`;

const GameTitle = styled.h2`
    font-size: 1.1rem;
    color: ${props => props.theme.color};
    margin: 0;
    font-weight: 600;
    transition: color 0.2s ease;

    &:hover {
        color: ${props => props.theme.primary};
    }
`;


export default HomePage;
