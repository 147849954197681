import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ isAuthenticated, children }) => {
  const location = useLocation();
  return isAuthenticated ? (
    children
  ) : (
    <Navigate
      to={`/login?returnUrl=${encodeURIComponent(
        location.pathname + location.search
      )}`}
    />
  );
};

export default ProtectedRoute;
