import {useState, useEffect, useRef} from "react";
import styled from "styled-components";
import {AiOutlinePlus} from "react-icons/ai";
import {Tooltip} from "react-tooltip";
import {useTranslation} from "react-i18next";

const MultiplePlaylistSelector = ({
                                      videoId,
                                      playlists,
                                      addPlaylist,
                                      addVideoToPlaylist,
                                      removeVideoFromPlaylist
                                  }) => {
    const {t} = useTranslation();
    const selectorRef = useRef(null);
    const playlistContainerRef = useRef(null);
    const inputRef = useRef(null);

    const [showMinWindow, setShowMinWindow] = useState(false);

    const [selectedPlaylists, setSelectedPlaylists] = useState([]);

    const [newPlaylistName, setNewPlaylistName] = useState("");

    useEffect(() => {
        if (playlists.length > 0) {
            const defaultSelectedIds = playlists
                .filter((playlist) =>
                    playlist.playlist_videos.some(video => video.video_id === videoId)
                )
                .map((playlist) => playlist.id);
            setSelectedPlaylists(defaultSelectedIds);
        }
    }, [videoId, playlists]);

    useEffect(() => {
        if (showMinWindow) {
            inputRef.current.focus();
        }
    }, [showMinWindow]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleTogglePlaylist = async (playlist) => {
        const isSelected = selectedPlaylists.includes(playlist.id);
        if (isSelected) {
            await removeVideoFromPlaylist(playlist.id, videoId);
            setSelectedPlaylists(
                selectedPlaylists.filter((id) => id !== playlist.id)
            );
        } else {
            await addVideoToPlaylist(playlist.id, videoId);
            setSelectedPlaylists([...selectedPlaylists, playlist.id]);
        }
    };

    const handleCreatePlaylist = async () => {
        const normalizedPlaylistName = newPlaylistName.trim();
        if (normalizedPlaylistName) {
            const newPlaylist = await addPlaylist(normalizedPlaylistName);
            await addVideoToPlaylist(newPlaylist.id, videoId);

            setNewPlaylistName("");
            setSelectedPlaylists([...selectedPlaylists, newPlaylist.id]);

            // Défile jusqu'en bas de la liste
            if (playlistContainerRef.current) {
                playlistContainerRef.current.scrollTop = playlistContainerRef.current.scrollHeight;
            }
        }
    };

    const handleInputKeyPress = async (event) => {
        if (event.key === "Enter") await handleCreatePlaylist();
    };

    const handleToggleMenuOpen = (event) => {
        event.stopPropagation();
        const newOpen = !showMinWindow;
        setShowMinWindow(newOpen);
    };

    // Gestionnaire pour fermer le popup si on clique à l'extérieur
    const handleClickOutside = (event) => {
        if (selectorRef.current && !selectorRef.current.contains(event.target)) {
            setShowMinWindow(false);
        }
    };

    return (
        <>
            <Tooltip id="addToPlaylistTooltip" style={{zIndex: "1500"}}>
                {t("selector.add")}
            </Tooltip>

            <div style={{position: "relative"}} ref={selectorRef}>
                <Button onClick={handleToggleMenuOpen}>
                    {selectedPlaylists.length > 0
                        ? `${selectedPlaylists.length} ${t("selector.nb")}`
                        : t("selector.choose")}
                    <AiOutlinePlus size={18}/>
                </Button>

                {showMinWindow && (
                    <MiniWindow>
                        <h4 style={{fontSize: "14px", marginBottom: "10px"}}>
                            {t("selector.choose")}
                        </h4>
                        <PlaylistContainer ref={playlistContainerRef}>
                            {playlists.map((playlist) => (
                                <PlaylistItem
                                    key={playlist.id}
                                    isSelected={selectedPlaylists.includes(playlist.id)}
                                    onClick={async () => await handleTogglePlaylist(playlist)}
                                >
                                    {playlist.name}
                                </PlaylistItem>
                            ))}
                        </PlaylistContainer>
                        <h4 style={{fontSize: "14px", marginBottom: "10px"}}>
                            {t("selector.newPlaylist")}
                        </h4>
                        <Input
                            ref={inputRef}
                            type="text"
                            placeholder={t("selector.placeholder")}
                            value={newPlaylistName}
                            onChange={(e) => setNewPlaylistName(e.target.value)}
                            onKeyUp={async (e) => await handleInputKeyPress(e)}
                        />
                        <LargeButton onClick={async () => await handleCreatePlaylist()}>
                            {t("add")}
                        </LargeButton>
                    </MiniWindow>
                )}
            </div>
        </>
    );
};

const Button = styled.div`
    display: flex;
    border: none;
    border-radius: 5px;
    overflow: hidden;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 300px;
    background-color: ${(props) => props.theme.cardBackground};
    color: ${(props) => props.theme.color};
    padding: 5px;

    &:hover {
        transform: scale(1.05);
        background-color: ${(props) => props.theme.highlightColor};
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 5px ${(props) => props.theme.error};
    }

    svg {
        margin-left: 5px;
    }
`;

const LargeButton = styled.div`
    background-color: ${(props) => props.theme.error};
    color: white;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s;

    &:hover {
        background-color: ${(props) => props.theme.errorHover};
    }
`;

const Input = styled.input`
    padding: 8px;
    margin-bottom: 8px;
    border: 1px solid ${(props) => props.theme.border};
    border-radius: 5px;
    width: calc(100% - 16px);
    background-color: ${(props) => props.theme.cardBackground};
    color: ${(props) => props.theme.color};
`;

const PlaylistItem = styled.div.withConfig({
    shouldForwardProp: (prop) => prop !== "isSelected"
})`
    padding: 8px;
    margin: 5px 0;
    background-color: ${(props) =>
            props.isSelected
                    ? props.theme.selectedItemBackground
                    : props.theme.cardBackground};
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: ${(props) => props.theme.selectedItemBackground};
    }
`;

const MiniWindow = styled.div`
    position: absolute;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    background-color: ${(props) => props.theme.cardBackground};
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 15px;
    width: 220px;
    z-index: 1500;
    color: ${(props) => props.theme.color};
`;

// Conteneur scrollable pour les éléments de playlist
const PlaylistContainer = styled.div`
    max-height: 100px;
    overflow-y: auto;
    margin-bottom: 10px;

    /* Style de base de la scrollbar */

    &::-webkit-scrollbar {
        width: 8px;
    }

    /* Style du track (fond) de la scrollbar */

    &::-webkit-scrollbar-track {
        background: ${(props) => props.theme.scrollbarTrack};
        border-radius: 4px;
    }

    /* Style du thumb (la partie mobile) de la scrollbar */

    &::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.scrollbarThumb};
        border-radius: 4px;

        &:hover {
            background: ${(props) => props.theme.scrollbarThumbHover};
        }

        &:active {
            background: ${(props) => props.theme.scrollbarThumb};
        }
    }
`;


export default MultiplePlaylistSelector;
