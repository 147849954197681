import React, {Component} from 'react';
import logger from "../services/Logger";
import ErrorPage from "../pages/ErrorPage";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, error: null, info: null};
    }

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, info) {
        this.setState({error, info});
        logger.error(`Boundary: ${error.message}`);
    }

    render() {
        if (this.state.hasError)
            return <ErrorPage message={this.state.error?.message}/>

        return this.props.children;
    }
}

export default ErrorBoundary;
