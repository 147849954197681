import { createSlice } from "@reduxjs/toolkit";

const themeSlice = createSlice({
  name: "theme",
  initialState: {
    // Convertir la chaîne en booléen, avec une valeur par défaut si non défini dans localStorage
    isDarkMode: localStorage.getItem("isDarkMode") === "true" ? true : false,
  },
  reducers: {
    setIsDarkMode: (state, action) => {
      state.isDarkMode = action.payload;
      localStorage.setItem("isDarkMode", action.payload.toString()); // Stocker sous forme de chaîne
    },
  },
});

export const { setIsDarkMode } = themeSlice.actions;
export default themeSlice.reducer;
