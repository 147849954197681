import {useEffect, useState} from "react";
import twitchAPIService from "../services/Twitch/TwitchAPIService";
import favoriteGamesService from "../services/Supabase/SupabaseFavoriteGamesService";
import playlistService from "../services/Supabase/SupabasePlaylistService";
import languageService from "../services/LanguageService";
import cacheService from "../services/CacheService";
import {formatDate, formatDuration} from "../utils/dateUtils";
import styled from "styled-components";
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorPage from "../pages/ErrorPage";
import {Link, useParams} from "react-router-dom";
import logger from "../services/Logger";
import {
    CenteredContainer, LoadingIndicator,
    LoadMoreButton,
    FavoriteButton,
    TabButton, ButtonSpinner
} from "../components/StyledControls";
import LiveStreamsGrid from "../components/LiveStreamsGrid";
import LanguageSelector from "../components/LanguageSelector";
import MultiplePlaylistSelector from "../components/MultiplePlaylistSelector";
import {CheckCircle, MoreVertical} from "lucide-react";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";
import {useTranslation} from "react-i18next";

const GamePage = ({user}) => {
    const {t} = useTranslation();

    const {gameId} = useParams(); // Récupère l'ID du jeu depuis l'URL

    const [selectedLanguages, setSelectedLanguages] = useState(languageService.getSelectedLanguages());

    const [error, setError] = useState(null);
    const [loadingGame, setLoadingGame] = useState(true);
    const [game, setGame] = useState([]);

    const [loadingStreams, setLoadingStreams] = useState(true);
    const [loadingMoreStreams, setLoadingMoreStreams] = useState(false);
    const [streams, setStreams] = useState([]);
    const [streamsPaginationCursor, setStreamsPaginationCursor] = useState(null);

    const [loadingVideos, setLoadingVideos] = useState(true);
    const [loadingMoreVideos, setLoadingMoreVideos] = useState(false);
    const [videos, setVideos] = useState([]);
    const [videosPaginationCursor, setVideosPaginationCursor] = useState(null);

    const [loadingPlaylists, setLoadingPlaylists] = useState(true);
    const [playlists, setPlaylists] = useState();

    const [isFavoriteGameAdded, setIsFavoriteGameAdded] = useState();

    const [activeTab, setActiveTab] = useState("streams");

    const fetchGame = async () => {
        setLoadingGame(true);
        try {
            const gameInfo = await twitchAPIService.GameService.getGameInfo(gameId);
            if (gameInfo) setGame(gameInfo);
            else throw new Error("Game not found");

            if (user) {
                const shortcut = await favoriteGamesService.getById(user.id, gameId);
                if (shortcut) setIsFavoriteGameAdded(true);
            }

        } catch (error) {
            logger.error(`GameDetailsPage - fetchGame - error ${error.message}`);
            setError(error);
        } finally {
            setLoadingGame(false);
        }
    };

    const fetchStreams = async (isLoadMore = false) => {
        if (isLoadMore) setLoadingMoreStreams(true);
        else {
            setLoadingStreams(true);
            setStreams([]);
        }
        try {
            const {data: newStreams, pagination} =
                await twitchAPIService.StreamService.getStreamsByGame(
                    gameId,
                    selectedLanguages,
                    streamsPaginationCursor
                );

            setStreams((prevStreams) => [...prevStreams, ...newStreams]);
            setStreamsPaginationCursor(pagination ? pagination.cursor : null);
        } catch (error) {
            logger.error(`GameDetailsPage - fetchStreams - error ${error.message}`);
        } finally {
            setLoadingStreams(false);
            setLoadingMoreStreams(false);
        }
    };

    const fetchVideos = async (isLoadMore = false) => {
        if (isLoadMore) setLoadingMoreVideos(true);
        else {
            setLoadingVideos(true);
            setVideos([]);
        }

        try {
            const {data: newVideos, pagination} =
                await twitchAPIService.VideoService.getVideosByGame(
                    gameId,
                    selectedLanguages,
                    videosPaginationCursor
                );

            setVideos((prevVideos) => [...prevVideos, ...newVideos]);
            setVideosPaginationCursor(pagination ? pagination.cursor : null);
        } catch (error) {
            logger.error(`GameDetailsPage - fetchVideos - error ${error.message}`);
        } finally {
            setLoadingVideos(false);
            setLoadingMoreVideos(false);
        }
    };

    const fetchPlaylists = async (userId) => {
        setLoadingPlaylists(true);
        try {
            const userPlaylists = await playlistService.getPlaylists(userId);
            setPlaylists(userPlaylists);
            return userPlaylists;
        } catch (error) {
            logger.error(`ChannelPage - fetchPlaylists - error ${error.message}`);
        } finally {
            setLoadingPlaylists(false);
        }
    };

    useEffect(() => {
        if (gameId) {
            fetchGame();
        }
    }, [gameId]);

    useEffect(() => {
        fetchStreams();
        fetchVideos();
        if (user) {
            fetchPlaylists(user.id);
        }
    }, [selectedLanguages]);

    const handleToggleLanguage = (languageCode) => {
        const languages = languageService.getSelectedLanguages();
        const isSelected = languages.includes(languageCode);
        if (isSelected) {
            languageService.removeLanguage(languageCode);
        } else {
            languageService.addLanguage(languageCode);
        }
        const updatedLanguages = languageService.getSelectedLanguages();
        setSelectedLanguages(updatedLanguages);
    };

    const createTwitchCategoryUrl = (gameName) =>
        `https://www.twitch.tv/directory/category/${gameName
            .toLowerCase()
            .replace(/\s+/g, "-")}`;

    // MultiplePlaylistSelector

    const handleAddPlaylist = async (name) => {
        const newPlaylist = await playlistService.addPlaylist(name, user.id);
        const newPlaylists = await playlistService.getPlaylists(user.id);
        setPlaylists(newPlaylists);
        return newPlaylist;
    };

    const handleAddVideoToPlaylist = async (playlistId, videoId) =>
        await playlistService.addVideoToPlaylist(playlistId, videoId);

    const handleRemoveVideoFromPlaylist = async (playlistId, videoId) =>
        await playlistService.removeVideoFromPlaylist(playlistId, videoId);

    const toggleGameToFavorites = async () => {
        if (isFavoriteGameAdded) {
            await favoriteGamesService.remove(user.id, gameId);
            setIsFavoriteGameAdded(false);
        } else {
            await favoriteGamesService.add(user.id, gameId);
            setIsFavoriteGameAdded(true);
        }
        cacheService.delete("favoriteGames");
    };

    if (error) return <ErrorPage message={t("game.error")}/>;

    if (loadingGame) return <LoadingSpinner/>;

    return (
        <Container>
            <HeaderContainer>
                <ImageContainer>
                    <GameImage
                        src={game.box_art_url
                            .replace("{width}", "150")
                            .replace("{height}", "200")}
                        alt={game.name}
                    />
                </ImageContainer>
                <Info>
                    <GameTitle>{game.name}</GameTitle>
                    <CategoryLink
                        href={createTwitchCategoryUrl(game.name)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t("game.category")}
                    </CategoryLink>
                    {user &&
                        <div style={{marginTop: "20px"}}>
                            <FavoriteButton onClick={async () => await toggleGameToFavorites()}>
                                {isFavoriteGameAdded ? (
                                    <AiFillStar
                                        size={24}
                                        style={{marginRight: "8px", color: "gold"}}
                                    />
                                ) : (
                                    <AiOutlineStar
                                        size={24}
                                        style={{marginRight: "8px", color: "gray"}}
                                    />
                                )}
                                {isFavoriteGameAdded
                                    ? t("game.removeFavorite")
                                    : t("game.addFavorite")}
                            </FavoriteButton>
                        </div>
                    }
                </Info>
            </HeaderContainer>
            <div>
                <LanguageSelector
                    selectedLanguages={selectedLanguages}
                    setSelectedLanguages={handleToggleLanguage}
                />
            </div>
            <div>
                <TabButton
                    active={activeTab === "streams"}
                    onClick={() => setActiveTab("streams")}
                >
                    {t("game.channelTab")}
                </TabButton>
                <TabButton
                    active={activeTab === "videos"}
                    onClick={() => setActiveTab("videos")}
                >
                    {t("game.videos")}
                </TabButton>
            </div>
            {activeTab === "streams" && loadingStreams && (
                <CenteredContainer>
                    <LoadingIndicator/>
                </CenteredContainer>
            )}
            {activeTab === "streams" && !loadingStreams && (
                <>
                    <LiveStreamsGrid streams={streams}/>

                    {streamsPaginationCursor && (
                        <CenteredContainer>
                            <LoadMoreButton onClick={() => fetchStreams(true)}>
                <span>
                  {loadingMoreStreams && <ButtonSpinner size={24}/>}
                    {loadingMoreStreams ?  t("loading") :  t("loadMore") }
                </span>
                            </LoadMoreButton>
                        </CenteredContainer>
                    )}
                </>
            )}
            {activeTab === "videos" && loadingVideos && (
                <CenteredContainer>
                    <LoadingIndicator/>
                </CenteredContainer>
            )}
            {activeTab === "videos" && !loadingVideos && (
                <>
                    <GridContainer>
                        <Grid>
                            {videos.length > 0 ? (
                                videos.map((video, index) => (
                                    <VideoCard key={index}>
                                        <Link
                                            style={{position: "relative"}}
                                            to={`/watch?video=${video.id}`}
                                        >
                                            <ThumbnailContainer>
                                                <Thumbnail className="thumbnail">
                                                    <img
                                                        src={video.thumbnail_url
                                                            .replace("%{width}", "320")
                                                            .replace("%{height}", "180")}
                                                        alt={video.title}
                                                    />
                                                </Thumbnail>
                                                <Duration>{formatDuration(video.duration)}</Duration>
                                                <ViewCount>{video.view_count} {t("game.views")}</ViewCount>
                                                <UploadTime>
                                                    {formatDate(video.published_at,t("language"))}
                                                </UploadTime>
                                            </ThumbnailContainer>
                                        </Link>
                                        <VideoInfo>
                                            <ChannelAvatar>
                                                <img
                                                    src={video.user_info.profile_image_url}
                                                    alt={video.user_name}
                                                />
                                            </ChannelAvatar>
                                            <MetaContainer>
                                                <Title>{video.title}</Title>
                                                <ChannelName to={`/channel/${video.user_info.login}`}>
                                                    {video.user_name}
                                                    <CheckCircle/>
                                                </ChannelName>
                                                <TextContent>
                                                    {user && !loadingPlaylists && (
                                                        <MultiplePlaylistSelector
                                                            videoId={video.id}
                                                            playlists={playlists}
                                                            addPlaylist={handleAddPlaylist}
                                                            addVideoToPlaylist={handleAddVideoToPlaylist}
                                                            removeVideoFromPlaylist={
                                                                handleRemoveVideoFromPlaylist
                                                            }
                                                            data-tooltip-id="addToPlaylistTooltip"
                                                        />
                                                    )}
                                                </TextContent>
                                                <Actions>
                                                    <MoreVertical size={20}/>
                                                </Actions>
                                            </MetaContainer>
                                        </VideoInfo>
                                    </VideoCard>
                                ))
                            ) : (
                                <p>{t("game.noVideo")}</p>
                            )}
                        </Grid>
                    </GridContainer>

                    {streamsPaginationCursor && (
                        <CenteredContainer>
                            <LoadMoreButton onClick={() => fetchVideos(true)}>
                <span>
                  {loadingMoreVideos && <ButtonSpinner size={24}/>}
                    {loadingMoreVideos ? t("loading") :t("loadMore")}
                </span>
                            </LoadMoreButton>
                        </CenteredContainer>
                    )}
                </>
            )}
        </Container>
    );
};

const TextContent = styled.div`
    flex: 1;
`;

const Container = styled.div`
    background-color: ${({theme}) => theme.background};
    color: ${({theme}) => theme.color};
    padding: 24px;
    max-width: 1920px;
    margin: 0 auto;
    min-height: calc(100vh - 60px);
`;

const HeaderContainer = styled.div`
    display: flex;
    gap: 24px;
    padding: 16px;
    background: ${({theme}) => theme.elevatedBackground || 'rgba(0, 0, 0, 0.2)'};
    border-radius: 12px;
    margin-bottom: 24px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const ImageContainer = styled.div`
    flex: 0 0 auto;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 8px;
        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
    }
`;

const Info = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const GameImage = styled.img`
    width: 150px;
    height: 200px;
    border-radius: 8px;
    object-fit: cover;
    transition: transform 0.2s ease;

    &:hover {
        transform: scale(1.02);
    }
`;

const GameTitle = styled.h1`
    font-size: 2.5em;
    font-weight: 600;
    color: ${({theme}) => theme.color};
    margin: 0;
    line-height: 1.2;
    letter-spacing: -0.5px;
`;

const CategoryLink = styled.a`
    font-size: 16px;
    font-weight: 500;
    color: ${({theme}) => theme.highlightColor || '#9147ff'};
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    padding: 8px 12px;
    background: rgba(145, 71, 255, 0.1);
    border-radius: 6px;
    transition: all 0.2s ease;

    &:hover {
        background: rgba(145, 71, 255, 0.2);
        color: ${({theme}) => theme.highlightColor || '#9147ff'};
    }
`;

const GridContainer = styled.div`
    max-width: 100%;
    margin: 0 auto;
    padding: 16px 0;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 24px;
    padding: 0 8px;

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1400px) {
        grid-template-columns: repeat(4, 1fr);
    }
`;

const VideoCard = styled.div`
    background: ${({theme}) => theme.elevatedBackground || 'rgba(0, 0, 0, 0.2)'};
    border-radius: 12px;
    // overflow: hidden;
    transition: transform 0.2s ease;

    &:hover {
        transform: translateY(-2px);

        .thumbnail img {
            transform: scale(1.05);
        }
    }
`;

const ThumbnailContainer = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    overflow: hidden;
`;

const Thumbnail = styled.div`
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;
    }
`;

const Duration = styled.div`
    position: absolute;
    bottom: 8px;
    right: 8px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 4px 6px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.3px;
`;

const ViewCount = styled.div`
    position: absolute;
    bottom: 8px;
    left: 8px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
`;

const UploadTime = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
`;

const VideoInfo = styled.div`
    display: flex;
    gap: 12px;
    padding: 12px;
`;

const ChannelAvatar = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    border: 2px solid transparent;
    transition: border-color 0.2s ease;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    ${VideoCard}:hover & {
        border-color: ${({theme}) => theme.highlightColor || '#9147ff'};
    }
`;

const MetaContainer = styled.div`
    position: relative;
    flex: 1;
    padding-right: 32px;
`;

const Title = styled.h3`
    font-size: 15px;
    font-weight: 600;
    color: ${({theme}) => theme.color};
    margin: 0 0 6px 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.4;
    letter-spacing: 0.2px;
`;

const ChannelName = styled(Link)`
    display: flex;
    align-items: center;
    gap: 4px;
    color: ${({theme}) => theme.secondaryText || '#adadb8'};
    font-size: 13px;
    font-weight: 500;
    text-decoration: none;
    transition: color 0.2s ease;

    svg {
        width: 14px;
        height: 14px;
        color: ${({theme}) => theme.highlightColor || '#9147ff'};
    }

    &:hover {
        color: ${({theme}) => theme.highlightColor || '#9147ff'};
    }
`;

const Actions = styled.button`
    color: ${({theme}) => theme.secondaryText || '#adadb8'};
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    transition: all 0.2s ease;
    border-radius: 50%;

    &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: ${({theme}) => theme.color};
    }

    ${VideoCard}:hover & {
        opacity: 1;
    }
`;

export default GamePage;
