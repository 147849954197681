import {useEffect, useState} from "react";
import twitchAPIService from "../services/Twitch/TwitchAPIService";
import logger from "../services/Logger";
import styled from "styled-components";
import LoadingSpinner from "./LoadingSpinner";
import {useSelector} from "react-redux";
import {
    StreamerImage,
    ChannelLink,
    TagsContainer,
    Tag,
} from "./StyledControls";
import {Tooltip} from "react-tooltip";
import {Link} from "react-router-dom";
import {BiBroadcast} from "react-icons/bi";
import {useTranslation} from "react-i18next";

const StreamDetails = () => {
    const {t} = useTranslation();
    const {channel} = useSelector((state) => state.player);

    const [loadingStreamDetails, setLoadingStreamDetails] = useState(true);
    const [streamDetails, setStreamDetails] = useState(null);

    const getStream = async (channel) => {
        try {
            setLoadingStreamDetails(true);
            const userId = await twitchAPIService.UserService.getUserId(channel);
            const user = await twitchAPIService.UserService.getUserById(userId);
            const details = await twitchAPIService.StreamService.getStream(userId);
            setStreamDetails({
                ...details,
                user_info: user,
            });
        } catch (error) {
            logger.error(
                `ChannelDetails - fetStream - error ${error.message}`,
                "ChannelDetails"
            );
        } finally {
            setLoadingStreamDetails(false);
        }
    };

    useEffect(() => {
        if (channel)
            getStream(channel);
    }, [channel]);

    if (loadingStreamDetails) return <LoadingSpinner/>;

    return (
        <>
            <Tooltip id="navToChannelTootltip" style={{zIndex: 1500}}>
                {t("details.tooltip1")}
            </Tooltip>

            <VideoDetailsContainer>
                <StreamerImage
                    src={streamDetails.user_info.profile_image_url}
                    alt="Streamer"
                />
                <div>
                    <VideoTitle>{streamDetails.title}</VideoTitle>
                    <ChannelLink
                        to={`/channel/${streamDetails.user_login}`}
                        data-tooltip-id="navToChannelTootltip"
                    >
                        {streamDetails.user_info.display_name}
                    </ChannelLink>
                    <GameInfoContainer>
                        <GameNameLink to={`/game/${streamDetails.game_id}`}>
                            {streamDetails.game_name}
                        </GameNameLink>
                        <TagsContainer>
                            {streamDetails.tags &&
                                streamDetails.tags.map((tag, index) => (
                                    <Tag key={index}>{tag}</Tag>
                                ))}
                        </TagsContainer>
                    </GameInfoContainer>
                </div>
            </VideoDetailsContainer>

            <DetailsContainer>
                <Title>
                    <BiBroadcast/>
                    {t("details.about")} {streamDetails.user_info.display_name}
                </Title>

                <StreamerDescription>
                    {streamDetails.user_info.description
                        ? streamDetails.user_info.description
                        : t("details.noDescription")}
                </StreamerDescription>
            </DetailsContainer>
        </>
    );
};

const VideoDetailsContainer = styled.div`
    background-color: ${({theme}) => theme.cardBackground};
    padding: 15px;
    margin-top: 15px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    border: 1px solid ${({theme}) => theme.borderColor};

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
    }

`;

const VideoTitle = styled.h2`
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    color: ${({theme}) => theme.color};
    transition: color 0.2s ease;

    &:hover {
        color: ${({theme}) => theme.primary};
    }
`;

// Conteneur principal pour le nom du jeu et les tags
const GameInfoContainer = styled.div`
    display: flex;
    align-items: center; /* Centre les éléments verticalement */
    gap: 10px;
`;

// Style pour le nom du jeu
const GameNameLink = styled(Link)`
    font-size: 16px; /* Taille de police réduite pour le nom du jeu */
    margin: 0; /* Supprime le margin par défaut */
    color: ${({theme}) => theme.color};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const DetailsContainer = styled.div`
    background-color: ${({theme}) => theme.cardBackground};
    padding: 15px;
    margin-top: 15px;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
`;

const Title = styled.h3`
    font-size: 18px;
    margin: 0;
    color: ${({theme}) => theme.color};
    display: flex;
    align-items: center;

    & > svg {
        margin-right: 8px; /* Espace entre l'icône et le texte */
    }
`;

// Style pour le descriptif du streamer
const StreamerDescription = styled.p`
    font-size: 14px; /* Taille de police pour le descriptif */
    margin: 10px 0 0; /* Marge au-dessus, aucune marge en dessous */
    color: ${({theme}) => theme.color};
`;


export default StreamDetails;
