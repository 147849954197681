import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {useScreen} from "../contexts/ScreenContext";
import { AiOutlineClose, AiOutlineExpand } from "react-icons/ai";
import styled from "styled-components";

const Player = () => {
  const playerRef = useRef(null);
  const navigate = useNavigate();
  const {
    oldChannel,
    oldVideo,
    video,
    channel,
    playlist,
    playlistIndex,
    isMiniPlayer,
    videoQuality,
    isChatEnabled
  } = useSelector((state) => state.player);
  const { isMobile } = useScreen();
  const [isPlayerVisible, setIsPlayerVisible] = useState(false);

  const setVideoQuality = () => {
    if (videoQuality !== "auto") {
      playerRef.current.getPlayer().setQuality(videoQuality);
    }
  };

  const handleReady = () => {
    playerRef.current.getPlayer().setMuted(false);
    setVideoQuality();
  };

  const removePlayer = () => {
    const existing = document
      .getElementById("twitch-embed")
      .querySelector("iframe");
    if (existing) existing.remove();
  };

  const updatePlayer = () => {
    const iframe = document
      .getElementById("twitch-embed")
      .querySelector("iframe");
    if (iframe) {
      iframe.style.width = isMiniPlayer ? "280px" : "100%";
      iframe.style.height = isMiniPlayer ? "160px" : "100%";
    }
  };

  const getChatLayout = (isMobile, isMiniPlayer) => {
     if (!isChatEnabled) {
      return "video";
    }
    const isSmallScreen = isMobile && window.innerWidth < 768;
    return isSmallScreen || isMiniPlayer ? "video" : "video-with-chat";
  };

  const createPlayer = () => {
    const options = {
      width: isMiniPlayer ? "280px" : "100%",
      height: isMiniPlayer ? "160px" : "100%",
      parent: [window.location.hostname]
    };

    if (video) options.video = video;
    else if (channel) {
      options.channel = channel;
      options.layout = getChatLayout(isMobile, isMiniPlayer);
    }

    playerRef.current = new window.Twitch.Embed("twitch-embed", options);

    playerRef.current.addEventListener(window.Twitch.Player.READY, handleReady);
    playerRef.current.addEventListener(window.Twitch.Player.ENDED, () => {
      if (playlist) {
        const loop = sessionStorage.getItem("loop_state");
        // Logger.info(
        //   `Player - event ENDED - Playlist ${playlist.id} ${playlist.name} with loopstate ${loop} index ${playlistIndex} with video ids length ${playlist.playlist_videos.length}`,
        //   "Player"
        // );
        if (loop === "LOOP_VIDEO") {
          playerRef.current.seek(0);
          playerRef.current.play();
        } else {
          const nextIndex = playlistIndex + 1;
          if (nextIndex < playlist.playlist_videos.length)
            navigate(`/watch?list=${playlist.id}&index=${nextIndex}`);
          else if (loop === "LOOP_PLAYLIST")
            navigate(`/watch?list=${playlist.id}&index=0`);
        }
      }
    });

    setIsPlayerVisible(true);
  };

  const addTwitchEmbedScriptIfMissingAndCreatePlayer = () => {
    if (window.Twitch && window.Twitch.Embed) createPlayer();
    else {
      const script = document.createElement("script");
      script.src = "https://embed.twitch.tv/embed/v1.js";
      script.async = true;
      script.onload = () => createPlayer();
      document.body.appendChild(script);
    }
  };

  useEffect(() => {
    // Logger.info(
    //   `Player -  useEffect / video channel - channel ${channel}, video ${video}, old channel ${oldChannel}, old video ${oldVideo}, playlist ${playlist}, mini ${isMiniPlayer}`,
    //   "Player"
    // );
    if (video) {
      // mini player closed ?
      if (!playerRef.current) addTwitchEmbedScriptIfMissingAndCreatePlayer();
      else {
        // si on regardait un live avec chat recréer le player pour cacher le chat
        if (isChatEnabled && oldChannel) {
          removePlayer();
          createPlayer();
        } else {
          updatePlayer();
          playerRef.current.setVideo(video);
        }
      }
    } else if (channel) {
      if (!playerRef.current) addTwitchEmbedScriptIfMissingAndCreatePlayer();
      else {
        // si on regardait une video recréer le player pour afficher le chat si il est enabled
        if (isChatEnabled && oldVideo) {
          removePlayer();
          createPlayer();
        }
        // sinon on change juste de chaine
        else {
          updatePlayer();
          playerRef.current.setChannel(channel);
        }
      }
    }
  }, [video, channel]);

  useEffect(() => {
    // Logger.info(
    //   `Player -  UseEffect /isMiniPlayer - isMiniPlayer ${isMiniPlayer}, channel ${channel}, video ${video}, old channel ${oldChannel}, old video ${oldVideo}`,
    //   "Player"
    // );
    if (channel) {
      // gestion du chat avec live
      if (isChatEnabled) {
        removePlayer();
        createPlayer();
      } else {
        updatePlayer();
      }
    } else if (!playerRef.current && video && video === oldVideo) {
      // si on a close le mini player et que l'on renavigue vers la même video
      addTwitchEmbedScriptIfMissingAndCreatePlayer();
    }
  }, [isMiniPlayer, isMobile]);

  useEffect(() => {
    if (playerRef.current) setVideoQuality();
  }, [videoQuality]);

  const handleClosePlayer = () => {
    if (playerRef.current) {
      removePlayer();
      playerRef.current = null;
    }
    setIsPlayerVisible(false); // Masquer le player et les boutons
  };

  const handleMaximize = () => {
    if (channel) navigate(`/watch?channel=${channel}`);
    else if (playlist && playlist.id)
      navigate(`/watch?list=${playlist.id}&index=${playlistIndex}`);
    else if (video) navigate(`/watch?video=${video}`);
  };

  return (
    <PlayerWrapper $isMiniPlayer={isMiniPlayer}>
      <PlayerContainer
        $isMiniPlayer={isMiniPlayer}
        $isPlayerVisible={isPlayerVisible}
        hasChat={channel && isChatEnabled && !isMobile}
      >
        <TwitchEmbed $isMiniPlayer={isMiniPlayer} id="twitch-embed">
          {isMiniPlayer && (
            <>
              <MaximizeButton onClick={handleMaximize}>
                <AiOutlineExpand />
              </MaximizeButton>
              <CloseButton onClick={handleClosePlayer}>
                <AiOutlineClose />
              </CloseButton>
            </>
          )}
        </TwitchEmbed>
      </PlayerContainer>
    </PlayerWrapper>
  );
};

const PlayerWrapper = styled.div`
  margin: 0 auto;
  @media (min-width: 1440px) {
    max-width: 1400px;
  }
`;

const PlayerContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "hasChat"
})`
  position: relative;
  width: 100%;
  padding-top: ${({ $isMiniPlayer, $isPlayerVisible, hasChat }) =>
    $isMiniPlayer || !$isPlayerVisible
        ? "0"
        : hasChat
            ? "calc((100% - 340px) * 9 / 16)" // Avec chat
            : "56.25%"}; // Sans chat (ratio 16:9 standard)

  overflow: hidden;
  ${({ $isPlayerVisible }) => !$isPlayerVisible && "display: none;"}
`;

const TwitchEmbed = styled.div`
  z-index: ${({ $isMiniPlayer }) => ($isMiniPlayer && 1500)};
  position: ${({ $isMiniPlayer }) => ($isMiniPlayer ? "fixed" : "absolute")};
  bottom: ${({ $isMiniPlayer }) => ($isMiniPlayer ? "10px" : "0")};
  right: ${({ $isMiniPlayer }) => ($isMiniPlayer ? "10px" : "0")};
  width: ${({ $isMiniPlayer }) => ($isMiniPlayer ? "280px" : "100%")};
  height: ${({ $isMiniPlayer }) => ($isMiniPlayer ? "160px" : "100%")};
`;

const MaximizeButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(
    -50%,
    -50%
  ); /* Centre horizontalement et verticalement */
  background-color: transparent;
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: rgba(100, 65, 165, 0.8); /* Fond violet Twitch */
    transform: translate(-50%, -50%) scale(1.1); /* Centre et agrandissement */
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: rgba(255, 99, 71, 0.8); /* Fond rouge clair Twitch */
    transform: scale(1.1); /* Agrandissement au hover */
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;


export default Player;
