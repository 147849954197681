
export class LanguageService {
 static LANGUAGES_KEY = "selectedLanguages";

  saveSelectedLanguages(languages) {
    localStorage.setItem(LanguageService.LANGUAGES_KEY, JSON.stringify(languages));
  }

  getSelectedLanguages() {
    const savedLanguages = localStorage.getItem(LanguageService.LANGUAGES_KEY);
    if (savedLanguages) return JSON.parse(savedLanguages);
    return ["fr", "en"];
  }

  addLanguage(languageCode) {
    const currentLanguages = this.getSelectedLanguages();
    if (!currentLanguages.includes(languageCode)) {
      currentLanguages.push(languageCode);
      this.saveSelectedLanguages(currentLanguages);
      return true;
    }
    return false;
  }

  removeLanguage(languageCode) {
    const currentLanguages = this.getSelectedLanguages();
    if (currentLanguages.includes(languageCode)) {
      const updatedLanguages = currentLanguages.filter(
          (lang) => lang !== languageCode
      );
      this.saveSelectedLanguages(updatedLanguages);
      return true;
    }
    return false;
  }

  clearSelectedLanguages() {
    localStorage.removeItem(LanguageService.LANGUAGES_KEY);
  }
}

const languageService = new LanguageService();
export default languageService;

