import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setChatVisibility, setVideoQuality} from "../slices/playerSlice";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const SettingsPage = () => {
    const dispatch = useDispatch();
    const {i18n, t} = useTranslation();
    const [isEnglish, setIsEnglish] = useState(i18n.language === 'en');

    const {videoQuality, isChatEnabled} = useSelector((state) => state.player);
    const [quality, setQuality] = useState(videoQuality);
    const [chatEnabled, setChatEnabled] = useState(videoQuality);

    useEffect(() => {
        setQuality(videoQuality);
        setChatEnabled(isChatEnabled);
    }, [dispatch]);


    const handleQualityChange = (e) => {
        const selectedQuality = e.target.value;
        setQuality(selectedQuality);
        dispatch(setVideoQuality(selectedQuality));
    };

    const handleChatToggle = () => {
        const newVisibility = !chatEnabled;
        setChatEnabled(newVisibility);
        dispatch(setChatVisibility(newVisibility));
    };

    const handleLanguageChange = () => {
        const newLang = isEnglish ? 'fr' : 'en';
        i18n.changeLanguage(newLang);
        setIsEnglish(!isEnglish); // Toggle language state
    };

    return (
        <SettingsWrapper>
            <Title>{t("settings.label")}</Title>
            <Label htmlFor="video-quality">
                {t("settings.videoQualityLabel")}
            </Label>
            <Select id="video-quality" value={quality} onChange={handleQualityChange}>
                <option value="auto">auto</option>
                <option value="160p">160p</option>
                <option value="360p">360p</option>
                <option value="480p">480p</option>
                <option value="720p">720p</option>
                <option value="1080p">1080p</option>
            </Select>
            <Label>
                <Checkbox
                    type="checkbox"
                    checked={chatEnabled}
                    onChange={handleChatToggle}
                />
                {t("settings.chatEnabledLabel")}
            </Label>

            <LanguageSection>
                <Label>{t('settings.languageLabel')}</Label>
                <LanguageButtonGroup>
                    <LanguageButton
                        active={!isEnglish}
                        onClick={() => handleLanguageChange('fr')}
                    >
                        Français
                    </LanguageButton>
                    <LanguageButton
                        active={isEnglish}
                        onClick={() => handleLanguageChange('en')}
                    >
                        English
                    </LanguageButton>
                </LanguageButtonGroup>
            </LanguageSection>

        </SettingsWrapper>
    );
};
const SettingsWrapper = styled.div`
    background-color: ${({theme}) => theme.background};
    color: ${({theme}) => theme.color};
    padding: 30px;
    border-radius: 10px;
    border: 1px solid ${({theme}) => theme.border};
    box-shadow: 0 8px 24px ${({theme}) => theme.shadowMedium};
`;

const Title = styled.h1`
    color: ${props => props.theme.color};
    font-size: 1.8rem;
    font-weight: 600;
    position: relative;
    padding-bottom: 0.8rem;
    margin-bottom: 1.2rem;
    display: flex;
    align-items: center;
    gap: 0.8rem;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 60px;
        height: 3px;
        background: ${props => props.theme.primary};
        border-radius: 3px;
    }
`;

const Label = styled.label`
    font-size: 16px;
    margin-bottom: 10px;
    display: block;
    color: ${({theme}) => theme.textDimmed};
    font-weight: 500;
    transition: color 0.3s ease;

    &:hover {
        color: ${({theme}) => theme.primary};
    }
`;

const Select = styled.select`
    background-color: ${({theme}) => theme.inputBg};
    color: ${({theme}) => theme.inputText};
    padding: 12px 15px;
    border: 1px solid ${({theme}) => theme.inputBorder};
    border-radius: 6px;
    width: 100%;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    appearance: none;

    &:hover, &:focus {
        border-color: ${({theme}) => theme.primary};
        box-shadow: 0 0 0 3px ${({theme}) => theme.highlightColor + '33'};
        outline: none;
    }
`;

const Checkbox = styled.input`
    appearance: none;
    width: 22px;
    height: 22px;
    border: 2px solid ${({theme}) => theme.border};
    border-radius: 4px;
    margin-right: 10px;
    vertical-align: middle;
    cursor: pointer;
    transition: all 0.3s ease;

    &:checked {
        background-color: ${({theme}) => theme.primary};
        border-color: ${({theme}) => theme.primary};
    }

    &:checked::after {
        content: '✓';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: ${({theme}) => theme.background};
    }
`;

const LanguageSection = styled.div`
    margin-bottom: 25px;
    padding: 20px;
    background-color: ${({theme}) => theme.cardBackground};
    border-radius: 8px;
    border: 1px solid ${({theme}) => theme.border};
`;
const LanguageButtonGroup = styled.div`
    display: flex;
    gap: 15px;
    justify-content: center;
`;

const LanguageButton = styled.button`
    position: relative;
    padding: 10px 20px;
    border: 1px solid ${({theme}) => theme.primary};
    border-radius: 6px;
    background-color: ${({active, theme}) => active ? theme.primary : 'transparent'};
    color: ${({active, theme}) => active ? 'white' : theme.primary};
    cursor: pointer;
    transition: ${({theme}) => theme.transition};
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    overflow: hidden;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            120deg, 
            transparent, 
            ${({theme}) => theme.highlightColor}30, 
            transparent
        );
        transition: all 0.6s ease;
        z-index: -1;
    }

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 15px ${({theme}) => theme.shadowLight};
        
        &::before {
            left: 100%;
        }
    }

    &:active {
        transform: translateY(1px);
        box-shadow: none;
    }

    ${({active, theme}) => !active && `
        &:hover {
            background-color: ${theme.hoverBg};
            color: ${theme.primary};
        }
    `}
`;
export default SettingsPage;
