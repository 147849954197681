import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import logger from "../services/Logger";
import twitchAPIService from "../services/Twitch/TwitchAPIService";
import playlistService from "../services/Supabase/SupabasePlaylistService";
import {formatDate} from "../utils/dateUtils";
import styled from "styled-components";
import {ChannelLink, StreamerImage} from "./StyledControls";
import {Tooltip} from "react-tooltip";
import LoadingSpinner from "./LoadingSpinner";
import MultiplePlaylistSelector from "./MultiplePlaylistSelector";
import {useTranslation} from "react-i18next";

const VideoDetails = ({user}) => {
    const {t} = useTranslation();
    const {video} = useSelector((state) => state.player); // video id

    const [loadingVideoDetails, setLoadingVideoDetails] = useState(true);
    const [videoDetails, setVideoDetails] = useState(null); // video details

    const [loadingPlaylists, setLoadingPlaylists] = useState(true);
    const [playlists, setPlaylists] = useState();

    const getVideo = async (video) => {
        try {
            const details = await twitchAPIService.VideoService.getVideo(video);
            const user = await twitchAPIService.UserService.getUserById(
                details.user_id
            );
            setVideoDetails({
                ...details,
                user_info: user,
            });
        } catch (error) {
            logger.error(`VideoDetails - getVideo - error ${error.message}`);
        } finally {
            setLoadingVideoDetails(false);
        }
    };

    const fetchPlaylists = async (userId) => {
        setLoadingPlaylists(true);
        try {
            const userPlaylists = await playlistService.getPlaylists(userId);
            setPlaylists(userPlaylists);
            return userPlaylists;
        } catch (error) {
            logger.error(`ChannelPage - fetchPlaylists - error ${error.message}`);
        } finally {
            setLoadingPlaylists(false);
        }
    };

    useEffect(() => {
        if (video) {
            getVideo(video);
        }
    }, [video]);

    useEffect(() => {
        if (user) {
            fetchPlaylists(user.id);
        }
    }, []);

    const handleAddPlaylist = async (name) => {
        const newPlaylist = await playlistService.addPlaylist(name, user.id);
        const newPlaylists = await playlistService.getPlaylists(user.id);
        setPlaylists(newPlaylists);
        return newPlaylist;
    };

    const handleAddVideoToPlaylist = async (playlistId, videoId) =>
        await playlistService.addVideoToPlaylist(playlistId, videoId);

    const handleRemoveVideoFromPlaylist = async (playlistId, videoId) =>
        await playlistService.removeVideoFromPlaylist(playlistId, videoId);

    if (loadingVideoDetails) return <LoadingSpinner/>;

    return (
        <>
            <Tooltip id="navToChannelTootltip" style={{zIndex: 1500}}>
                {t("details.tooltip1")}
            </Tooltip>

            <VideoDetailsContainer>
                <StreamerImage
                    src={videoDetails.user_info.profile_image_url}
                    alt="Streamer"
                />
                <div>
                    <VideoTitle>{videoDetails.title}</VideoTitle>
                    <StreamerInfoContainer>
                        <ChannelLink
                            to={`/channel/${videoDetails.user_login}`}
                            data-tooltip-id="navToChannelTootltip"
                        >
                            {videoDetails.user_name}
                        </ChannelLink>
                    </StreamerInfoContainer>
                    <VideoDate>{formatDate(videoDetails.published_at, t("language"))}</VideoDate>
                    {user && !loadingPlaylists && (
                        <MultiplePlaylistSelector
                            videoId={video}
                            playlists={playlists}
                            addPlaylist={handleAddPlaylist}
                            addVideoToPlaylist={handleAddVideoToPlaylist}
                            removeVideoFromPlaylist={handleRemoveVideoFromPlaylist}
                            data-tooltip-id="addToPlaylistTooltip"
                        />
                    )}
                </div>
            </VideoDetailsContainer>
        </>
    );
};

const VideoDetailsContainer = styled.div`
    background-color: ${({theme}) => theme.cardBackground};
    padding: 15px;
    margin-top: 15px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    border: 1px solid ${({theme}) => theme.borderColor};

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
    }

`;

const VideoTitle = styled.h2`
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    color: ${({theme}) => theme.color};
    transition: color 0.2s ease;

    &:hover {
        color: ${({theme}) => theme.primary};
    }
`;

const DetailsContainer = styled.div`
    background-color: ${({theme}) => theme.cardBackground};
    padding: 15px;
    margin-top: 15px;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
`;

const Title = styled.h3`
    font-size: 18px;
    margin: 0;
    color: ${({theme}) => theme.color};
`;

// Style pour le descriptif du streamer
const StreamerDescription = styled.p`
    font-size: 14px;
    margin: 10px 0 0;
    color: ${({theme}) => theme.color};
`;

const StreamerInfoContainer = styled.div`
    display: flex;
    align-items: center;
`;

const VideoDate = styled.p`
    margin: 5px 0;
    color: ${({theme}) => theme.color};
`;

//
// const VideoDetailsContainer = styled.div`
//     background-color: ${({theme}) => theme.cardBackground};
//     padding: 15px;
//     margin-top: 15px;
//     border-radius: 4px;
//     display: flex;
//     align-items: center;
//     margin-bottom: 10px;
//     box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
//     transition: transform 0.2s ease, box-shadow 0.2s ease;
//     border: 1px solid ${({theme}) => theme.borderColor};
//
//     &:hover {
//         transform: translateY(-2px);
//         box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
//     }
//
// `;
//
// const VideoTitle = styled.h2`
//     font-size: 18px;
//     font-weight: 600;
//     margin: 0;
//     color: ${({theme}) => theme.color};
//     transition: color 0.2s ease;
//
//     &:hover {
//         color: ${({theme}) => theme.primary};
//     }
// `;
//
// const StreamerInfoContainer = styled.div`
//     display: flex;
//     align-items: center;
//     gap: 12px;
//     margin: 8px 0;
//     padding: 4px 0;
//
//     &:hover {
//         ${ChannelLink} {
//             color: ${({theme}) => theme.primary};
//             text-decoration: underline;
//         }
//     }
//
//     @media (max-width: 768px) {
//         justify-content: center;
//     }
// `;
//
// const VideoDate = styled.p`
//     margin: 5px 0;
//     color: ${({theme}) => theme.textDimmed};
//     font-size: 14px;
//     font-weight: 400;
//     transition: color 0.2s ease;
//     opacity: 0.9;
//
//     &:hover {
//         opacity: 1;
//     }
// `;
//
// // Additional styled components to enhance the layout
// const StreamerImageContainer = styled.div`
//     position: relative;
//     width: 48px;
//     height: 48px;
//     border-radius: 50%;
//     overflow: hidden;
//     border: 2px solid ${({theme}) => theme.primary};
//     flex-shrink: 0;
//     transition: transform 0.2s ease, border-color 0.2s ease;
//
//     &:hover {
//         transform: scale(1.05);
//         border-color: ${({theme}) => theme.highlightColor};
//     }
// `;
//
// const StyledStreamerImage = styled(StreamerImage)`
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     transition: transform 0.2s ease;
//
//     &:hover {
//         transform: scale(1.1);
//     }
// `;
//
// const ContentWrapper = styled.div`
//     flex: 1;
//     min-width: 0; // Pour éviter le débordement du texte
//     display: flex;
//     flex-direction: column;
//     gap: 4px;
// `;
//
// const MetaInfo = styled.div`
//     display: flex;
//     align-items: center;
//     gap: 12px;
//     margin-top: 4px;
//     color: ${({theme}) => theme.textDimmed};
//     font-size: 14px;
//
//     @media (max-width: 768px) {
//         flex-direction: column;
//         align-items: center;
//         gap: 8px;
//     }
// `;
//
// const StyledChannelLink = styled(ChannelLink)`
//     color: ${({theme}) => theme.color};
//     font-weight: 500;
//     text-decoration: none;
//     transition: color 0.2s ease;
//
//     &:hover {
//         color: ${({theme}) => theme.primary};
//         text-decoration: underline;
//     }
// `;

export default VideoDetails;
