import {useState} from "react";
import {useLocation} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import userAccessTokenService from "../services/Twitch/TwitchUserAccessTokenService";
import {setIsDarkMode} from "../slices/themeSlice";
import Logger from "../services/Logger";
import {ButtonSpinner} from "../components/StyledControls";
import {FaTwitch, FaMoon, FaSun} from "react-icons/fa";
import styled, {keyframes, css} from "styled-components";
import {useTranslation} from "react-i18next";

const LoginPage = ({visitCount = 0}) => {
    const {t} = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const {isDarkMode} = useSelector((state) => state.theme);
    const [isLogging, setIsLogging] = useState(false);

    const handleLogin = async () => {
        setIsLogging(true);
        try {
            const params = new URLSearchParams(location.search);
            const returnUrl = params.get("returnUrl") || "/";
            await userAccessTokenService.login(returnUrl);
        } catch (error) {
            Logger.error(`LoginPage - handleLogin - error ${error.message}`);
        } finally {
            setIsLogging(false);
        }
    };
    return (
        <PageContainer>
            <ThemeToggle onClick={() => dispatch(setIsDarkMode(!isDarkMode))}>
                {isDarkMode ? <FaSun size={20}/> : <FaMoon size={20}/>}
            </ThemeToggle>

            <HeroSection>
                <PageLogo src="logo.png" alt="Twitch Playlist"/>
                <PageTitle>{t('login.welcome')}</PageTitle>
                <PageSubtitle>{t('login.subtitle')}</PageSubtitle>
                <LoginButton onClick={handleLogin} disabled={isLogging}>
                    {isLogging ? <ButtonSpinner size={24}/> : <FaTwitch size={24}/>}
                    <span>{isLogging ? t('login.loggingIn') : t('login.loginButton')}</span>
                </LoginButton>
            </HeroSection>

            <FeaturesGrid>
                <FeatureCard>
                    <FeatureImage src="./assets/tp1.jpg" alt="Jouer la playlist"/>
                    <FeatureContent>
                        <FeatureTitle>{t('login.feature1')}</FeatureTitle>
                        <FeatureDescription>{t('login.feature1Desc')}</FeatureDescription>
                    </FeatureContent>
                </FeatureCard>

                <FeatureCard>
                    <FeatureImage src="./assets/tp2.jpg" alt="Rechercher les vidéos"/>
                    <FeatureContent>
                        <FeatureTitle>{t('login.feature2')}</FeatureTitle>
                        <FeatureDescription>{t('login.feature2Desc')}</FeatureDescription>
                    </FeatureContent>
                </FeatureCard>

                <FeatureCard>
                    <FeatureImage src="./assets/tp3.jpg" alt="Regardez plusieurs streams"/>
                    <FeatureContent>
                        <FeatureTitle>{t('login.feature3')}</FeatureTitle>
                        <FeatureDescription>{t('login.feature3Desc')}</FeatureDescription>
                    </FeatureContent>
                </FeatureCard>
            </FeaturesGrid>

            <Footer>
                <VisitCounter>{visitCount} {t('login.users')}</VisitCounter>
            </Footer>
        </PageContainer>
    );
};

const gradient = css`
    background: linear-gradient(135deg, #9146ff 0%, #9146ff 100%);
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const PageContainer = styled.div`
    min-height: 100vh;
    background: ${(props) => props.theme.background};
    color: ${(props) => props.theme.color};
    padding: 2rem;
    position: relative;
    overflow-x: hidden;
`;

const ThemeToggle = styled.button`
    position: absolute;
    top: 1rem;
    right: 2rem;
    background: none;
    border: none;
    color: ${(props) => props.theme.color};
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    transition: all 0.3s ease;

    &:hover {
        background: rgba(145, 70, 255, 0.1);
        transform: rotate(180deg);
    }
`;

const HeroSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 4rem;
    animation: ${fadeIn} 0.8s ease-out;
`;

const PageLogo = styled.img`
    width: 350px;
    height: auto;
    margin-bottom: 2rem;
    filter: drop-shadow(0 0 10px rgba(145, 70, 255, 0.3));
`;

const PageTitle = styled.h1`
    font-size: 3.5rem;
    font-weight: 800;
    margin-bottom: 1rem;
    background: linear-gradient(135deg, #9146ff 0%, #ff6ac1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const PageSubtitle = styled.p`
    font-size: 1.25rem;
    color: ${(props) => props.theme.color};
    opacity: 0.9;
    max-width: 600px;
    margin-bottom: 2rem;
`;

const LoginButton = styled.button`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: white;
    background: #9146ff;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(145, 70, 255, 0.3);

    &:hover:not(:disabled) {
        transform: translateY(-2px);
        background: #7f2fff;
        box-shadow: 0 6px 16px rgba(145, 70, 255, 0.4);
    }

    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }

    svg {
        font-size: 1.5rem;
    }
`;

const FeaturesGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin: 4rem 0;
    padding: 0 1rem;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const FeatureCard = styled.div`
    background: ${(props) => props.theme.cardBackground};
    border-radius: 1rem;
    overflow: hidden;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 24px rgba(145, 70, 255, 0.2);
    }
`;

const FeatureImage = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 2px solid rgba(145, 70, 255, 0.3);
`;

const FeatureContent = styled.div`
    padding: 1.5rem;
`;

const FeatureTitle = styled.h3`
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #9146ff;
`;

const FeatureDescription = styled.p`
    font-size: 1rem;
    color: ${(props) => props.theme.color};
    opacity: 0.9;
    line-height: 1.6;
`;

const Footer = styled.footer`
    text-align: center;
    margin-top: 4rem;
    padding: 2rem;
`;

const VisitCounter = styled.div`
    display: inline-block;
    padding: 0.75rem 1.5rem;
    background: rgba(145, 70, 255, 0.1);
    border-radius: 2rem;
    font-weight: 600;
    font-size: 1.25rem;
    color: #9146ff;
`;

export default LoginPage;
