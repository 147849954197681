export const formatDuration = (duration) => {
  const regex = /(\d+)([hms])/g; // Regex pour capturer les heures, minutes et secondes
  const timeParts = { hours: 0, minutes: 0, seconds: 0 };

  let match;
  while ((match = regex.exec(duration)) !== null) {
    const value = parseInt(match[1], 10); // Récupérer la valeur numérique
    const unit = match[2]; // Récupérer l'unité (h, m, s)

    switch (unit) {
      case "h":
        timeParts.hours = value;
        break;
      case "m":
        timeParts.minutes = value;
        break;
      case "s":
        timeParts.seconds = value;
        break;
      default:
        break;
    }
  }

  // Formater les valeurs pour avoir toujours 2 chiffres
  const formattedHours = String(timeParts.hours).padStart(2, "0");
  const formattedMinutes = String(timeParts.minutes).padStart(2, "0");
  const formattedSeconds = String(timeParts.seconds).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`; // Retourner le format hh:mm:ss
};

export const formatDate = (dateString, language) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(language, options);
};
