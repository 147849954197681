export const formatViewers = (viewers) => {
  if (viewers < 1000) {
    return viewers.toString(); // Pas de formatage nécessaire si moins de 1000
  } else if (viewers < 1000000) {
    // Pour les milliers
    return (viewers / 1000).toFixed(1).replace(/\.0$/, "") + "k"; // Affiche 10.4k
  } else {
    // Pour les millions
    return (viewers / 1000000).toFixed(1).replace(/\.0$/, "") + "M"; // Affiche 1.2M
  }
};
