import supabase from "../../supabaseClient";

export class SupabaseAuthService {

    async loginUser(twitchUserInfo) {
        if (twitchUserInfo === undefined) throw new Error("Twitch User Info required");

        const { id } = twitchUserInfo;
        const { data } = await supabase
            .from("users")
            .select("*")
            .eq("id", id)
            .single();

        return data; // null si n'existe pas
    }

    async registerUser(twitchUserInfo) {
        if (twitchUserInfo === undefined) throw new Error("Twitch User Info required");

        const { id, display_name, login, profile_image_url } = twitchUserInfo;
        const { data, error } = await supabase
            .from("users")
            .insert([{ id, display_name, login, profile_image_url }])
            .select()
            .single();

        if (error) {
            throw new Error(`SupabaseAuthService - registerUser - Error:${error.message}`);
        }
        return data;
    }
}

const authService = new SupabaseAuthService();
export default authService;