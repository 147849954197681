export class UserSuggestionService {
    static MAX_SUGGESTIONS = 100;
    // Méthode pour obtenir les suggestions
    getSuggestions() {
        const item = localStorage.getItem("channels");
        return item ? JSON.parse(item) : [];
    }

    // Méthode pour normaliser la suggestion
    normalizeSuggestion(suggestion) {
        return suggestion.toLowerCase().trim();
    }

    // Méthode pour sauvegarder les suggestions
    saveSuggestions(suggestions) {
        localStorage.setItem("channels", JSON.stringify(suggestions));
    }

    // Méthode pour filtrer les suggestions
    filterSuggestions(prefix) {
        const normalizedPrefix = this.normalizeSuggestion(prefix);
        return this.getSuggestions().filter((suggestion) =>
            suggestion.startsWith(normalizedPrefix)
        );
    }

    // Méthode pour ajouter une suggestion
    addSuggestion(suggestion) {
        const normalizedSuggestion = this.normalizeSuggestion(suggestion);
        const suggestions = this.getSuggestions();

        if (!suggestions.includes(normalizedSuggestion)) {
            suggestions.push(normalizedSuggestion);
            if (suggestions.length > UserSuggestionService.MAX_SUGGESTIONS) {
                suggestions.shift(); // Remove the oldest suggestion if max limit is reached
            }
            this.saveSuggestions(suggestions);
            return true;
        }
        return false;
    }

    // Méthode pour supprimer une suggestion
    removeSuggestion(suggestion) {
        const normalizedSuggestion = this.normalizeSuggestion(suggestion);
        const suggestions = this.getSuggestions();
        const index = suggestions.indexOf(normalizedSuggestion);

        if (index !== -1) {
            suggestions.splice(index, 1); // Remove the suggestion from the array
            this.saveSuggestions(suggestions);
            return true;
        }
        return false;
    }

    // Méthode pour effacer toutes les suggestions
    clearSuggestions() {
        this.saveSuggestions([]);
    }
}
const userSuggestionService = new UserSuggestionService();
export default userSuggestionService;
