import styled from "styled-components";
import { Coffee } from "lucide-react";
import {useTranslation} from "react-i18next";


const AboutPage = () => {
  const { t } = useTranslation();

  return (
      <AboutContainer>
        <Title>{t("about.title")}</Title>
        <Card>
          <Paragraph>{t("about.description")}</Paragraph>
          <Paragraph>{t("about.allows")}</Paragraph>
          <List>
            {t("about.features", { returnObjects: true }).map((feature, index) => (
                <ListItem key={index}>{feature}</ListItem>
            ))}
          </List>
        </Card>
        <Card>
          <Paragraph>{t("about.improvements.title")}</Paragraph>
          <List>
            {t("about.improvements.items", { returnObjects: true }).map((item, index) => (
                <ListItem key={index}>
                  <Highlight>{item.highlight}</Highlight> : {item.text}
                </ListItem>
            ))}
          </List>
        </Card>
        <Card>
          <KofiButton
              href="https://ko-fi.com/benquoueck"
              target="_blank"
              rel="noopener noreferrer"
          >
            <Coffee size={24} />
            {t("about.support")}
          </KofiButton>
        </Card>
      </AboutContainer>
  );
};

const AboutContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 1.5rem;
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.color};
  min-height: 100vh;
  transition: background-color 0.3s ease, color 0.3s ease;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Card = styled.div`
  background-color: ${(props) => props.theme.cardBackground};
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid ${(props) => props.theme.border};
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    padding: 1.25rem;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  color: ${(props) => props.theme.primary};
  margin-bottom: 1.5rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: 1.25rem;
  }
`;

const Paragraph = styled.p`
  margin-bottom: 1.25rem;
  font-size: 1rem;
  line-height: 1.6;
  color: ${(props) => props.theme.color};

  @media (max-width: 768px) {
    margin-bottom: 1rem;
    font-size: 0.95rem;
  }
`;

const List = styled.ul`
  margin-left: 1.5rem;
  margin-bottom: 1.25rem;

  @media (max-width: 768px) {
    margin-left: 1.25rem;
    margin-bottom: 1rem;
  }
`;

const ListItem = styled.li`
  margin-bottom: 0.4rem;
  color: ${(props) => props.theme.tagText};

  @media (max-width: 768px) {
    margin-bottom: 0.3rem;
  }
`;

const KofiButton = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background-color: ${(props) => props.theme.primary};
  color: white;
  padding: 0.6rem 1.25rem; // Decreased from 0.75rem 1.5rem
  border-radius: 8px;
  text-decoration: none;
  font-weight: 600;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${(props) => props.theme.secondary};
  }

  @media (max-width: 768px) {
    padding: 0.5rem 1rem; // Decreased from 0.6rem 1.25rem
  }
`;

const Highlight = styled.span`
  color: ${(props) => props.theme.primary};
  font-weight: 600;
`;

export default AboutPage;
