import supabase from "../../supabaseClient";

const FavoriteGamesService = {

    getAll: async (userId) => {
        if (userId === undefined) throw new Error("userId required");

        const {data, error} = await supabase
            .from("favorite_games")
            .select("game_id")
            .eq("user_id", userId);

        if (error) {
            return [];
        }

        return data.map((item) => item.game_id); // Retourne un tableau des identifiants de jeux
    },

    getById: async (userId, gameId) => {
        if (userId === undefined) throw new Error("userId required");
        if (gameId === undefined) throw new Error("gameId required");

        const {data} = await supabase
            .from("favorite_games")
            .select("game_id")
            .eq("user_id", userId)
            .eq("game_id", gameId)
            .single();

        return data ? data.game_id : null;
    },

    add: async (userId, gameId) => {
        if (userId === undefined) throw new Error("userId required");
        if (gameId === undefined) throw new Error("gameId required");

        const {error} = await supabase
            .from("favorite_games")
            .insert([{user_id: userId, game_id: gameId}]);

        return !error;
    },

    remove: async (userId, gameId) => {
        if (userId === undefined) throw new Error("userId required");
        if (gameId === undefined) throw new Error("gameId required");

        const {error} = await supabase
            .from("favorite_games")
            .delete()
            .eq("user_id", userId)
            .eq("game_id", gameId);

        return !error;
    },

    clear: async (userId) => {
        if (userId === undefined) throw new Error("userId required");

        const {error} = await supabase
            .from("favorite_games")
            .delete()
            .eq("user_id", userId);

        return !error;
    },
};

export default FavoriteGamesService;
