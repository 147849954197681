import { PulseLoader } from "react-spinners"; // Importation du spinner

const LoadingSpinner = ({ loading = true, color = "#9146ff", size = 15 }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        position: "absolute",
        top: 0,
        left: 0,
      }}
    >
      <PulseLoader color={color} loading={loading} size={size} />
    </div>
  );
};

export default LoadingSpinner;
