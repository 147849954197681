import {Link} from "react-router-dom";
import {BigLogo, ErrorContainer} from "../components/StyledControls";

const ErrorPage = ({message}) => {
    return (
        <ErrorContainer>
            <BigLogo src="/logo.png" alt="Twitch Playlist"/>
            <h1>Erreur</h1>
            <p>{message}</p>
            <Link to="/">Retour vers Accueil</Link>
        </ErrorContainer>
    );
};

export default ErrorPage;
