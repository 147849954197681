import supabase from "../../supabaseClient";

const SupabaseFavoriteUsersService = {

    getAll: async (userId) => {
        if (userId === undefined) throw new Error("userId required");

        const {data, error} = await supabase
            .from("favorite_users")
            .select("favorite_user_id")
            .eq("user_id", userId);

        if (error) {
            return [];
        }

        return data.map((item) => item.favorite_user_id); // Retourne la liste des identifiants des favoris
    },

    getById: async (userId, favoriteUserId) => {
        if (userId === undefined) throw new Error("userId required");
        if (favoriteUserId === undefined) throw new Error("favoriteUserId required");

        const {data} = await supabase
            .from("favorite_users")
            .select("favorite_user_id")
            .eq("user_id", userId)
            .eq("favorite_user_id", favoriteUserId)
            .single();

        return data ? data.favorite_user_id : null;
    },

    add: async (userId, favoriteUserId) => {
        if (userId === undefined) throw new Error("userId required");
        if (favoriteUserId === undefined) throw new Error("favoriteUserId required");

        // Ajouter un nouveau favori
        const {error} = await supabase
            .from("favorite_users")
            .insert([{user_id: userId, favorite_user_id: favoriteUserId}]);

        return !error;
    },

    remove: async (userId, favoriteUserId) => {
        if (userId === undefined) throw new Error("userId required");
        if (favoriteUserId === undefined) throw new Error("favoriteUserId required");

        const {error} = await supabase
            .from("favorite_users")
            .delete()
            .eq("user_id", userId)
            .eq("favorite_user_id", favoriteUserId);

        return !error;
    },

    clear: async (userId) => {
        if (userId === undefined) throw new Error("userId required");

        const {error} = await supabase
            .from("favorite_users")
            .delete()
            .eq("user_id", userId);

        return !error;
    },
};

export default SupabaseFavoriteUsersService;
