import styled from "styled-components";

const LiveStatusProfile = ({isOffline, imageUrl, channelUrl}) => {
    const handleLinkClick = (e) => {
        e.stopPropagation();
    };

    const imageWithLink = (
        <a href={channelUrl} onClick={handleLinkClick}>
            <ChannelImage src={imageUrl} isOffline={isOffline}/>
        </a>
    );

    const imageWithoutLink = (
        <ChannelImage src={imageUrl} isOffline={isOffline}/>
    );

    return (
        <>
            <ImageWrapper
            >
                {isOffline ? imageWithoutLink : imageWithLink}
                <LiveText isOffline={isOffline}>LIVE</LiveText>
            </ImageWrapper>
        </>
    );
};

// Image de profil avec état (en ligne/déconnecté) et effet de survol
const ChannelImage = styled.img.withConfig({
    shouldForwardProp: (prop) => prop !== "isOffline"
})`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    border: ${(props) =>
            props.isOffline ? "2px solid gray" : "2px solid red"}; /* Liseré */
    filter: ${(props) =>
            props.isOffline ? "grayscale(100%)" : "none"}; /* Grisé si déconnecté */

    ${(props) =>
            !props.isOffline &&
            `
    &:hover {
      border-color: #ff4500; /* Changement de couleur de liseré au survol */
      transform: scale(1.05); /* Effet de zoom au survol */
      transition: transform 0.2s ease, border-color 0.2s ease; /* Transition */
    }
  `}
`;

// Conteneur pour l'image et le texte "LIVE"
const ImageWrapper = styled.div`
    position: relative;
    display: inline-block;
`;

// Texte "LIVE" en bas de l'image
const LiveText = styled.span.withConfig({
    shouldForwardProp: (prop) => prop !== "isOffline"
})`
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 0, 0, 0.8); /* Fond rouge avec opacité */
    color: white;
    padding: 2px 6px;
    font-size: 12px;
    border-radius: 5px;
    font-weight: bold;
    display: ${(props) => (props.isOffline ? "none" : "block")};
`;

export default LiveStatusProfile;
