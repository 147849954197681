import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import store from "./store";
import {ScreenProvider} from "./contexts/ScreenContext";
import "./fonts.css";
import App from "./App";
import logger from "./services/Logger";

import './i18n';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

window.addEventListener("error", (event) => {
    logger.error(`Global Error: ${event.message}`, event);
});

window.addEventListener("unhandledrejection", (event) => {
    logger.error(`Unhandled Promise Rejection: ${event.reason}`);
});

root.render(
    <Provider store={store}>
        <ScreenProvider>
            <App/>
        </ScreenProvider>
    </Provider>
);
