// Fonctions utilitaires pour gérer le sessionStorage
export const getCacheFromStorage = (cacheKey) => {
  return JSON.parse(sessionStorage.getItem(cacheKey)) || {};
};

export const saveCacheToStorage = (cacheKey, cache) => {
  sessionStorage.setItem(cacheKey, JSON.stringify(cache));
};

// Classe FollowCacheService
export class FollowCacheService {
  constructor(twitchService, expirationDuration = 30000) {
    // 30 sec
    this.twitchService = twitchService;
    this.cacheKey = "followedChannelsCache";
    this.expirationDuration = expirationDuration; // Durée d'expiration en millisecondes
    this.cache = getCacheFromStorage(this.cacheKey) || {};
  }

  isCacheExpired(userId) {
    const cachedData = this.cache[userId];
    return (
      !cachedData || Date.now() - cachedData.timestamp > this.expirationDuration
    );
  }

  async getFollowedChannels(userId) {
    if (!this.isCacheExpired(userId)) {
      return this.cache[userId].data; // Renvoie les données du cache si elles ne sont pas expirées
    }

    let allChannels = [];
    let paginationCursor = null;

    do {
      const { data, paginationCursor: nextCursor } =
        await this.twitchService.ChannelService.getFollowedChannels(
          userId,
          paginationCursor
        );
      allChannels = [...allChannels, ...data];
      paginationCursor = nextCursor;
    } while (paginationCursor);

    // Stockage dans le cache avec un timestamp
    this.cache[userId] = {
      data: allChannels,
      timestamp: Date.now(), // Enregistre le moment du stockage
    };

    saveCacheToStorage(this.cacheKey, this.cache); // Sauvegarde le cache

    return allChannels;
  }
}

