import {useEffect, useState} from "react";
import twitchAPIService from "../services/Twitch/TwitchAPIService";
import {FollowCacheService} from "../services/FollowCacheService";
import userSuggestionService from "../services/UserSuggestionService";
import styled from "styled-components";
import {
    FlexColumnContainer,
    TwitchLink,
    Title,
    TitleLink,
} from "../components/StyledControls";
import LoadingSpinner from "../components/LoadingSpinner";
import LiveStatusProfile from "../components/LiveStatusProfile";
import Logger from "../services/Logger";
import {Tooltip} from "react-tooltip";
import {useTranslation} from "react-i18next";

const followCacheService = new FollowCacheService(twitchAPIService);

const FollowingPage = ({user}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [followedChannels, setFollowedChannels] = useState([]);

    const fetchFollowedChannels = async () => {
        setLoading(true);
        try {
            const data = await followCacheService.getFollowedChannels(user.id);
            setFollowedChannels(data);
        } catch (error) {
            Logger.error(
                `FollowingPage - fetchFollowedChannels - error ${error.message}`
            );
        } finally {
            setLoading(false);
        }
    };

    const addNewChannelSuggestions = () => {
        const suggestions = userSuggestionService.getSuggestions(); // array user name suggestions

        const newSuggestions = [...suggestions];

        followedChannels.forEach((channel) => {
            if (!newSuggestions.includes(channel.name)) {
                newSuggestions.push(channel.name);
            }
        });

        if (newSuggestions.length > suggestions.length) {
            userSuggestionService.saveSuggestions(newSuggestions);
        }
    };

    useEffect(() => {
        if (user) {
            fetchFollowedChannels();
        }
    }, [user]);

    useEffect(() => {
        if (followedChannels.length > 0) {
            addNewChannelSuggestions();
        }
    }, [followedChannels]);

    if (loading) return <LoadingSpinner/>;

    return (
        <>
            <Tooltip id="watchVideosTooltip" style={{zIndex: "1500"}}>
                {t("following.tooltip1")}
            </Tooltip>
            <Tooltip id="twitchChannelTooltip" style={{zIndex: "1500"}}>
                {t("following.tooltip2")}
            </Tooltip>
            <Tooltip id="navToChannelTootltip" style={{zIndex: 1500}}>
                {t("following.tooltip3")}
            </Tooltip>

            <Title>{t("following.title")}</Title>
            {followedChannels.length === 0 ? (
                <p>{t("following.noChannel")}</p>
            ) : (
                <>
                    <StyledGrid>
                        {followedChannels.map((channel) => (
                            <StyledCard key={channel.user_id}>
                                <LiveStatusProfile
                                    imageUrl={channel.image}
                                    alt={channel.display_name}
                                    isOffline={!channel.is_online}
                                    channelUrl={`/watch?channel=${channel.name}`}
                                />
                                <div>
                                    <TitleLink
                                        to={`/channel/${channel.name}`}
                                        data-tooltip-id="navToChannelTootltip"
                                    >
                                        {channel.display_name}
                                    </TitleLink>
                                </div>

                                <FlexColumnContainer>
                                    <TwitchLink
                                        href={`https://www.twitch.tv/${channel.name}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{marginBottom: "10px"}}
                                        data-tooltip-id="twitchChannelTooltip"
                                    >
                                        {t("following.channel")}
                                    </TwitchLink>
                                </FlexColumnContainer>
                            </StyledCard>
                        ))}
                    </StyledGrid>
                </>
            )}
        </>
    );
};

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
  
  @media (max-width: 1200px) {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
    padding: 15px;
  }
`;

const StyledCard = styled.div`
  background-color: ${({ theme }) => theme.cardBackground};
  border-radius: 10px;
  padding: 12px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.05);
  overflow: hidden;
  position: relative;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-color: ${({ theme }) => theme.primary || '#9147ff'};
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
      90deg,
      transparent,
      ${({ theme }) => theme.primary || '#9147ff'},
      transparent
    );
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover:before {
    opacity: 1;
  }

  img {
    border-radius: 8px;
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    margin-bottom: 8px;
  }

  ${TitleLink} {
    font-size: 1.1em;
    font-weight: 600;
    color: ${({ theme }) => theme.textPrimary || '#efeff1'};
    margin: 8px 0;
    display: block;
    
    &:hover {
      color: ${({ theme }) => theme.primary || '#9147ff'};
    }
  }

  ${FlexColumnContainer} {
    margin-top: 8px;
  }

  ${TwitchLink} {
    font-size: 0.9em;
    padding: 6px 12px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.primary || '#9147ff'};
    color: white;
    text-decoration: none;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: ${({ theme }) => theme.highlightColor || '#7d2df9'};
    }
  }
`;


export default FollowingPage;
