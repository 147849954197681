import {useEffect} from "react";
import userAccessTokenService from "../services/Twitch/TwitchUserAccessTokenService";
import LoadingSpinner from "./LoadingSpinner";
import {useLocation} from "react-router-dom";

const AuthCallback = () => {
    const location = useLocation();
    useEffect(() => {
        userAccessTokenService.handleAuthCallback(location.search);
    }, []);
    return <LoadingSpinner/>;
};

export default AuthCallback;
