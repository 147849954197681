import supabase from "../../supabaseClient";

const generateRandomId = () => {
    const prefix = "pl_";
    const randomString = Math.random().toString(36).substring(2, 10);
    const timestamp = Date.now().toString(36);
    return `${prefix}${randomString}_${timestamp}`;
};

const PlaylistService = {
    async getPlaylists(userId) {
        if(userId === undefined) throw  new Error("userId required");

        let {data} = await supabase
            .from("playlists")
            .select(
                `
          id,
          name,
          user_id,
          created_at,
          playlist_videos (
            video_id
          )
        `
            )
            .eq("user_id", userId);

        return data;
    },

    async getPlaylistById(playlistId) {
        if(playlistId === undefined) throw  new Error("playlistId required");

        const {data} = await supabase
            .from("playlists")
            .select(
                `
          id,
          name,
          user_id,
          created_at,
          playlist_videos (
            video_id
          )
        `
            )
            .eq("id", playlistId)
            .single();

        return data;
    },

    async setPlaylistIndex(playlistId, index) {
        if(playlistId === undefined) throw  new Error("playlistId required");
        if(index === undefined) throw  new Error("index required");

        const playlist = await PlaylistService.getPlaylistById(playlistId);
        if (playlist) {
            if (index < playlist.playlist_videos.length) {
                await supabase
                    .from("playlists")
                    .update({index: index})
                    .eq("id", playlistId);
            }
        }
    },

    async addPlaylist(name, userId) {
        if(name === undefined) throw  new Error("name required");
        if(userId === undefined) throw  new Error("userId required");

        const {data} = await supabase
            .from("playlists")
            .insert([
                {
                    id: generateRandomId(),
                    name,
                    user_id: userId
                }
            ])
            .select();
        return data[0];
    },

    async addVideoToPlaylist(playlistId, videoId) {
        if(playlistId === undefined) throw  new Error("playlistId required");
        if(videoId === undefined) throw  new Error("videoId required");

        const {error} = await supabase
            .from("playlist_videos")
            .insert([{playlist_id: playlistId, video_id: videoId}]);
        return !error;
    },

    async removeVideoFromPlaylist(playlistId, videoId) {
        if(playlistId === undefined) throw  new Error("playlistId required");
        if(videoId === undefined) throw  new Error("videoId required");

        const { error } = await supabase
            .from("playlist_videos")
            .delete()
            .match({
                playlist_id: playlistId,
                video_id: videoId
            });
        return !error;
    },

    async clearVideosFromPlaylist(playlistId) {
        if(playlistId === undefined) throw  new Error("playlistId required");

        const {error} = await supabase
            .from("playlist_videos")
            .delete()
            .eq("playlist_id", playlistId);
        return !error;
    },

    async removePlaylist(playlistId) {
        if(playlistId === undefined) throw  new Error("playlistId required");

        // Supprimer playlist_videos
        const {error: deleteVideosError} = await supabase
            .from("playlist_videos")
            .delete()
            .eq("playlist_id", playlistId);

        if (deleteVideosError) {
           return false;
        }

        // supprimer playlist
        const {error: deletePlaylistError} = await supabase
            .from("playlists")
            .delete()
            .eq("id", playlistId);

        return !deletePlaylistError;
    }
};

export default PlaylistService;
