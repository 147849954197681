import {useEffect, useState} from "react";
import {ThemeProvider} from "styled-components";
import {lightTheme, darkTheme} from "./themes";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";
import {useSelector} from "react-redux";
import userAccessTokenService from "./services/Twitch/TwitchUserAccessTokenService";
import authService from "./services/Supabase/SupabaseAuthService";
import playlistService from "./services/Supabase/SupabasePlaylistService";
import visitService from "./services/Supabase/SupabaseVisitService";
import gameSuggestionService from "./services/GameSuggestionService";
import logger from "./services/Logger";
import ErrorBoundary from "./components/ErrorBoundary";
import GlobalStyle from "./GlobalStyle";
import styled from "styled-components";
import Player from "./components/Player";
import Header from "./components/Header";
import LoadingSpinner from "./components/LoadingSpinner";
import ProtectedRoute from "./components/ProtectedRoute";
import NotFound from "./pages/NotFound";
import AuthCallback from "./components/AuthCallback";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import ChannelPage from "./pages/ChannelPage";
import GamePage from "./pages/GamePage";
import BrowsePage from "./pages/BrowsePage";
import SettingsPage from "./pages/SettingsPage";
import MultiStreamPage from "./pages/MultiStreamPage";
import FollowingPage from "./pages/FollowingPage";
import WatchPage from "./pages/WatchPage";
import AboutPage from "./pages/AboutPage";
import ScrollToTop from "./components/ScrollToTop";
import BackToTopButton from "./components/BackToTopButton";

const App = () => {
    const {isDarkMode} = useSelector((state) => state.theme);

    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState();

    const [visitCount, setVisitCount] = useState();

    const authenticate = async () => {
        setIsAuthenticating(true);

        try {
            // si un access_token est présent dans le localStorage
            const accessToken = userAccessTokenService.getAccessToken();
            if (accessToken) {
                // si expiré on se reconnecte pour refresh le token ... au rechargement de la page le token ne sera plus expiré
                if (userAccessTokenService.isTokenExpired()) {
                    await userAccessTokenService.refreshToken();
                }

                const twitchUserInfo = await userAccessTokenService.getUserInfo();

                let registeredUser = await authService.loginUser(twitchUserInfo);
                if (!registeredUser) {
                    registeredUser = await authService.registerUser(twitchUserInfo);

                    // init
                    await playlistService.addPlaylist("Watch Later", registeredUser.id);
                }

                setUser(registeredUser);
                setIsAuthenticated(true);
            }
        } catch (error) {
            console.error(`App - authUser - Error auth user: ${error.message}`);
        } finally {
            setIsAuthenticating(false);
        }
    };

    const fetchVisits = async () => {
        try {
            const result = await visitService.fetchIpAndLocation(user?.id);
            setVisitCount(result);
        } catch (error) {
            logger.error(error.message);
        }
    };

    const preloadGameSuggestions = async () => {
        try {
            await gameSuggestionService.preloadTopGames();
        } catch (error) {
            logger.error(error.message);
        }
    };

    useEffect(() => {
        authenticate();
        fetchVisits();
        preloadGameSuggestions();
    }, []);

    if (isAuthenticating) return <LoadingSpinner/>;

    return (
        <ErrorBoundary>
            <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
                <GlobalStyle/>
                <Router>
                    <ScrollToTop/>
                    <StyledContainer isAuthenticated={isAuthenticated}>
                        <Header
                            user={user}
                            logout={() => userAccessTokenService.logout()}
                        />
                        <Player/>
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                                        <HomePage user={user}/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/login"
                                element={
                                    isAuthenticated ? (
                                        <Navigate to="/" replace/>
                                    ) : (
                                        <LoginPage visitCount={visitCount}/>
                                    )
                                }
                            />
                            <Route path="/watch" element={<WatchPage user={user}/>}/>
                            <Route
                                path="/multistream"
                                element={<MultiStreamPage user={user}/>}
                            />
                            <Route
                                path="/following"
                                element={
                                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                                        <FollowingPage user={user}/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="/directory" element={<BrowsePage/>}/>
                            <Route path="/game/:gameId" element={<GamePage user={user}/>}/>
                            <Route path="/about" element={<AboutPage/>}/>
                            <Route
                                path="/channel/:login"
                                element={<ChannelPage user={user}/>}
                            />
                            <Route path="/settings" element={<SettingsPage/>}/>
                            <Route path="/auth/callback" element={<AuthCallback/>}/>
                            <Route path="*" element={<NotFound isDarkMode={isDarkMode}/>}/>
                        </Routes>
                    </StyledContainer>
                    <BackToTopButton/>
                </Router>
            </ThemeProvider>
        </ErrorBoundary>
    );
};

const StyledContainer = styled.div.withConfig({
    shouldForwardProp: (prop) => !["isAuthenticated"].includes(prop),
})`
    padding: 100px 20px 20px 20px;
    min-height: 100vh;
`;

export default App;
