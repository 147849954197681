import supabase from "../supabaseClient";

function defaultLoggerProvider(level, category, message) {
    const timestamp = new Date().toLocaleString("fr-FR", {
        timeZone: "Europe/Paris"
    });

    console[level](
        `[${category.toUpperCase()}] [${level.toUpperCase()}] ${timestamp}: ${message}`
    );
}

function supabaseProvider(level, category, message) {
    return supabase
        .from("logs")
        .insert([{level, message}])
        .then(({error}) => {
            if (error) {
                console.error("Failed to log to Supabase:", error);
            }
        });
}

class Logger {
    constructor() {
        this.filterCategory = this.getCategoryFilter();
        this.minimumLevelFilter = this.getMinimumLevelFilter();
        this.levels = ["info", "warn", "error"];
        this.loggerProvider = defaultLoggerProvider;
        this.databaseProvider = supabaseProvider;
    }

    setLoggerProvider(newLoggerProvider) {
        this.loggerProvider = newLoggerProvider;
    }

    setDatabaseProvider(newDatabaseProvider) {
        this.databaseProvider = newDatabaseProvider;
    }

    // Méthode pour définir un filtre (category)
    setCategoryFilter(value) {
        this.filterCategory = value;
        sessionStorage.setItem("logFilterCategory", value);
    }

    // Méthode pour définir le filtre de niveau minimum
    setMinimumLevelFilter(value) {
        this.minimumLevelFilter = value;
        sessionStorage.setItem("minimumLevelFilter", value);
    }

    // Méthode pour récupérer le filtre de catégorie
    getCategoryFilter() {
        return sessionStorage.getItem("logFilterCategory") || "default";
    }

    // Méthode pour récupérer le filtre de niveau minimum
    getMinimumLevelFilter() {
        return sessionStorage.getItem("minimumLevelFilter") || "info";
    }

    // Méthode pour vérifier si le niveau du log est supérieur ou égal au filtre minimum
    isLevelEnabled(level) {
        const minLevelIndex = this.levels.indexOf(this.minimumLevelFilter);
        const levelIndex = this.levels.indexOf(level);
        return levelIndex >= minLevelIndex;
    }

    canLog(category, level) {
        if (this.filterCategory && this.filterCategory !== category) return false;
        if (!this.isLevelEnabled(level)) return false;
        return true;
    }

    // Méthode pour loguer les messages
    log(message, level = "info", category = "default") {
        // Si l'environnement est dev ou test, on applique les filtres
        if (
            process.env.NODE_ENV === "development" ||
            process.env.NODE_ENV === "test"
        ) {
            // Filtrage par catégorie et par niveau
            if (!this.canLog(category, level)) return;

            this.loggerProvider(level, category, message);
        }
        // En production, on envoie vers Supabase
        else if (process.env.NODE_ENV === "production") {
            this.databaseProvider(level, category, message);
        }
    }

    // Méthodes pour loguer avec des niveaux spécifiques
    error(message, category = "default") {
        this.log(message, "error", category);
    }

    warn(message, category = "default") {
        this.log(message, "warn", category);
    }

    info(message, category = "default") {
        this.log(message, "info", category);
    }
}

const logger = new Logger();
export default logger;
