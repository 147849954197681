import supabase from "../../supabaseClient";
import Logger from "../Logger";

const table = process.env.NODE_ENV === "development" ? "visits_dev" : "visits";

const SupabaseVisitService = {
    async getVisitCount() {
        const {count} = await supabase
            .from(table)
            .select("*", {count: "exact"});
        return count;
    },

    async recordVisit(userId, ipAddress, location) {
        await supabase
            .from(table)
            .insert([{user_id: userId, ip_address: ipAddress, location}]);
    },

    async fetchIpAndLocation(userId) {
        try {
            // Getting user IP
            const ipResponse = await fetch("https://api.ipify.org?format=json");
            if (!ipResponse.ok) throw new Error("Failed to fetch IP");
            const ipData = await ipResponse.json();

            // Getting Location
            const locationResponse = await fetch(
                `https://ipapi.co/${ipData.ip}/json/`
            );
            if (!locationResponse.ok) throw new Error("Failed to fetch location");
            const locationData = await locationResponse.json();

            // save ip + location
            await SupabaseVisitService.recordVisit(userId, ipData.ip, locationData);
        } catch (error) {
            Logger.error(
                `VisitService - fetchIpAndLocation - Error recording visit ${error.message}`
            );
        }

        try {
            // get visit count
            const visitCount = await SupabaseVisitService.getVisitCount();
            return visitCount;
        } catch (error) {
            Logger.error(
                `VisitService - fetchIpAndLocation - Error getting visit count ${error.message}`
            );
        }
        return 0;
    },
};

export default SupabaseVisitService;
