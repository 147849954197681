import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { DragDropContext, Droppable,Draggable } from "react-beautiful-dnd";
import {
  FaStepBackward,
  FaStepForward,
  FaSync,
  FaTrash,
  FaTrashAlt,
  FaSyncAlt,
} from "react-icons/fa";
import { MdPlaylistPlay } from "react-icons/md";
import { Tooltip } from "react-tooltip";
import { toggleLoopState } from "../slices/playerSlice";
import { Play } from "lucide-react";
import {useTranslation} from "react-i18next";

const Playlist = ({ videos, onRemoveVideo, onClearVideos, onDragEnd }) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { playlist, playlistIndex, loopState } = useSelector(
    (state) => state.player
  );

  const handlePlayVideo = (index) =>
    navigate(`/watch?list=${playlist.id}&index=${index}`);

  return (
    <PlaylistContainer>
      <HeaderContainer>
        <Icon size={32} />
        {playlist.name}
      </HeaderContainer>

      {videos.length === 0 ? (
        <p>{t("playlist.noVideo")}</p>
      ) : (
        <>
          <Tooltip id="previous" style={{zIndex: 1500}}>{t("playlist.prev")}</Tooltip>
          <Tooltip id="next" style={{zIndex: 1500}}>{t("playlist.next")}</Tooltip>
          <Tooltip id="loop" style={{zIndex: 1500}}>Loop</Tooltip>
          <Tooltip id="delete" style={{zIndex: 1500}}>{t("playlist.delete")}</Tooltip>
          <Tooltip id="delete-all" style={{zIndex: 1500}}>{t("playlist.deleteAll")}</Tooltip>
          <Tooltip id="loop" style={{zIndex: 1500}}>
            {loopState === "NORMAL" && <>{t("playlist.t1")}</>}
            {loopState === "LOOP_PLAYLIST" && <>{t("playlist.t2")}</>}
            {loopState === "LOOP_VIDEO" && (
              <>{t("playlist.t3")}</>
            )}
          </Tooltip>

          <PlayerControlsContainer>
            <ControlButton
              onClick={() => handlePlayVideo(playlistIndex - 1)}
              disabled={playlistIndex - 1 < 0}
              data-tooltip-id="previous"
            >
              <FaStepBackward />
            </ControlButton>
            <ControlButton
              onClick={() => handlePlayVideo(playlistIndex + 1)}
              disabled={playlistIndex + 1 >= videos.length}
              data-tooltip-id="next"
            >
              <FaStepForward />
            </ControlButton>
            <ControlButton
              onClick={() => dispatch(toggleLoopState())}
              data-tooltip-id="loop"
            >
              {loopState === "NORMAL" && <FaSync />}
              {loopState === "LOOP_PLAYLIST" && <FaSyncAlt />}
              {loopState === "LOOP_VIDEO" && (
                <>
                  <FaSyncAlt />
                  <CenteredNumber>1</CenteredNumber>
                </>
              )}
            </ControlButton>
          </PlayerControlsContainer>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="playlist">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {videos.map((video, index) => (
                    <Draggable
                      key={video.id}
                      draggableId={video.id}
                      index={index}
                    >
                      {(provided) => (
                        <PlaylistItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          active={index === playlistIndex}
                        >
                          <div
                            onClick={() => handlePlayVideo(index)}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {index === playlistIndex && (
                                <Play
                                  size={20}
                                  color="#9146FF"
                                  style={{ marginRight: "10px" }}
                                />
                              )}
                              <TitleSpan>{video.title}</TitleSpan>
                            </div>
                            <Username>{video.user_name}</Username>{" "}
                          </div>
                          <div>
                            <PlaylistItemButton
                              danger={true}
                              onClick={() => onRemoveVideo(index)}
                              data-tooltip-id="delete"
                            >
                              <FaTrash />
                            </PlaylistItemButton>
                          </div>
                        </PlaylistItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <ClearButton onClick={onClearVideos} data-tooltip-id="delete-all">
            <FaTrashAlt />
          </ClearButton>
        </>
      )}
    </PlaylistContainer>
  );
};

const PlaylistContainer = styled.div`
  background-color: ${({ theme }) => theme.cardBackground};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;
  border: 1px solid ${({ theme }) => theme.borderColor};

  &:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.6);
    transform: translateY(-2px);
  }
`;

const PlaylistItem = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "active",
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  transition: all 0.2s ease;

  &:last-child {
    border-bottom: none;
  }

  background-color: ${({ active, theme }) =>
    active ? theme.selectedItemBackground : "transparent"};
  cursor: grab;

  &:hover {
    background-color: ${({ active, theme }) =>
    active ? theme.selectedItemBackground : theme.hoverBackground};
    transform: translateX(4px);
  }

  &:active {
    cursor: grabbing;
    transform: scale(0.98);
  }
`;

const PlaylistItemButton = styled.button.withConfig({
  shouldForwardProp: (prop) => prop !== "danger",
})`
  background-color: ${({ danger, theme }) => (danger ? theme.error : theme.primary)};
  color: white;
  border: none;
  border-radius: 6px;
  padding: 6px 12px;
  cursor: pointer;
  margin-left: 12px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 6px;

  &:hover {
    background-color: ${({ danger, theme }) =>
    danger ? theme.errorHover : theme.primaryHover};
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const ClearButton = styled(PlaylistItemButton)`
  background-color: ${({ theme }) => theme.error};
  margin-top: 12px;
  margin-left: 0;
  width: 100%;
  justify-content: center;
  padding: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.errorHover};
  }
`;

const HeaderContainer = styled.h2`
  display: flex;
  align-items: center;
  font-size: 24px;
  margin: 24px 0;
  color: ${({ theme }) => theme.color};
  padding-bottom: 12px;
  border-bottom: 2px solid ${({ theme }) => theme.borderColor};
  transition: color 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const Icon = styled(MdPlaylistPlay)`
  margin-right: 12px;
  color: ${({ theme }) => theme.primary};
  transition: transform 0.2s ease;

  ${HeaderContainer}:hover & {
    transform: scale(1.1);
  }
`;

const Username = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.textSecondary};
  transition: color 0.2s ease;
  padding: 4px 8px;
  border-radius: 4px;
  background: ${({ theme }) => theme.backgroundSecondary};

  &:hover {
    color: ${({ theme }) => theme.color};
  }
`;

const PlayerControlsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
  gap: 12px;
  flex-wrap: wrap;
`;

const ControlButton = styled.button`
  background-color: ${({ theme }) => theme.primary};
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  font-weight: 500;
  min-width: 100px;

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.primaryHover};
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
  }

  &:active:not(:disabled) {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    background-color: ${({ theme }) => theme.disabledBackground};
    cursor: not-allowed;
    box-shadow: none;
    opacity: 0.7;
  }
`;

const TitleSpan = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.color};
  transition: color 0.2s ease;
  padding: 4px 0;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }

  @media (max-width: 1000px) {
    max-width: 600px;
  }

  @media (max-width: 800px) {
    max-width: 300px;
  }

  @media (max-width: 600px) {
    max-width: 250px;
  }

  @media (max-width: 500px) {
    max-width: 180px;
  }
`;

const CenteredNumber = styled.span`
  position: absolute;
  font-size: 9px;
  font-weight: bold;
  color: white;
  background: ${({ theme }) => theme.primary};
  padding: 2px 4px;
  border-radius: 8px;
  min-width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(50%, -50%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;


export default Playlist;
